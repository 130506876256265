import { compareObjects } from "../js/CommonHelper";
import useAxiosPrivate from "../hooks/useAxiosPrivate";


export const delay = (time) => {
    return new Promise(r => setTimeout(r, time));
}

export const addItem = async (item, url, setItems, itemsList) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify(item)
    });
    const data = await response.json();
    setItems([...itemsList, data]);
}

export const updateItem = async (item, currentItem, url, setItems, itemsList) => {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify(currentItem)
    });
    const data = await response.json();
    setItems(
        itemsList.map((i) =>
            i.id === item.id ? data : i));
}

export const deleteItem = async (id, url, setItems, itemsList) => {
    await fetch(url, {method: 'DELETE',});
    setItems(itemsList.filter((i) => i.id !== id));
}

export const onSaveAndClose = ({ state }) => {
  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const setBackupItem = state.setBackupItem;
  const defaultItem = state.defaultItem;

  setCurrentFormState({...currentFormState,
        showForm: false,
        formSaveButtonDisabled: true,
        formAddingDataMode: false
    })

    clearCurrentItem(setCurrentItem, setBackupItem, defaultItem);
}


export const onSaveNotClose = ({ state }) => {
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const setBackupItem = state.setBackupItem;
  const currentItem = state.currentItem;
  setCurrentFormState({...currentFormState,
    formSaveButtonDisabled: true,
    formAddingDataMode: false
  })

  setBackupItem(currentItem);

}


export const clearCurrentItem = (setCurrentItem, setBackupItem, defaultItem) => {
    setCurrentItem(defaultItem);
    setBackupItem(defaultItem);
}

export const onAddDataClick = (currentFormState, setCurrentFormState, formDescription, formHeader) => {
  setCurrentFormState({...currentFormState,
    formDescription: formDescription,
    formHeader: formHeader,
    formAddingDataMode: true,
    formSaveButtonDisabled: false,
    showForm: true})
}

export const onFormCancelCloseButtonClick = () => {
    let closeWithoutSaving = document.getElementById("confirm-close");
    let btnClose = document.getElementById("btn-close");
    closeWithoutSaving.classList.remove("div-visible");
    btnClose.classList.remove("btn-invisible");
}

export const onFormCancelDeleteButtonClick = () =>  {
    document.getElementById("confirm-delete").classList.add("div-hidden");
    document.getElementById("btn-delete").classList.remove("btn-invisible");
    document.getElementById("modal_wrapper").classList.remove("delete_warning");
}

export const onFormConfirmDeleteButtonClick = () =>  {
    document.getElementById("confirm-delete").classList.remove("div-hidden");
    document.getElementById("btn-delete").classList.add("btn-invisible");
    document.getElementById("modal_wrapper").classList.add("delete_warning");
}

export const onItemsListInfoButtonClick = (currentFormState, setCurrentFormState, formHeader) => {
    setCurrentFormState({...currentFormState,
        formAddingDataMode: false,
        formHeader: formHeader,
        formDescription: "",
        formSaveButtonDisabled: true,
        showForm: true})
}


export const compareData = (currentFormState, setCurrentFormState, currentItem, backupItem) => {
    let dataChangedInfo = document.getElementById("data-changed");
    let confirmCloseDiv = document.getElementById("confirm-close");
    let btnClose = document.getElementById("btn-close");
    let btnRestore = document.getElementById("btn-restore");
    if (dataChangedInfo && currentFormState.showForm && !currentFormState.formAddingDataMode) {
        if (!compareObjects(backupItem, currentItem)) {
            // console.log("NOT THE SAME");
            // console.log(backupItem);
            // console.log(currentItem);
            dataChangedInfo.classList.add("visible");
            btnRestore.classList.add("visible");
            setCurrentFormState({...currentFormState, formSaveButtonDisabled: false})
        } else {
          // console.log("THE SAME");
          // console.log(backupItem);
          // console.log(currentItem);

          if (confirmCloseDiv) {
                confirmCloseDiv.classList.remove("div-visible")
            }
            btnClose.classList.remove("btn-invisible");
            dataChangedInfo.classList.remove("visible");
            btnRestore.classList.remove("visible");
            setCurrentFormState({...currentFormState, formSaveButtonDisabled: true})
        }
    }
}


export const onFormCloseWithoutSavingButtonClick = (currentFormState, setCurrentFormState, setCurrentItem, setBackupItem, defaultItem) => {
//   console.log("helper");
  setCurrentFormState({
        ...currentFormState,
        showForm: false,
        formSaveButtonDisabled: true,
        formAddingDataMode: false
    })
    clearCurrentItem(setCurrentItem, setBackupItem, defaultItem);
}


export const getRelatedItemsByParentId = async (url, setItemsList) => {
    const response = await fetch(url);
    const data = await response.json();
    setItemsList(data);
    return data;
}


export const restoreFormData = ({ state }) =>  {

  const backupItem = state.backupItem;
  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;

  const elements = document.querySelectorAll('.form-input-invalid');
  elements.forEach(element => {
    element.classList.remove('form-input-invalid');
  });

  setCurrentItem(backupItem);
    for (let key in backupItem) {
        if (backupItem.hasOwnProperty(key)) {
            let element = document.getElementById(key);
            if (element) {
              //console.log(element.tagName);
              if (element.tagName === "SELECT") {
                // Ustaw wartość na id z backupItem, jeśli istnieje i nie jest nullem; w przeciwnym razie ustaw pusty string
                element.value = (backupItem[key] && backupItem[key].id) ? backupItem[key].id : "";
              } else {
                element.value = backupItem[key] || "";  // Dla innych elementów również upewnij się, że nie próbujemy ustawić null
              }



              // if (element.tagName !== "SELECT") {
              //   element.value = backupItem[key];
              // } else if (element.tagName === "SELECT") {
              //   element.value = backupItem[element.id].id;
              // } else {
              //   console.log(element.tagName);
              // }
            }  else {
              // console.log("Element not found for ID:", key);
            }
        }
    }

    let dataChangedInfo = document.getElementById("data-changed");
    let btnRestore = document.getElementById("btn-restore");
    let confirmClose = document.getElementById("confirm-close");
    let btnClose = document.getElementById("btn-close");
    dataChangedInfo.classList.remove("visible");
    btnRestore.classList.remove("visible");
    confirmClose.classList.remove("div-visible");
    btnClose.classList.remove("btn-invisible");

    setCurrentFormState({
        ...currentFormState,
        formSaveButtonDisabled: true,
    });
}


export const onItemsListDeleteButtonClick = (currentFormState, setCurrentFormState, itemName, allowDelete, itemDetails) =>  {
    if (allowDelete) {
        setCurrentFormState({...currentFormState,
            showDeleteWarning: true,
            warningDescription: "Czy na pewno usunąć " + itemName +" (" + itemDetails + ")? Tej akcji nie można cofnąć!",
            warningDeleteButtonDisabled: false,
            warningWarningIconVisible: false})
    } else {
        setCurrentFormState({...currentFormState,
            showDeleteWarning: true,
            warningDescription: "Nie można usunąć " + itemName +" (" + itemDetails + "), ponieważ istnieją powiązane elementy.",
            warningDeleteButtonDisabled: true,
            warningWarningIconVisible: true
        })
    }
}

export const getItems = async (url, setItems) => {
    const response = await fetch(url);
    const data = await response.json();
    switch(response.status) {
        case 401:
            // TODO Unauthenticated
            break;
        case 403:
            // TODO Forbidden
            break;
        case 404:
            // TODO Not found
            break;
        default:
        // TODO Other errors
    }
    if (response.ok) {
        setItems(data);
    }
}

export const onCloseDeleteWarningDialog = ({ state }) => {
  const setCurrentItem = state.setCurrentItem;
  const setBackupItem = state.setBackupItem;
  const defaultItem = state.defaultItem;
  const setAllowDelete = state.setAllowDelete;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;

  clearCurrentItem(setCurrentItem, setBackupItem, defaultItem);
//   console.log("helper");
  setAllowDelete(null);
  setCurrentFormState({ ...currentFormState,
    showDeleteWarning: false,
    showForm: false,
    warningDescription: "",
    warningDeleteButtonDisabled: false,
    warningWarningIconVisible: false });
};


export function resetInvalidInputField(fieldId) {
  const inputField = document.getElementById(fieldId);
  if (inputField.type !== "checkbox" && inputField.type !== null) {
    inputField.placeholder = "";
    inputField.classList.remove("form-input-invalid");
  }
}

export const onCloseDetails = ( {state} ) => {
  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const backupItem = state.backupItem;
  const setBackupItem = state.setBackupItem;
  const defaultItem = state.defaultItem;
//   console.log("helper");
  // if (compareObjects(backupItem, currentItem)) {
    setCurrentFormState({
      ...currentFormState,
      showForm: false,
      formSaveButtonDisabled: true,
      formAddingDataMode: false
    })
    clearCurrentItem(setCurrentItem, setBackupItem, defaultItem);
  // } else {
  //   let closeWithoutSaving = document.getElementById("confirm-close");
  //   let btnClose = document.getElementById("btn-close");
  //   closeWithoutSaving.classList.add("div-visible");
  //   btnClose.classList.add("btn-invisible");
  // }
};

export const getItemById = (array, id) => {
   for (let i = 0, l = array.length; i < l; i++) {
      if (String(array[i].id) === String(id)) {
        return array[i];
      }
    }
  return null;
}




// export const getItemById = (array, id) => {
//
//   console.log(id.valueOf());
//   for (let i = 0, l = array.length; i < l; i++) {
//     if (array[i].id === id) {
//       return array[i]
//     }
//   }
// }


export const setForegroundColor = (backgroundColorHex) => {
  if (backgroundColorHex) {
    const red = parseInt(backgroundColorHex.substring(1, 3), 16);
    const green = parseInt(backgroundColorHex.substring(3, 5), 16);
    const blue = parseInt(backgroundColorHex.substring(5), 16);
    //console.log(backgroundColorHex);
    //console.log(red + "." + green + "." + blue);
    //console.log(red * 0.299 + green * 0.587 + blue * 0.114);
    return (red * 0.299 + green * 0.587 + blue * 0.114) > 140 ? "black" : "white"
  }

}


export const validateNumberField = (value, fieldId, min, max) => {

  let isValid = !isNaN(value);
  let isInRange = isNaN(min) || isNaN(max) ? true : (value >= min && value <= max);
  let inputField = document.getElementById(fieldId);

  if (inputField && inputField.type !== "checkbox") {
    if (!isValid) {
      inputField.classList.add("form-input-invalid");
      inputField.placeholder = "ten element jest wymagany";
    } else if (!isInRange) {
      inputField.classList.add("form-input-invalid");
      inputField.placeholder = "wartość poza dozwolonym zakresem";
    } else {
      inputField.classList.remove("form-input-invalid");
    }
  }

  return isValid && isInRange;
};


export const validateTextField = (value, fieldId) => {

  let isValid = value !== "";
  let inputField = document.getElementById(fieldId);
  if (inputField) {
    if (!isValid) {
      inputField.classList.add("form-input-invalid");
      inputField.placeholder = "ten element jest wymagany";
    } else {
      inputField.classList.remove("form-input-invalid");
    }
  }
  return isValid;
};


export const validateSelectField = (value, fieldId) => {

  let isValid = value !== null && value !== undefined && value.id !== 0;
  let inputField = document.getElementById(fieldId);

  if (inputField) {
    if (!isValid) {
      inputField.classList.add("form-input-invalid");
      inputField.placeholder = "ten element jest wymagany";
    } else {
      inputField.classList.remove("form-input-invalid");
    }
  }
  return isValid;
};


