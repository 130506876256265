import React, {useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import ModalControls from "../../common/ModalControls";
import {useNavigate, useLocation} from "react-router-dom";
import {defaultFormState} from "../../../../defaults/Forms";
import {documentNumberingPatternDefault} from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails,
  setForegroundColor,
  validateNumberField,
  validateTextField,
  validateSelectField,
  resetInvalidInputField
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import useCrud from "../../../../hooks/useCrud";
import {Table} from "../../../table/Table";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Select from "../../../elements/Select";
import OptionsList from "../../../elements/OptionsList";

const DocumentNumberingPattern = () => {

  const dataUrl = "/document-numbering-patterns";
  const contractTypesUrl = "/contract-types";
  const relatedItemsUrl = null;
  const defaultItem = documentNumberingPatternDefault;
  const itemName = "wzorzec numeracji";
  const itemNames = "wzorce numeracji";
  const navigate = useNavigate();
  const location = useLocation();
  const {createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId} = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [updateConflict, setUpdateConflict] = useState(false);
  const [contractTypesList, setContractTypesList] = useState([]);
  const [validPattern, setValidPattern] = useState(false);
  const [pattern, setPattern] = useState('');
  const [patternExample, setPatternExample] = useState('');
  const [symbol, setSymbol] = useState('');
  const [yearFormat, setYearFormat] = useState('');
  const [monthFormat, setMonthFormat] = useState('');

  const patternRef = useRef();
  // PATTERN WITH REQUIRED MONTH AND YEAR
  // const NUMBERING_PATTERN_REGEX = /^(?!.*([RMN]).*\1)(?=[^S]*S?[^S]*$)(?=.*R)(?=.*M)(?=.*N)[RMNS \/:\-_\\]*$/
  const NUMBERING_PATTERN_REGEX = /^(?!.*([RMN]).*\1)(?=[^S]*S?[^S]*$)(?=.*N)[RMNS \/:\-_\\]*$/

  // elements related to current item
  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false},
    {label: "Rok", accessor: "year", sortable: true, searchable: true},
    {label: "Wzorzec", accessor: "pattern", sortable: true, searchable: true},
    {
      label: "Typ umowy",
      accessor: "contractType",
      sortable: true,
      searchable: true,
      format: (contractType) => contractType ? `${contractType.name}` : "",
      fieldsToInclude: ['name']
    },
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];


  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }


  const onDelete = async () => {

    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  }


  const onSaveItemClick = async (e) => {
    e.preventDefault();

    const formIsValid = fieldsValidation.every(isValid => isValid);
    if (!formIsValid) {
      return;
    }

    let response;
    const item = {
      id: currentItem.id,
      year: currentItem.year,
      symbol: currentItem.symbol,
      yearFormat: currentItem.yearFormat,
      monthFormat: currentItem.monthFormat,
      pattern: currentItem.pattern,
      contractType: currentItem.contractType && currentItem.contractType.id > 0 ? {id: currentItem.contractType.id} : null,
      locked: currentItem.locked
    };


    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataUrl, item, state);
    } else {
      response = await updateItem(`${dataUrl}/${item.id}`, item, state);
    }

    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
      setUpdateConflict(true);
    } else {
      response && onSaveAndClose({state});
    }
  }

  const checkRelatedItems = async (id) => {
    setAllowDelete(true);
  }


  const fieldsValidation = [
    validateNumberField(currentItem.year, "year", 2000, 2999),
    validateTextField(currentItem.pattern, "pattern"),
    validateSelectField(currentItem.contractType)
  ];


  const onClose = () => {
    setUpdateConflict(false);
    onCloseDetails({state});
  };


  useEffect(() => {
    setValidPattern(NUMBERING_PATTERN_REGEX.test(pattern));
  }, [pattern])


  useEffect(() => {
    if (updateConflict) setUpdateConflict(false);
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
  }, [itemChanged])


  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])


  useEffect(() => {
    const getData = async () => {
      const [itemsData, contractTypesData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(contractTypesUrl)
        ]);
      const success =
        itemsData.status === 200 &&
        contractTypesData.status === 200
      if (success) {
        setLoading(false);
        setItems(itemsData.data);
        setContractTypesList(contractTypesData.data);
        setPattern('');
      } else {
        alert("Nie udało się pobrać danych.");
      }
    };
    getData();
  }, [])


  useEffect(() => {
    const isValid = NUMBERING_PATTERN_REGEX.test(pattern);
    setValidPattern(isValid);

    if (isValid) {
      generatePatternExample();
    }
  }, [pattern, symbol, yearFormat, monthFormat]);


  const getCompleteItem = async (id) => {
    const response = await getItems(`${dataUrl}/${id}`);
    if (response.status === 200) {
      setPattern(response.data.pattern);
      setSymbol(response.data.symbol);
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }


  useEffect(() => {
    if (currentFormState.showForm && currentFormState.formAddingDataMode) {
      setSymbol(defaultItem.symbol || currentItem.symbol);
      setYearFormat(defaultItem.yearFormat || currentItem.yearFormat);
      setMonthFormat(defaultItem.monthFormat || currentItem.monthFormat);
      setPattern(defaultItem.pattern || currentItem.pattern);
    }
  }, [currentFormState.showForm, currentFormState.formAddingDataMode]);


  const generatePatternExample = () => {

    const months = {
      1: 'STY',
      2: 'LUT',
      3: 'MAR',
      4: 'KWI',
      5: 'MAJ',
      6: 'CZE',
      7: 'LIP',
      8: 'SIE',
      9: 'WRZ',
      10: 'PAŹ',
      11: 'LIS',
      12: 'GRU'
    };

    const currentYear = new Date().getFullYear().toString();
    const currentMonth = new Date().getMonth() + 1;
    const exampleNumber = 123;

    let example = pattern.replace('S', symbol);
    example = example.replace('R', yearFormat === 'rrrr' ? currentYear : currentYear.slice(-2));

    if (monthFormat === 'mmm') {
      example = example.replace('M', months[currentMonth]);
    } else if (monthFormat === 'mm') {
      example = example.replace('M', currentMonth.toString().padStart(2, '0'));
    } else {
      example = example.replace('M', currentMonth.toString());
    }

    example = example.replace('N', exampleNumber.toString());

    setPatternExample(example);
  };


  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem,
    currentFormState,
    setCurrentFormState,
    formDescription: null,
    formHeader: `Nowy element`,
    buttonTitle: ` Dodaj nowy Wzorzec numeracji dokumentów`
  }


  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv/>
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows={itemsList}
        columns={columns}
        state={state}
        checkRelatedItems={checkRelatedItems}
        formHeader={`Edycja ${itemName}`}
        relatedItemsUrl={relatedItemsUrl}
        getCompleteItem={getCompleteItem}
      />
  } else {
    dataSectionContent =
      <div className={"warn_info"}><FontAwesomeIcon
        className={"warning-icon div-visible"} icon={faExclamationCircle}/>&nbsp;&nbsp;&nbsp;NIE ZNALEZIONO ŻADNYCH
        DANYCH
      </div>
  }


  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader
          title={`Edycja wzorca numeracji dokumentów`}/>
      ) : (
        <AppComponentCardHeader
          title={`Wzorce numeracji dokumentów`}
          buttonProps={addDataButtonProps}/>
      )}
      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{currentFormState.formDescription}</p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={() => {
                    setUpdateConflict(false)
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>
                <div className="col-md-12 mb-md-0 mb-5">
                  <form id="add-document-numbering-pattern-form" name="add-document-numbering-pattern-form">

                    {/*<div className="row form-group mb-4">*/}
                    {/*  <div className="col-md-3 form-label">*/}
                    {/*    <label htmlFor="contractTypes" className="">Typ umowy *</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-md-9">*/}
                    {/*    <OptionsList*/}
                    {/*      label="Typy umów *"*/}
                    {/*      propertyName="contractTypeId"*/}
                    {/*      required={false}*/}
                    {/*      state={state}*/}
                    {/*      itemsList={contractTypesList}*/}
                    {/*      itemName="contractTypes"*/}
                    {/*      displayNames={["name"]}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="contractType" className="">Typ umowy *</label>
                      </div>
                      <div className="col-md-9">
                        <Select
                          label="contractType"
                          propertyName="contractTypeId"
                          required={false}
                          state={state}
                          itemsList={contractTypesList}
                          itemName="contractType"
                          displayNames={["name"]}
                        />
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="year" className="">Rok *</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="year" required={true} state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="pattern" className="">Wzorzec *</label>
                      </div>


                      <div className="row col-md-9">

                        <div className="col-md-2">
                          <label htmlFor="symbol" className="">Symbol</label>
                          <input
                            type="text"
                            className="form-control"
                            id="symbol"
                            autoComplete="off"
                            value={currentItem.symbol}
                            required
                            onChange={(e) => {
                              setSymbol(e.target.value);
                              setItemChanged(!itemChanged);
                              setCurrentItem({
                                ...currentItem,
                                symbol: e.target.value
                              });
                              setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
                            }}
                            onClick={() => resetInvalidInputField("symbol")}
                          />
                        </div>

                        <div className="col-md-2">
                          <label htmlFor="yearFormat" className="">Format roku</label>
                          <div>
                            <select name="yearFormat"
                                    value={currentItem?.yearFormat || ""}
                                    className="form-select"
                                    onChange={(e) => {
                                      setYearFormat(e.target.value);
                                      setItemChanged(!itemChanged);
                                      setCurrentItem({
                                        ...currentItem,
                                        yearFormat: e.target.value
                                      });
                                    }
                                    }>
                              <option value="rrrr" selected>RRRR (2025)</option>
                              <option value="rr">RR (25)</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <label htmlFor="monthFormat" className="">Format miesiąca</label>
                          <div>
                            <select name="monthFormat"
                                    value={currentItem?.monthFormat || ""}
                                    className="form-select"
                                    onChange={(e) => {
                                      setMonthFormat(e.target.value);
                                      setItemChanged(!itemChanged);
                                      setCurrentItem({
                                        ...currentItem,
                                        monthFormat: e.target.value
                                      });
                                    }}>
                              <option value="m">M (7)</option>
                              <option value="mm" selected>MM (07)</option>
                              <option value="mmm">MMM (LIP)</option>
                            </select>
                          </div>
                        </div>

                      </div>

                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="pattern" className=""></label>
                      </div>
                      <div className="col-md-9">
                        <label htmlFor="pattern" className="">Definicja wzorca <FontAwesomeIcon icon={faCheck}
                                                                                                className={validPattern ? "valid" : "invalid"}/>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="pattern"
                          ref={patternRef}
                          autoComplete="off"
                          value={currentItem.pattern}
                          required
                          onChange={(e) => {
                            setPattern(e.target.value);
                            setItemChanged(!itemChanged);
                            setCurrentItem({
                              ...currentItem,
                              pattern: e.target.value
                            });
                            setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
                          }}
                          onClick={() => resetInvalidInputField("pattern")}
                        />
                        <p id="pattern_note" className={!validPattern ? "instructions" : "offscreen"}>
                          <FontAwesomeIcon icon={faInfoCircle}/>
                          Wzorzec musi zawierać jedno oznaczenie N (kolejny numer).<br/>
                          Opcjonalnie wzorzec może zawierać S (zdefiniowany symbol), M (miesiąc), R (rok)<br/>
                          Jako separator można użyć spacji, dwukropka, znaku podkreślenia, ukośników. <br/>
                          Przykładowe wzorce:<br/>
                          RMN (202405123) <br/>
                          S/RM/N (XYZ/202505/123)<br/>
                          N_RM_S (123_202505_XYZ)<br/>
                          S:R/M N (XYZ:2025/05 123)
                        </p>
                        <p id="pattern_example" className={validPattern ? "" : "offscreen"}>
                          Przykład: {patternExample}<br/>
                        </p>
                      </div>
                    </div>

                    <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                      Nie można dodać ani zmienić tego elementu, ponieważ istnieje już wpis o tej nazwie i/lub skrócie
                    </p>
                  </form>
                </div>

              </section>
            </div>
            <ModalControls
              onDelete={onDelete}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div
            className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{dataSectionContent}</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );
}

export default DocumentNumberingPattern;

