import React from 'react';
import Select from "../../../elements/Select";
import TextInput from "../../../elements/TextInput";

const EmployeeAddressCard = ({ state, addressProps, countriesList, countryProps, cardHeader }) => {

  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const setCurrentFormState = state.setCurrentFormState;
  const currentFormState = state.currentFormState;


  return (
    <>
      <div className="card-header">
        { cardHeader }
      </div>
      <div className="card-body">
        <div className="row">
        <div className="col-md-8">
          <div className="md-form mb-0">
            <label htmlFor = { addressProps.street } className="">Ulica</label>
            <TextInput propertyName = { addressProps.street } state={ state }/>
          </div>
        </div>
        <div className="col-md-4">
          <div className="md-form mb-0">
            <label htmlFor={ addressProps.streetNumber } className="">Numer</label>
            <TextInput propertyName={ addressProps.streetNumber } state={ state }/>
          </div>
        </div>
        <div className="col-md-8">
          <div className="md-form mb-0">
            <label htmlFor={ addressProps.city } className="">Miasto</label>
            <TextInput propertyName={ addressProps.city } state={ state }/>
          </div>
        </div>
        <div className="col-md-4">
          <div className="md-form mb-0">
            <label htmlFor={ addressProps.postalCode } className="">Kod pocztowy</label>
            <TextInput propertyName={ addressProps.postalCode } state={ state }/>
          </div>
        </div>
        <label htmlFor={ countryProps.itemName } className="">{ countryProps.label }</label>
        <Select
          label = "Country" // aria-label
          propertyName = { countryProps.propertyName }
          required = { false }
          state = { state }
          itemsList = { countriesList }
          itemName = { countryProps.itemName } // DOM element id
          displayNames = {["name"]}
        />
        </div>
      </div>
    </>
  );
};

export default EmployeeAddressCard;
