import React, {useEffect, useState, useCallback} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faChalkboardUser,
} from "@fortawesome/free-solid-svg-icons";
import PositionCertification from "./PositionCertification";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {certificationTypeDefault} from "../../../../defaults/Items";


const PositionCertificationsCard = ({ state, certificationsList }) => {
  const currentItem = state.currentItem;
  const requiredCertifications = currentItem?.requiredCertifications || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const filteredCertificationsList = certificationsList.filter(
    (certification) => !requiredCertifications.some((rt) => rt.id === certification.id)
  );

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

    useEffect(()=> {
      console.log(currentItem.requiredCertifications[0])
      console.log(currentItem.requiredCertifications[1])
      console.log(currentItem.requiredCertifications[2])
  }, [currentItem])

  const addCertification = () => {
    const existingIds = requiredCertifications.map((certification) => certification.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }
    const newCertification = { ...certificationTypeDefault, id: newId, name: "---Wybierz---" };
    const updatedCertifications = [...requiredCertifications, newCertification];
    setCurrentItem({ ...currentItem, requiredCertifications: updatedCertifications });
    setItemChanged(!itemChanged);
  };


  const removeCertification = useCallback(
    (certificationId) => {
      const updatedCertifications = requiredCertifications.filter(
        (item) => item.id !== certificationId
      );
      setCurrentItem({ ...currentItem, requiredCertifications: updatedCertifications });
      setItemChanged(!itemChanged);
    },
    [requiredCertifications, currentItem, setCurrentItem, setItemChanged, itemChanged]
  );


  return (
    <>
      <div className="card">
        <div
          className="card-header d-flex align-items-center justify-content-between"
          onClick={toggleAccordion}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faChalkboardUser}
              className="me-2 header-icon"
            />
            <span>
              Wymagane zaświadczenia {!isAccordionOpen ? `(${requiredCertifications.length})` : ""}
            </span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div
          className={`card-body ${isAccordionOpen ? "show" : "offscreen"}`}
          id="requiredCertifications"
        >
          <button
            type="button"
            className="mb-2 btn-auxiliary btn-primary"
            onClick={addCertification}
          >
            <FontAwesomeIcon icon={faCirclePlus} /> Dodaj
          </button>
          {requiredCertifications.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-2 header-label">Typ zaświadczenia</div>
            </div>
          )}
          {requiredCertifications.length > 0 ? (
            requiredCertifications.map((certification, index) => (
              <PositionCertification
                key={index}
                certification={certification}
                index={index}
                state={state}
                certificationsList={filteredCertificationsList}
                onRemove={() => removeCertification(certification.id)}
              />
            ))
          ) : (
            <p>Brak wymaganych zaświadczeń</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PositionCertificationsCard;








