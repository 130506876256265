import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faChalkboardUser,
  faLightbulb
} from "@fortawesome/free-solid-svg-icons";
import EmployeeTraining from "./EmployeeTraining";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {employeeTrainingDefault} from "../../../../defaults/Items";

const EmployeeTrainingsCard = ({ state, trainingsList, supervisorsList, settings }) => {

  const currentItem = state.currentItem;
  let employeeTrainings = currentItem?.employeeTrainings?.slice() || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [trainingsNote, setTrainingsNote] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);


  useEffect(()=> {
    //console.log(trainingsList);
  }, [currentItem])

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const onInputChange = (e, training, field) => {
    const updatedTrainings = employeeTrainings.map((item) =>
      item.id === training.id ? { ...item, [field]: e.target.value } : item
    );
    setCurrentItem({ ...currentItem, employeeTrainings: updatedTrainings });
    setItemChanged(!itemChanged);
  };

  const addTraining = () => {
    const existingIds = currentItem.employeeTrainings.map(cert => cert.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }
    const newTraining = { ...employeeTrainingDefault, EID: currentItem.id, id: newId};
    const updatedTrainings = [...employeeTrainings, newTraining];
    setCurrentItem({ ...currentItem, employeeTrainings: updatedTrainings });
    setItemChanged(!itemChanged);
  };

  const removeTraining = (trainingId) => {
    const updatedTrainings = employeeTrainings.filter(item => item.id !== trainingId);
    setCurrentItem({ ...currentItem, employeeTrainings: updatedTrainings });
    setItemChanged(!itemChanged);
  };


  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faChalkboardUser} className="me-2 header-icon"/>
            <span>Szkolenia pracownika {!isAccordionOpen ? "(" + currentItem.employeeTrainings.length + ")" : ""}</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="employeeTrainings">
          <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addTraining}><FontAwesomeIcon
            icon={ faCirclePlus }/> Dodaj</button>
          {employeeTrainings.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-1 header-label">Początek</div>
              <div className="col-md-1 header-label">Koniec</div>
              <div className="col-md-2 header-label">Typ szkolenia</div>
              <div className="col-md-2 header-label">Osoba prowadząca</div>
              <div className="col-md-1 header-label">Ocena</div>
              <div className="col-md-1 header-label">Data oceny</div>
              <div className="col-md-1 header-label">Ukończone</div>
              <div className="col-md-3 header-label">Opis</div>
            </div>
          )}
          {employeeTrainings.length > 0 ? (
            employeeTrainings.map((training, index) => (
              <EmployeeTraining
                key={index}
                training={training}
                index={index}
                state={state}
                onInputChange={onInputChange}
                trainingsList={trainingsList}
                supervisorsList={supervisorsList}
                onRemove={() => removeTraining(training.id)}
                settings={settings}
              />
            ))
          ) : (
            <p>Brak odbytych szkoleń</p>
          )}
        </div>
      </div>
      <p id="trainingsNote" className={trainingsNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
        Uzupełnij dane szkolenia
      </p>
    </>
  );
};

export default EmployeeTrainingsCard;



