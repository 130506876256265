import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faChalkboardUser,
  faLightbulb
} from "@fortawesome/free-solid-svg-icons";
import EmployeeAbsence from "./EmployeeAbsence";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {employeeAbsenceDefault} from "../../../../defaults/Items";
import {faUmbrellaBeach} from "@fortawesome/free-solid-svg-icons/faUmbrellaBeach";

const EmployeeAbsencesCard = ({ state, absenceTypesList, supervisorsList, settings }) => {

  const currentItem = state.currentItem;
  let employeeAbsences = currentItem?.employeeAbsences?.slice() || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [absencesNote, setAbsencesNote] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  useEffect(()=> {
    //console.log(currentItem.employeeAbsences);
  }, [currentItem])

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const onInputChange = (e, absence, field) => {
    const updatedAbsences = employeeAbsences.map((item) =>
      item.id === absence.id ? { ...item, [field]: e.target.value } : item
    );
    setCurrentItem({ ...currentItem, employeeAbsences: updatedAbsences });
    setItemChanged(!itemChanged);
  };

  const addAbsence = () => {
    const existingIds = currentItem.employeeAbsences.map(cert => cert.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }
    const newAbsence = { ...employeeAbsenceDefault, EID: currentItem.id, id: newId};
    const updatedAbsences = [...employeeAbsences, newAbsence];
    setCurrentItem({ ...currentItem, employeeAbsences: updatedAbsences });
    setItemChanged(!itemChanged);
  };

  const removeAbsence = (absenceId) => {
    const updatedAbsences = employeeAbsences.filter(item => item.id !== absenceId);
    setCurrentItem({ ...currentItem, employeeAbsences: updatedAbsences });
    setItemChanged(!itemChanged);
  };


  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faUmbrellaBeach} className="me-2 header-icon"/>
            <span>Zaplanowane nieobecności pracownika {!isAccordionOpen ? "(" + currentItem.employeeAbsences.length + ")" : ""}</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="employeeAbsences">
          <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addAbsence}><FontAwesomeIcon
            icon={ faCirclePlus }/> Dodaj</button>
          {employeeAbsences.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-2 header-label">Początek</div>
              <div className="col-md-2 header-label">Koniec</div>
              <div className="col-md-2 header-label">Typ nieobecności</div>
              <div className="col-md-2 header-label">Manager</div>
              <div className="col-md-3 header-label">Uwagi</div>
            </div>
          )}
          {employeeAbsences.length > 0 ? (
            employeeAbsences.map((absence, index) => (
              <EmployeeAbsence
                key={index}
                absence={absence}
                index={index}
                state={state}
                onInputChange={onInputChange}
                absenceTypesList={absenceTypesList}
                supervisorsList={supervisorsList}
                onRemove={() => removeAbsence(absence.id)}
              />
            ))
          ) : (
            <p>Brak nieobecności</p>
          )}
        </div>
      </div>
      <p id="absencesNote" className={absencesNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
        Uzupełnij dane nieobecności
      </p>
    </>
  );
};

export default EmployeeAbsencesCard;



