import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChalkboardUser, faFrownOpen, faInfoCircle, faNotesMedical} from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import {employeeMedExamDefault} from "../../../../defaults/Items";
import EmployeeMedExam from "./EmployeeMedExam";

const EmployeeMedExamsCard = ({ state, medExamTypesList, settings }) => {

  const currentItem = state.currentItem;
  let employeeMedExams = currentItem?.employeeMedExams?.slice() || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [medExamsNote, setMedExamsNote] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  useEffect(()=> {
  }, [currentItem])

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const onInputChange = (e, medExam, field) => {
    const updatedMedExams = employeeMedExams.map((item) =>
      item.id === medExam.id ? { ...item, [field]: e.target.value } : item
    );
    setCurrentItem({ ...currentItem, employeeMedExams: updatedMedExams });
    setItemChanged(!itemChanged);
  };


  const addMedExam = () => {
    const existingIds = currentItem.employeeMedExams.map(cert => cert.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }
    const newMedExam = { ...employeeMedExamDefault, EID: currentItem.id, id: newId};
    const updatedMedExams = [...employeeMedExams, newMedExam];
    setCurrentItem({ ...currentItem, employeeMedExams: updatedMedExams });
    setItemChanged(!itemChanged);
  };

  const removeMedExam = (id) => {
    const updatedMedExams = employeeMedExams.filter(item => item.id !== id);
    setCurrentItem({ ...currentItem, employeeMedExams: updatedMedExams });
    setItemChanged(!itemChanged);
  };


  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faNotesMedical} className="me-2 header-icon"/>
            <span>Badania pracownika {!isAccordionOpen ? "(" + currentItem.employeeMedExams.length + ")" : ""}</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="employeeMedExams">
          <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addMedExam}><FontAwesomeIcon
            icon={ faCirclePlus }/> Dodaj</button>
          {employeeMedExams.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-2 header-label">Typ badania</div>
              <div className="col-md-1 header-label">Data badania</div>
              <div className="col-md-2 header-label">Uwagi</div>
              <div className="col-md-1 header-label">Wynik</div>
              <div className="col-md-1 header-label">Data ważności</div>
            </div>
          )}
          {employeeMedExams.length > 0 ? (
            employeeMedExams.map((medExam, index) => (
              <EmployeeMedExam
                key={index}
                medExam={medExam}
                index={index}
                state={state}
                onInputChange={onInputChange}
                medExamTypesList={medExamTypesList}
                onRemove={() => removeMedExam(medExam.id)}
              />
            ))
          ) : (
            <p>Brak badań lekarskich</p>
          )}
        </div>
      </div>
      <p id="medExamsNote" className={medExamsNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
        Uzupełnij dane badania lekarskiego
      </p>
    </>
  );
};

export default EmployeeMedExamsCard;



