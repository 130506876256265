import React from "react";
import DatePicker from "react-datepicker";

const QDatePicker = ({ pickerId, errorParagraphId, state, propertyName, startDate, setStartDate }) => {

  const handleDateChange = (date) => {

    if (date === null) {
      setStartDate(null);
      state.setCurrentItem({ ...state.currentItem, [propertyName]: null });
      document.getElementById(errorParagraphId).classList.add('offscreen');
    } else {
      const adjustedDate = new Date(date);
      adjustedDate.setHours(12, 0, 0, 0);
      setStartDate(adjustedDate);
      state.setCurrentItem({ ...state.currentItem, [propertyName]: adjustedDate });
      state.setItemChanged(!state.itemChanged);
      state.setCurrentFormState({ ...state.currentFormState, formSaveButtonDisabled: false });
      document.getElementById(errorParagraphId).classList.add('offscreen');
    }
  };

  const handleRawChange = (e) => {
    const value = e.target.value;
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(1\d{3}|2\d{3})$/;

    if (!dateRegex.test(value)) {
      document.getElementById(errorParagraphId).classList.remove('offscreen');
    } else {
      document.getElementById(errorParagraphId).classList.add('offscreen');
    }
  };

  return (
    <>
      <DatePicker
        id={pickerId}
        selected={startDate}
        onChange={handleDateChange}
        onChangeRaw={handleRawChange}
        dateFormat="dd/MM/yyyy"
      />
    </>
  );
};

export default QDatePicker;
