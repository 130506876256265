import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import ModalControls from "../../common/ModalControls";
import {useNavigate, useLocation} from "react-router-dom";
import {defaultFormState} from "../../../../defaults/Forms";
import {positionDefault} from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import useCrud from "../../../../hooks/useCrud";
import {Table} from "../../../table/Table";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Select from "../../../elements/Select";
import NumberInput from "../../../elements/NumberInput";
import Toggler from "../../../elements/Toggler";
import PositionTrainingsCard from "./PositionTrainingsCard";
import PositionCertificationsCard from "./PositionCertificationCard";

const Position = () => {

  const dataUrl = "/positions";
  const departmentsUrl = "/departments";
  const trainingsUrl = "/trainings";
  const certificationsUrl = "/certification-types";
  const relatedItemsUrl = null; // if no need to check it, initialize with null and remove RelatedItemsList from details modal
  const defaultItem = positionDefault;
  const itemName = "stanowisko";
  const itemNames = "stanowiska";
  const navigate = useNavigate();
  const location = useLocation();
  const {createItem, updateItem, deleteItem, getItems} = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [updateConflict, setUpdateConflict] = useState(false);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [trainingsList, setTrainingsList] = useState([]);
  const [certificationsList, setCertificationsList] = useState([]);
  const [departmentError, setDepartmentError] = useState(false);


  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false},
    {label: "Nazwa", accessor: "name", sortable: true, searchable: true},
    {label: "Skrócona nazwa", accessor: "shortName", sortable: true, searchable: true},
    {label: "Stawka bazowa", accessor: "basicWage", sortable: true, searchable: true},
    {label: "Opis", accessor: "description", sortable: true, searchable: true},
    {label: "Dział firmy", accessor: "department", sortable: true, searchable: true, format: (department) => department ? `${department.name}` : "", fieldsToInclude: ['name']},
    {label: "Aktywny", accessor: "active", sortable: true, searchable: false, type: "bool"},
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];


  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }


  const onDelete = async () => {
    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  }


  const onSaveItemClick = async (e) => {
    e.preventDefault();


    if (!currentItem.name || !currentItem.shortName || !currentItem.basicWage || !currentItem.department) {
      if (!currentItem.name) {
        let nameInput = document.getElementById("name");
        nameInput.classList.add("form-input-invalid");
        nameInput.placeholder = `ten element jest wymagany`;
      }

      if (!currentItem.shortName) {
        let shortNameInput = document.getElementById("shortName");
        shortNameInput.classList.add("form-input-invalid");
        shortNameInput.placeholder = `ten element jest wymagany`;
      }

      if (!currentItem.basicWage) {
        let basicWageInput = document.getElementById("basicWage");
        basicWageInput.classList.add("form-input-invalid");
        basicWageInput.placeholder = `ten element jest wymagany`;
      }

      if (!currentItem.department) {
        setDepartmentError(true);
        console.log("Department missing");
      }

      return;
    }


    let response;
    const item = {
      id: currentItem.id,
      shortName: currentItem.shortName,
      name: currentItem.name,
      basicWage: currentItem.basicWage,
      description: currentItem.description,
      department: currentItem.department && currentItem.department.id > 0 ? {id: currentItem.department.id} : null,
      active: currentItem.active,
      requiredTrainings: currentItem.requiredTrainings,
      requiredCertifications: currentItem.requiredCertifications
    };

    console.log(item);

    // return; // TODO USUNĄĆ

    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataUrl, item, state);
    } else {
      response = await updateItem(`${dataUrl}/${item.id}`, item, state);
    }

    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
      setUpdateConflict(true);

    } else {
      response && onSaveAndClose({state});
    }
  }


  const checkRelatedItems = async (id) => {
    //const data = await getRelatedChildrenByParentId(`${ relatedItemsUrl }/${ id }`, id, setEventsList);
    //data.length === 0 ? setAllowDelete(true) : setAllowDelete(false);
    setAllowDelete(true);
  }


  const onClose = () => {
    setUpdateConflict(false);
    onCloseDetails({state});
  };


  useEffect(() => {
    if (updateConflict) setUpdateConflict(false);
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
    setDepartmentError(false);
  }, [itemChanged])


  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])


  useEffect(() => {
    const getData = async () => {
      const [itemsData, departmentsData, trainingsData, certificationsData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(departmentsUrl),
          getItems(trainingsUrl),
          getItems(certificationsUrl)
        ]);
      const success =
        itemsData.status === 200 &&
        departmentsData.status === 200 &&
        trainingsData.status === 200 &&
        certificationsData.status === 200;
      if (success) {
        setLoading(false);
        setItems(itemsData.data);
        setDepartmentsList(departmentsData.data);
        setTrainingsList(trainingsData.data);
        setCertificationsList(certificationsData.data);

      } else {
        alert("Nie udało się pobrać danych.");
      }
    };
    getData();
  }, [])


  const getCompleteItem = async (id) => {
    const response = await getItems(`${dataUrl}/${id}`);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }



  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem,
    currentFormState,
    setCurrentFormState,
    formDescription: `Tu możesz dodać nowy element.`,
    formHeader: `Nowy element`,
    buttonTitle: ` Dodaj nowe stanowisko`
  }


  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv/>
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows={itemsList}
        columns={columns}
        state={state}
        checkRelatedItems={checkRelatedItems}
        formHeader={`Edycja ${itemName}`}
        relatedItemsUrl={relatedItemsUrl}
        getCompleteItem={getCompleteItem}
      />
  } else {
    dataSectionContent =
      <div className={"warn_info"}><FontAwesomeIcon
        className={"warning-icon div-visible"} icon={faExclamationCircle}/>&nbsp;&nbsp;&nbsp;NIE ZNALEZIONO ŻADNYCH
        DANYCH, DODAJ NOWY {itemName.toUpperCase()}</div>
  }

  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader
          title={"Edycja pozycji słownika " + `${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}` + " -> " + currentItem.name}/>
      ) : (
        <AppComponentCardHeader
          title={"Pozycje słownika " + `${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}`}
          buttonProps={addDataButtonProps}/>
      )}
      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{currentFormState.formDescription}</p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={() => {
                    // console.log("Before restoreFormData");
                    setUpdateConflict(false)
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>
                <div className="row">

                  <div className="col-md-12 mb-md-0 mb-5">
                    <form id="add-position-form" name="add-position-form">
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="name" className="">Nazwa *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="name" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="shortName" className="">Skrót *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="shortName" maxlen="10" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="basicWage" className="">Stawka bazowa</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="basicWage" required={false} rows="2" state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="description" className="">Opis</label>
                        </div>
                        <div className="col-md-9">
                          <TextArea propertyName="description" required={false} rows="2" state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="department" className="">Dział firmy</label>
                        </div>
                        <div className="col-md-9">
                          <Select
                            label="Dział firmy"
                            propertyName="departmentId"
                            required={false}
                            state={state}
                            itemsList={departmentsList}
                            itemName="department"
                            displayNames={["name"]}
                          />
                          <p id="position_error" className={departmentError ? "err_info" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                            Wybierz dział do stanowiska
                          </p>
                        </div>


                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="active" className="">Aktywny</label>
                        </div>
                        <div className="col-md-1">
                          <Toggler propertyName="active" state={state} />
                        </div>
                      </div>


                      <div className="row form-group mb-2">
                        <div className="col-md-1 form-label">
                          <label htmlFor="requiredTrainings" className=""></label>
                        </div>
                        <div className="col-md-11">
                          <PositionTrainingsCard state={state} trainingsList={trainingsList}/>
                        </div>
                      </div>

                      <div className="row form-group mb-2">
                        <div className="col-md-1 form-label">
                          <label htmlFor="requiredCertifications" className=""></label>
                        </div>
                        <div className="col-md-11">
                          <PositionCertificationsCard state={state} certificationsList={certificationsList}/>
                        </div>
                      </div>


                      <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                        Nie można dodać ani zmienić tego elementu, ponieważ istnieje już wpis o tej nazwie i/lub skrócie
                      </p>
                    </form>
                  </div>

                </div>
              </section>
            </div>
            <ModalControls
              onDelete={onDelete}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div
            className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{dataSectionContent}</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );
}

export default Position;
