import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {useNavigate, useLocation} from "react-router-dom";
import {defaultFormState} from "../../../../defaults/Forms";
import {companyDefault} from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onCloseDetails, onItemsListDeleteButtonClick, deleteItem, validateNumberField, validateTextField
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import useCrud from "../../../../hooks/useCrud";

import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ModalControls from "../../common/ModalControls";
import NumberInput from "../../../elements/NumberInput";
import Select from "../../../elements/Select";
import Toggler from "../../../elements/Toggler";
import {Table} from "../../../table/Table";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import SimpleDataDisplay from "../../common/SimpleDataDisplay";
import CompanyPreview from "./CompanyPreview";

const Company = () => {

  const dataUrl = "/company";
  const legalEntitiesURL = "/legal-entities";
  const taxOfficesURL = "/tax-offices";
  const countriesURL = "/countries";
  const defaultItem = companyDefault;
  const itemName = "Dane firmy";
  const navigate = useNavigate();
  const location = useLocation();
  const {updateItem, getItems} = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);

  const [legalEntitiesList, setLegalEntitiesList] = useState([]);
  const [taxOfficesList, setTaxOfficesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);


  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }


  // TODO pobierać z bazy danych

  const itemDictionary = {
    fullName: "Pełna nazwa",
    shortName: "Skrócona nazwa",
    legalEntity: "Forma prawna",
    taxOffice: "Urząd skarbowy",
    regon: "REGON",
    nip: "NIP",
    krs: "KRS",
    insuranceNumber: "Numer ubezpieczenia",
    primaryStreet: "Ulica",
    primaryStreetNumber: "Numer",
    primaryPostalCode: "Kod pocztowy",
    primaryCity: "Miasto",
    secondaryStreet: "Ulica",
    secondaryStreetNumber: "Numer",
    secondaryPostalCode: "Kod pocztowy",
    secondaryCity: "Miasto",
    email: "Adres email",
    website: "Strona www",
    primaryPhone: "Telefon 1",
    secondaryPhone: "Telefon 2",
    bankAccount: "Konto bankowe",
    primaryCountry: "Państwo",
    secondaryCountry: "Państwo"
  }


  const onDelete = async () => {
    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  }


  const onSaveItemClick = async (e) => {
    e.preventDefault();
    const formIsValid = fieldsValidation.every(isValid => isValid);

    if (!formIsValid) {
      return;
    }

    let response;

    const item = {
      id: currentItem.id,
      fullName: currentItem.fullName,
      shortName: currentItem.shortName,
      regon: currentItem.regon,
      nip: currentItem.nip,
      krs: currentItem.krs,
      insuranceNumber: currentItem.insuranceNumber,
      legalEntity: currentItem.legalEntity && currentItem.legalEntity.id > 0 ? {id: currentItem.legalEntity.id} : null,
      taxOffice: currentItem.taxOffice && currentItem.taxOffice.id > 0 ? {id: currentItem.taxOffice.id} : null,
      notes: currentItem.notes,
      representative: currentItem.representative && currentItem.representative.id > 0 ? {id: currentItem.representative.id} : null,
      primaryStreet: currentItem.primaryStreet,
      primaryPostalCode: currentItem.primaryPostalCode,
      primaryCity: currentItem.primaryCity,
      secondaryStreet: currentItem.secondaryStreet,
      secondaryPostalCode: currentItem.secondaryPostalCode,
      secondaryCity: currentItem.secondaryCity,
      email: currentItem.email,
      website: currentItem.website,
      bankAccount: currentItem.bankAccount,
      primaryCountry: currentItem.primaryCountry && currentItem.primaryCountry.id > 0 ? {id: currentItem.primaryCountry.id} : null,
      secondaryCountry: currentItem.secondaryCountry && currentItem.secondaryCountry.id > 0 ? {id: currentItem.secondaryCountry.id} : null,
    };

    item.id = 1;

    response = await updateItem(`${dataUrl}/${item.id}`, item, state);

    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
    } else {
      response && onSaveAndClose({state});
    }
  }


  const fieldsValidation = [
    validateTextField(currentItem.fullName, "fullName"),
    validateTextField(currentItem.shortName, "shortName"),
    validateTextField(currentItem.regon, "regon"),
    validateTextField(currentItem.nip, "nip"),
    validateTextField(currentItem.krs, "krs"),
    validateTextField(currentItem.insuranceNumber, "insuranceNumber"),
    validateTextField(currentItem.primaryStreet, "primaryStreet"),
    validateTextField(currentItem.primaryPostalCode, "primaryPostalCode"),
    validateTextField(currentItem.primaryCity, "primaryCity"),
    validateTextField(currentItem.secondaryStreet, "secondaryStreet"),
    validateTextField(currentItem.secondaryPostalCode, "secondaryPostalCode"),
    validateTextField(currentItem.secondaryCity, "secondaryCity"),
    validateTextField(currentItem.insuranceNumber, "insuranceNumber"),
    validateTextField(currentItem.bankAccount, "bankAccount")
  ];


  const onClose = () => {
    onCloseDetails({state});
  };


  const getCompleteItem = async () => {
    const response = await getItems(`${dataUrl}`);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }


  useEffect(() => {
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
  }, [itemChanged])


  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])


  useEffect(() => {
    const getData = async () => {
      const [itemsData, legalEntitiesData, taxOfficesData, countriesData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(legalEntitiesURL),
          getItems(taxOfficesURL),
          getItems(countriesURL)
        ]);
      const success =
        itemsData.status === 200 &&
        legalEntitiesData.status === 200 &&
        taxOfficesData.status === 200 &&
        countriesData.status === 200
      if (success) {
        setLoading(false);
        setItems([itemsData.data]);
        setLegalEntitiesList(legalEntitiesData.data);
        setTaxOfficesList(taxOfficesData.data);
        setCountriesList(countriesData.data);
      } else {
        alert("Nie udało się pobrać danych.");
      }
    };
    getData();
  }, [])



  const companyConfig = {
    companyName: {
      title: "Nazwa firmy",
      fields: ['fullName', 'shortName']
    },
    registrationData: {
      title: "Dane rejestracyjne",
      fields: ['legalEntity.name', 'taxOffice.name', 'regon', 'nip', 'krs', 'insuranceNumber']
    },
    primaryAddress: {
      title: "Adres",
      fields: ['primaryStreet', 'primaryPostalCode', 'primaryCity', 'primaryCountry.name']
    },
    secondaryAddress: {
      title: "Adres korespondencyjny",
      fields: ['secondaryStreet', 'secondaryPostalCode', 'secondaryCity', 'secondaryCountry.name']
    },
    contactData: {
      title: "Dane kontaktowe",
      fields: ['email', 'website', 'primaryPhone', 'secondaryPhone']
    },
    bankingData: {
      title: "Konto bankowe",
      fields: ['bankAccount']
    }
  };





  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv />;
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <CompanyPreview
        state={{
          itemsList,
          legalEntitiesList,
          taxOfficesList,
          countriesList,
          setCurrentItem,
          setBackupItem,
          setCurrentFormState,
          currentFormState
        }}
        formHeader={`Edycja ${itemName}`}
        getCompleteItem={getCompleteItem}
        itemDictionary={itemDictionary}
        itemConfig={companyConfig}
      />;
  } else {
    dataSectionContent = <div>Nie znaleziono danych, dodaj nowy element.</div>;
  }


  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader title={"Edycja informacji o firmie"}/>
      ) : (
        <AppComponentCardHeader title={"Informacje o firmie"}/>
      )}
      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">

                <p className="text-center w-responsive mx-auto mb-1"></p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore mb-3" onClick={() => {
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>

                <div className="row">

                  <div className="col-md-12 mb-md-0 mb-5">
                    <form id="company-form" name="company-form">

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="fullName" className="">Pełna nazwa *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="fullName" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="shortName" className="">Skrócona nazwa *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="shortName" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="legalEntity" className="">Forma prawna *</label>
                        </div>
                        <div className="col-md-9">
                          <Select
                            label="legalEntity"
                            propertyName="legalEntityId"
                            required={false}
                            state={state}
                            itemsList={legalEntitiesList}
                            itemName="legalEntity"
                            displayNames={["name"]}
                          />
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="taxOffice" className="">Urząd skarbowy *</label>
                        </div>
                        <div className="col-md-9">
                          <Select
                            label="taxOffice"
                            propertyName="taxOfficeId"
                            required={false}
                            state={state}
                            itemsList={taxOfficesList}
                            itemName="taxOffice"
                            displayNames={["name"]}
                          />
                        </div>
                      </div>


                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="regon" className="">REGON *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="regon" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="nip" className="">NIP *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="nip" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="krs" className="">KRS *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="krs" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="insuranceNumber" className="">Nr ubezpieczenia</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="insuranceNumber" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="primaryStreet" className="">Ulica *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="primaryStreet" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="primaryPostalCode" className="">Kod pocztowy *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="primaryPostalCode" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="primaryCity" className="">Miasto *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="primaryCity" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="primaryCountry" className="">Państwo *</label>
                        </div>
                        <div className="col-md-9">
                          <Select
                            label="primaryCountry"
                            propertyName="primaryCountryId"
                            required={false}
                            state={state}
                            itemsList={countriesList}
                            itemName="primaryCountry"
                            displayNames={["name"]}
                          />
                        </div>
                      </div>
                      
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="secondaryStreet" className="">Ulica</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="secondaryStreet" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="secondaryPostalCode" className="">Kod pocztowy</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="secondaryPostalCode" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="secondaryCity" className="">Miasto</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="secondaryCity" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="secondaryCountry" className="">Państwo *</label>
                        </div>
                        <div className="col-md-9">
                          <Select
                            label="secondaryCountry"
                            propertyName="secondaryCountryId"
                            required={false}
                            state={state}
                            itemsList={countriesList}
                            itemName="secondaryCountry"
                            displayNames={["name"]}
                          />
                        </div>
                      </div>
                      
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="email" className="">e-mail *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="email" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="website" className="">WWW</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="website" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="primaryPhone" className="">Telefon *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="primaryPhone" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="secondaryPhone" className="">Telefon</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="secondaryPhone" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="bankAccount" className="">Konto bankowe *</label>
                        </div>
                        <div className="col-md-9">
                          <TextInput propertyName="bankAccount" required={true} state={state}/>
                        </div>
                      </div>

                    </form>
                  </div>

                </div>

              </section>
            </div>
            <ModalControls
              onDelete={null}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{ dataSectionContent }</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );


}

export default Company;