import React from 'react';
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import QNestedDatePicker from "../../../elements/QNestedDatePicker";

const EmployeeInfraction= ({ infraction, index, state, onInputChange, infractionsList, supervisorsList, onRemove }) => {

  return (
    <div className="row">
      <div className="col-md-1">
        <QNestedDatePicker
          pickerId={`infractionIncidentDateError-${infraction.id}`}
          errorParagraphId={`infractionIncidentDateError-${infraction.id}`}
          state={state}
          property="employeeInfractions"
          propertyPath={`employeeInfractions[${index}].incidentDate`}
          startDate={infraction.incidentDate ? new Date(infraction.incidentDate) : null}
          isDisabled={false}
        />
        <p id={`infractionIncidentDateError-${infraction.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>

      <div className="col-md-2">
        <SelectNested
          propertyName={`employeeInfractions[${index}].infraction`}
          state={state}
          itemsList={infractionsList}
          displayNames={["name"]}
          showInput={false}
          showDelete={false}
          useId={false}
        />
      </div>

      <div className="col-md-2">
        <SelectNested
          propertyName={`employeeInfractions[${index}].supervisorID`}
          state={state}
          itemsList={supervisorsList}
          displayNames={["firstName", "lastName"]}
          showInput={false}
          showDelete={false}
          useId={true}
        />
      </div>

      <div className="col-md-2">
        <input
          type="text"
          id={`comments-${infraction.id}`}
          value={infraction.comments || ""}
          onChange={(e) => onInputChange(e, infraction, "comments")}
          placeholder="Komentarz"
          className="form-control"
        />
      </div>

      <div className="col-md-1">
        <button className="btn btn-outline-danger" onClick={onRemove}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>

    </div>
  );
};

export default EmployeeInfraction;
