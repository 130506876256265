import React, {useMemo} from 'react';
import { paginateRows, sortRows } from "./tableHelpers";
import {onItemsListInfoButtonClick, resetInvalidInputField} from "../../helpers/ComponentHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEdit, faTrashAlt, faSquare, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useCrud from "../../hooks/useCrud";
import {Checkbox} from "@mui/material";
import FormCheck from "react-bootstrap/FormCheck";

const TableBody = ({ selectedItems, setSelectedItems,
                         columns,
                         sort,
                         activePage,
                         filteredRows,
                         rowsPerPage,
                         state,
                         checkRelatedItems,
                         formHeader,
                         relatedItemsUrl,
                         getCompleteItem
                       }) => {

  const sortedRows = useMemo(() => sortRows(filteredRows, sort, columns), [filteredRows, sort, columns]);
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)
  const { getRelatedChildrenByParentId } = useCrud();
  const setCurrentItem = state.setCurrentItem;
  const setBackupItem = state.setBackupItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const relatedItems = state.setRelatedItems;
  // let selectedItems = [];


  return (
    <tbody>
    {calculatedRows.map((item) => {
      return (
        <tr key={item.id}  onDoubleClick={async () => {
          relatedItemsUrl && getRelatedChildrenByParentId(`${relatedItemsUrl}/${item.id}`, item.id, relatedItems);
          if (getCompleteItem) {
            const completeItem = await getCompleteItem(item.id);
            setCurrentItem(completeItem);
            setBackupItem(completeItem);
          } else {
            setCurrentItem(item);
            setBackupItem(item);
          }
          onItemsListInfoButtonClick(currentFormState, setCurrentFormState, formHeader);
        }}>
          <td>
            <FormCheck
              type="checkbox"
              checked={selectedItems.has(item.id)}
              onChange={() => {
                const newSelectedItems = new Set(selectedItems);
                if (selectedItems.has(item.id)) {
                  newSelectedItems.delete(item.id);
                } else {
                  newSelectedItems.add(item.id);
                }
                setSelectedItems(newSelectedItems);
              }}
            />
          </td>
          {columns.map((column) => {
            if (column.visible !== false) {
              let content = item[column.accessor]; // test
              if (column.accessor === "editBtn") {
                return (
                  <td key={`${item.id}-${column.accessor}`}>
                    <button className='btn btn-outline-info' onClick={async () => {
                      relatedItemsUrl && getRelatedChildrenByParentId(`${relatedItemsUrl}/${item.id}`, item.id, relatedItems);
                      if (getCompleteItem) {
                        const completeItem = await getCompleteItem(item.id);
                        setCurrentItem(completeItem);
                        setBackupItem(completeItem);
                      } else {
                        setCurrentItem(item);
                        setBackupItem(item);
                      }
                      onItemsListInfoButtonClick(currentFormState, setCurrentFormState, formHeader);
                    }}><FontAwesomeIcon icon={faEdit}/></button>
                  </td>
                )
              } else if (column.accessor === "deleteBtn") {
                return (
                  <td key={`${item.id}-${column.accessor}`}>
                    <button className='btn btn-outline-danger' onClick={() => {
                      setCurrentItem(item);
                      checkRelatedItems(item.id);
                    }}><FontAwesomeIcon icon={faTrashAlt}/></button>
                  </td>
                )
              } else if (column.format) {
                content = column.format(item[column.accessor]);
                const bcolor = item[column.accessor] && typeof item[column.accessor] === 'object' && item[column.accessor].color ? item[column.accessor].color : null;
                return <td key={`${item.id}-${column.accessor}`}><span
                  className={`${content.trim().length > 0 ? "accessor_object" : ""} ${bcolor ? "custom_background" : ""}`}
                  style={bcolor ? { backgroundColor: bcolor } : {}}
                >{content}</span></td>
              } else if (column.type === "color") {
                return <td key={`${item.id}-${column.accessor}`}><FontAwesomeIcon style={{color: item.color}} icon={faSquare}/></td>
              } else if (column.type === "bool") {
                return   <td key={`${item.id}-${column.accessor}`}>
                  {item[column.accessor] ? (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      readOnly={true}
                      checked={true}
                    />
                  ) : (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      readOnly={true}
                      checked={false}
                    />
                  )}
                </td>
              }
              return <td key={`${item.id}-${column.accessor}`}>{item[column.accessor]}</td>
            }
          })}
        </tr>
      )
    })}
    </tbody>
  );
};
export default TableBody;

