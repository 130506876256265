import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultFormState } from "../../../../defaults/Forms";
import { resourcePlanDefault } from "../../../../defaults/Items";
import {
  onSaveNotClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import useCrud from "../../../../hooks/useCrud";
import { Table } from "../../../table/Table";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Toggler from "../../../elements/Toggler";
import QTimePicker from "../../../elements/QTimePicker";
import ResourcePlanItemsCard from "../resource_plan_item/ResourcePlanItemsCard";
import ModalControlsSaveOnly from "../../common/ModalControlsSaveOnly";

const ResourcePlan = () => {

  const dataUrl = "/resource-plans";
  const positionsUrl = "/positions";
  const settingsUrl = "/settings";
  const relatedItemsUrl = null; // if no need to check it, initialize with null and remove RelatedItemsList from details modal
  const defaultItem = resourcePlanDefault;
  const itemName = "plan zapotrzebowania";
  const itemNames = "plany zapotrzebowania";
  const navigate = useNavigate();
  const location = useLocation();
  const {createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId} = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [updateConflict, setUpdateConflict] = useState(false);
  const [positionsList, setPositionsList] = useState([]);
  const [settingsList, setSettingsList] = useState([]);

  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false},
    {label: "Nazwa", accessor: "name", sortable: true, searchable: true},
    {label: "Skrócona nazwa", accessor: "shortName", sortable: true, searchable: true},
    {label: "Opis", accessor: "description", sortable: true, searchable: true},
    {label: "Aktywny", accessor: "active", sortable: true, searchable: false, type: "bool"},
    {label: "Start pracy", accessor: "workStart", sortable: true, searchable: true},
    {label: "Koniec pracy", accessor: "workEnd", sortable: true, searchable: true},
    {label: "Liczba osób", accessor: "totalStaff", sortable: true, searchable: true},
    {label: "Liczba godzin", accessor: "totalHours", sortable: true, searchable: true},
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];

  const formatDateToTimeString = (date) => {
    if (!date) return null;
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };


  const parseTimeToDate = (timeString) => {
    if (!timeString) return null;
    if (typeof timeString === 'string') {
      const [hours, minutes] = timeString.split(':').map(Number);
      return new Date(1970, 0, 1, hours || 0, minutes || 0, 0);
    }
    if (timeString instanceof Date) {
      return timeString;
    }
    throw new Error('Invalid time format');
  };


  const formatToLocalTime = (isoString) => {
    if (/^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/.test(isoString)) {
      return isoString.slice(0, 5);
    }
    const date = new Date(isoString);
    return date.toISOString().slice(11, 16);
  };


  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }

  const [workStart, setWorkStart] = useState(null);
  const [workEnd, setWorkEnd] = useState(null);

  useEffect(() => {
    if (state.currentItem.workStart) {
      setWorkStart(parseTimeToDate(state.currentItem.workStart));
    }
    if (state.currentItem.workEnd) {
      setWorkEnd(parseTimeToDate(state.currentItem.workEnd));
    }
  }, [state.currentItem]);


  const onDelete = async () => {
    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  }


  const cleanupItems = () => {

    currentItem.resourcePlanItems = currentItem.resourcePlanItems.filter(
      item => item.position && typeof item.position.id === 'number' && item.position.id > 0
    );

    currentItem.totalStaff = currentItem.resourcePlanItems.reduce(
      (sum, item) => sum + Number(item.requiredPeopleCount),
      0
    );


  }


  const onSaveItemClick = async (e) => {
    e.preventDefault();

    cleanupItems();

    if (!currentItem.name || !currentItem.shortName) {
      if (!currentItem.name) {
        let nameInput = document.getElementById("name");
        nameInput.classList.add("form-input-invalid");
        nameInput.placeholder = `ten element jest wymagany`;
      }

      if (!currentItem.shortName) {
        let shortNameInput = document.getElementById("shortName");
        shortNameInput.classList.add("form-input-invalid");
        shortNameInput.placeholder = `ten element jest wymagany`;
      }
      return;
    }

    let response;

    const item = {
      id: currentItem.id,
      shortName: currentItem.shortName,
      name: currentItem.name,
      description: currentItem.description,
      workStart: formatToLocalTime(currentItem.workStart),
      workEnd: formatToLocalTime(currentItem.workEnd),
      resourcePlanItems: currentItem.resourcePlanItems,
      createdAt: currentItem.createdAt,
      createdBy: currentItem.createdBy,
      updatedAt: currentItem.updatedAt,
      updatedBy: currentItem.updatedBy,
      active: currentItem.active,
      totalStaff: currentItem.totalStaff,
      totalHours: currentItem.totalHours
    };


    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataUrl, item, state);
    } else {
      response = await updateItem(`${dataUrl}/${item.id}`, item, state);
    }

    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
      setUpdateConflict(true);

    } else {
      response && onSaveNotClose({state});
    }
  }

  const checkRelatedItems = async (id) => {
    //const data = await getRelatedChildrenByParentId(`${ relatedItemsUrl }/${ id }`, id, setEventsList);
    //data.length === 0 ? setAllowDelete(true) : setAllowDelete(false);
    setAllowDelete(true);
  }


  const onClose = () => {
    setUpdateConflict(false);
    onCloseDetails({state});
  };


  useEffect(() => {
    if (updateConflict) setUpdateConflict(false);
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
//    console.log(currentItem);
  }, [itemChanged])


  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])


  useEffect(() => {
    const getData = async () => {
      const [itemsData, positionsData, settingsData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(positionsUrl),
          getItems(settingsUrl)
        ]);

      const success =
        itemsData.status === 200 &&
        positionsData.status === 200 &&
        settingsData.status === 200
      ;

      if (success) {
        setLoading(false);
        setItems(itemsData.data);
        setPositionsList(positionsData.data);
        setSettingsList(settingsData.data);
      } else {
        navigate('/login', { state: { from: location }, replace: true})
      }
    };

    getData();
  }, [])


  const getCompleteItem = async (id) => {
    const response = await getItems(`${dataUrl}/${id}`);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }

  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem,
    currentFormState,
    setCurrentFormState,
    formDescription: `Tu możesz dodać nowy element.`,
    formHeader: `Nowy element`,
    buttonTitle: ` Dodaj nowy Plan zapotrzebowania`
  }


  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv/>
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows={itemsList}
        columns={columns}
        state={state}
        checkRelatedItems={checkRelatedItems}
        formHeader={`Edycja ${itemName}`}
        relatedItemsUrl={relatedItemsUrl}
        getCompleteItem={getCompleteItem}
      />
  } else {
    dataSectionContent =
      <div className={"warn_info"}><FontAwesomeIcon
        className={"warning-icon div-visible"} icon={faExclamationCircle}/>&nbsp;&nbsp;&nbsp;NIE ZNALEZIONO ŻADNYCH DANYCH</div>
  }


  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader title={"Edycja pozycji słownika " + `${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}` + " -> " + currentItem.name}/>
      ) : (
        <AppComponentCardHeader title={"Pozycje słownika " + `${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}`}
                                buttonProps={addDataButtonProps}/>
      )}
      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{currentFormState.formDescription}</p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={() => {
                    setUpdateConflict(false)
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>

                <div className="col-md-12 mb-md-0 mb-5">
                  <form id="add-resourcePlan-form" name="add-resourcePlan-form">

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="name" className="">Nazwa *</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="name" required={true} state={ state }/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="shortName" className="">Skrót *</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="shortName" maxlen="10" required={true} state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="description" className="">Opis</label>
                      </div>
                      <div className="col-md-9">
                        <TextArea propertyName="description" required={false} rows="2" state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="active" className="">Aktywny</label>
                      </div>
                      <div className="col-md-1">
                        <Toggler propertyName="active" state={state} />
                      </div>
                    </div>

                    <div className="row form-group mb-2">
                      <div className="col-md-3 form-label">
                      </div>
                      <div className="col-md-1">
                        <label htmlFor="workStart" className=""><span className="required">*</span> Początek pracy</label>
                        <QTimePicker
                          pickerId="workStart"
                          errorParagraphId="wrongWorkStartTimeFormat"
                          state={state}
                          propertyName="workStart"
                          startTime={workStart}
                          setStartTime={setWorkStart}
                          caption="Godzina rozpoczęcia pracy"
                        />
                        <p id="wrongWorkStartTimeFormat" className={"err_info offscreen"}>
                          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format czasu
                        </p>
                      </div>

                      <div className="col-md-1">
                        <label htmlFor="workEnd" className=""><span className="required">*</span> Koniec pracy</label>
                        <QTimePicker
                          pickerId="workEnd"
                          errorParagraphId="wrongWorkEndTimeFormat"
                          state={state}
                          propertyName="workEnd"
                          startTime={workEnd}
                          setStartTime={setWorkEnd}
                          caption="Godzina zakończenia pracy"
                        />
                        <p id="wrongWorkEndTimeFormat" className={"err_info offscreen"}>
                          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format czasu
                        </p>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-1">
                        <label htmlFor="totalStaff" className="">Liczba osób</label>
                        <div>
                          <TextInput propertyName="totalStaff" disabled={true} state={state}/>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <label htmlFor="totalHours" className="">Liczba godzin</label>
                        <div>
                          <TextInput propertyName="totalHours" disabled={true} state={state}/>
                        </div>
                      </div>
                    </div>

                    <div className="row form-group mb-2">
                      <div className="col-md-3 form-label">
                        <label htmlFor="resourcePlanItems" className=""></label>
                      </div>
                      <div className="col-md-9">
                        <ResourcePlanItemsCard state={state} positionsList={positionsList} settings={settingsList}/>
                      </div>
                    </div>


                    <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                      Nie można dodać ani zmienić tego elementu, ponieważ istnieje już wpis o tej nazwie i/lub skrócie
                    </p>
                  </form>
                </div>

              </section>
            </div>
            <ModalControlsSaveOnly
              onDelete={onDelete}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{ dataSectionContent }</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );
}

export default ResourcePlan;