import React, { useEffect, useState } from 'react';
import { resetInvalidInputField } from "../../../../helpers/ComponentHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faInfoCircle, faLightbulb } from "@fortawesome/free-solid-svg-icons";

const EmployeeSkillsCard = ({ state, skillsList, settings }) => {
  const currentItem = state.currentItem;
  let employeeSkills = currentItem?.employeeSkills?.slice() || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [skillsNote, setSkillsNote] = useState(false);
  const minSkillValue = settings.skillEvaluationMinimumValue;
  const maxSkillValue = settings.skillEvaluationMaximumValue;
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const findEmployeeSkill = (skillId) => {
    return employeeSkills.find((employeeSkill) => employeeSkill.skill.id === skillId);
  };


  const checkSkillsEvaluation = (skills) => {
    let invalidSkillFound = false;

    skills.forEach((skill) => {
      if (!skill.evaluation || skill.evaluation === "0") {
        invalidSkillFound = true;
      }
    });

    setSkillsNote(invalidSkillFound);
  };


  useEffect(() => {
    checkSkillsEvaluation(employeeSkills);
  }, [employeeSkills]);



  const onEmployeeSkillsChange = (e, skill) => {
    const existingEmployeeSkill = findEmployeeSkill(skill.id);

    if (e.currentTarget.checked) {
      if (!existingEmployeeSkill) {
        employeeSkills.push({
          skill: { id: skill.id, name: skill.name},
          employeeId: currentItem.id,
          evaluation: "",
          description: ""
        });
      }

      const inputField = document.getElementById(`evaluation-${skill.id}`);
      if (!existingEmployeeSkill || existingEmployeeSkill.evaluation === "" || existingEmployeeSkill.evaluation === "0") {
        inputField.classList.add("form-input-invalid");
      }
    } else {
      employeeSkills = employeeSkills.filter((empSkill) => empSkill.skill.id !== skill.id);

      const inputField = document.getElementById(`evaluation-${skill.id}`);
      inputField.classList.remove("form-input-invalid");
    }

    setCurrentItem((currentItem) => ({
      ...currentItem,
      employeeSkills: employeeSkills
    }));

    setItemChanged(!itemChanged);
  };


  const onInputChange = (e, skill, field) => {
    const value = e.target.value;

    const updatedSkills = employeeSkills.map((empSkill) => {
      if (empSkill.skill.id === skill.id) {
        return { ...empSkill, [field]: value };
      }
      return empSkill;
    });

    setCurrentItem((currentItem) => ({
      ...currentItem,
      employeeSkills: updatedSkills
    }));

    setItemChanged(!itemChanged);

    const inputField = document.getElementById(`evaluation-${skill.id}`);
    if (field === "evaluation" && (value === "" || value === "0")) {
      inputField.classList.add("form-input-invalid");
    } else {
      inputField.classList.remove("form-input-invalid");
    }
  };





  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faLightbulb} className="me-2 header-icon"/>
            <span>Umiejętności</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="employeeSkills">
          {skillsList?.map((skill) => {
            const employeeSkill = findEmployeeSkill(skill.id);
            const isChecked = !!employeeSkill; // Zaznaczone, jeśli obiekt istnieje w employeeSkills

            return (
              <div key={skill.id} className="row mb-2 align-items-center">
                <div className="col-md-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={skill.name}
                    checked={isChecked} // Checkbox jest zaznaczony, jeśli skill istnieje w employeeSkills
                    onChange={(e) => onEmployeeSkillsChange(e, skill)}
                    onClick={() => resetInvalidInputField("employeeSkills")}
                  />
                </div>

                <div className="col-md-3">
                  <label className="form-check-label" htmlFor={skill.name} style={{ color: isChecked ? 'black' : 'lightgrey' }}>
                    {skill.name}
                  </label>
                </div>

                <div className="col-md-1">
                  <input
                    type="number"
                    min={minSkillValue}
                    max={maxSkillValue}
                    id={`evaluation-${skill.id}`}
                    value={employeeSkill ? employeeSkill.evaluation || "" : ""}
                    onChange={(e) => onInputChange(e, skill, "evaluation")}
                    placeholder="Ocena"
                    className="form-control"
                    disabled={!isChecked}
                  />
                </div>
                <div className="col-md-4">
                  <input
                    id={`description-${skill.id}`}
                    value={employeeSkill ? employeeSkill.description || "" : ""}
                    onChange={(e) => onInputChange(e, skill, "description")}
                    placeholder="Uwagi"
                    className="form-control"
                    disabled={!isChecked}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <p id="roles_note" className={skillsNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
        Umiejętność musi mieć ocenę, jeśli została wybrana
      </p>
    </>
  );
};

export default EmployeeSkillsCard;



