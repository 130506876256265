import React, {useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faChalkboardUser,
  faLightbulb, faCertificate
} from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import { employeeCertificationDefault } from "../../../../defaults/Items";
import EmployeeCertification from "./EmployeeCertification";

const EmployeeCertificationsCard = ({ state, certificationTypesList, settings }) => {

  const currentItem = state.currentItem;
  let employeeCertifications = currentItem?.employeeCertifications?.slice() || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [certificationNote, setCertificationNote] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);


  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };


  const onInputChange = (e, certification, field) => {
    const updatedCertifications = employeeCertifications.map((item) =>
      item.id === certification.id ? { ...item, [field]: e.target.value } : item
    );
    setCurrentItem({ ...currentItem, employeeCertifications: updatedCertifications });
    setItemChanged(!itemChanged);
  };


  const addCertification = () => {
    // const newCertification = { ...employeeCertificationDefault, EID: currentItem.id, id: uuidv4()};
    const existingIds = currentItem.employeeCertifications.map(cert => cert.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }

    const newCertification = { ...employeeCertificationDefault, EID: currentItem.id, id: newId};
    const updatedCertifications = [...employeeCertifications, newCertification];
    setCurrentItem({ ...currentItem, employeeCertifications: updatedCertifications });
    setItemChanged(!itemChanged);

  };


  const removeCertification = (id) => {
    const updatedCertifications = employeeCertifications.filter(item => item.id !== id);
    setCurrentItem({ ...currentItem, employeeCertifications: updatedCertifications });
    setItemChanged(!itemChanged);
  };

  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faCertificate} className="me-2 header-icon"/>
            <span>Zaświadczenia pracownika {!isAccordionOpen ? "(" + currentItem.employeeCertifications.length + ")" : ""}</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="employeeCertifications">
          <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addCertification}><FontAwesomeIcon
            icon={ faCirclePlus }/> Dodaj</button>
          {employeeCertifications.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-1 header-label">Data wydania</div>
              <div className="col-md-2 header-label">Wydany przez</div>
              <div className="col-md-1 header-label">Ważny od</div>
              <div className="col-md-1 header-label">Ważny do</div>
              <div className="col-md-2 header-label">Typ zaświadczenia</div>
              <div className="col-md-1 header-label">Bezterminowy</div>
              <div className="col-md-1 header-label">Ważny</div>
              <div className="col-md-3 header-label">Uwagi</div>
            </div>
          )}
          {employeeCertifications.length > 0 ? (
            employeeCertifications.map((certification, index) => (
              <EmployeeCertification
                key={index}
                certification={certification}
                index={index}
                state={state}
                onInputChange={onInputChange}
                certificationTypesList={certificationTypesList}
                onRemove={() => removeCertification(certification.id)}
              />
            ))
          ) : (
            <p>Brak zaświadczeń</p>
          )}
        </div>
      </div>
      <p id="certificationNote" className={certificationNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
        Uzupełnij dane zaświadczenia
      </p>
    </>
  );
};

export default EmployeeCertificationsCard;



