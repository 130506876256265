import React from 'react';
import Toggler from "../../../elements/Toggler";
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import QNestedDatePicker from "../../../elements/QNestedDatePicker";

const EmployeeTraining = ({ training, index, state, onInputChange, trainingsList, supervisorsList, onRemove, settings }) => {

  return (
    <div className="row">
      <div className="col-md-1">
        <QNestedDatePicker
          pickerId={`trainingDateFromError-${training.id}`}
          errorParagraphId={`trainingDateFromError-${training.id}`}
          state={state}
          property="employeeTrainings"
          propertyPath={`employeeTrainings[${index}].dateFrom`}
          startDate={training.dateFrom ? new Date(training.dateFrom) : null}
          isDisabled={false}
        />
        <p id={`trainingDateFromError-${training.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>

      </div>

      <div className="col-md-1">
        <QNestedDatePicker
          pickerId={`trainingDateToError-${training.id}`}
          errorParagraphId={`trainingDateToError-${training.id}`}
          state={state}
          property="employeeTrainings"
          propertyPath={`employeeTrainings[${index}].dateTo`}
          startDate={training.dateTo ? new Date(training.dateTo) : null}
          isDisabled={false}
        />
        <p id={`trainingDateToError-${training.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>

      <div className="col-md-2">
        <SelectNested
          propertyName={`employeeTrainings[${index}].training`}
          state={state}
          itemsList={trainingsList}
          displayNames={["name"]}
          showInput={false}
          showDelete={false}
          useId={false}
        />
      </div>

      <div className="col-md-2">
        <SelectNested
          propertyName={`employeeTrainings[${index}].trainerID`}
          state={state}
          itemsList={supervisorsList}
          displayNames={["firstName", "lastName"]}
          showInput={false}
          showDelete={false}
          useId={true}
        />
      </div>

      <div className="col-md-1">
        <input
          type="number"
          id={`training_evaluation-${training.id}`}
          value={training.evaluation || ""}
          onChange={(e) => onInputChange(e, training, "evaluation")}
          min={settings.trainingEvaluationMinimumValue}
          max={settings.trainingEvaluationMaximumValue}
          placeholder="Opis"
          className="form-control"
        />
      </div>

      <div className="col-md-1">
        <QNestedDatePicker
          pickerId={`trainingEvaluationDateError-${training.id}`}
          errorParagraphId={`trainingEvaluationDateError-${training.id}`}
          state={state}
          property="employeeTrainings"
          propertyPath={`employeeTrainings[${index}].evaluationDate`}
          startDate={training.evaluationDate ? new Date(training.evaluationDate) : null}
          isDisabled={false}
        />
        <p id={`trainingEvaluationDateError-${training.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>

      <div className="col-md-1">
        <Toggler propertyName={`employeeTrainings[${index}].completed`} state={state} />
      </div>

      <div className="col-md-2">
        <input
          type="text"
          id={`comments-${training.id}`}
          value={training.comments || ""}
          onChange={(e) => onInputChange(e, training, "comments")}
          placeholder="Opis"
          className="form-control"
        />
      </div>
      <div className="col-md-1">
        <button className="btn btn-outline-danger" onClick={onRemove}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    </div>
  );
};

export default EmployeeTraining;
