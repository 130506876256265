import React, {useEffect, useState, useRef} from "react";
import TextInput from "../../../elements/TextInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faInfoCircle, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import UserRolesCard from "../users/UserRolesCard";
import {Button} from "react-bootstrap";
import Select from "../../../elements/Select";
import Toggler from "../../../elements/Toggler";
import EmployeeAddressCard from "./EmployeeAddressCard";
import EmployeeIdentityCard from "./EmployeeIdentityCard";
import QDatePicker from "../../../elements/QDatePicker";

const Employee_Personal_Data_Tab = ({state}) => {

  const [showPasswordSection, setShowPasswordSection] = useState(false);
  const [passwordsEqual, setPasswordsEqual] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [oldPwdVisible, setOldPwdVisible] = useState(false);
  const [newPwdVisible, setNewPwdVisible] = useState(false);
  const [confPwdVisible, setConfPwdVisible] = useState(false);

  const oldPasswordRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmationRef = useRef();
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%]).{8,24}$/; // requires 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special char and has to be 8-24 chars long

  const [startDate, setStartDate] = useState(state.currentItem.dateOfBirth);

  const passwordToggler = (passwordField, passwordTogglerIcon, setPasswordVisible) => {
    const password = document.getElementById(passwordField);
    const toggler = document.getElementById(passwordTogglerIcon);
    const isPasswordVisible = password.type === "password";
    password.type = isPasswordVisible ? "text" : "password";
    toggler.classList.toggle("pwd_visible", isPasswordVisible);
    setPasswordVisible(isPasswordVisible);
  };

  useEffect(() => {

    const passwordField = document.getElementById("password");
    const newPassword = passwordField.value;

    if (passwordsEqual && state.validPassword) {
      state.setItemChanged(!state.itemChanged);
      state.setCurrentItem(currentItem => ({...currentItem,
        password: newPassword === "" ? state.backupItem.password : newPassword}));
      state.setCurrentFormState({...state.currentFormState, formSaveButtonDisabled: false});
    } else {
      state.setCurrentItem(currentItem => ({...currentItem,
        password: state.backupItem.password}));
      state.setCurrentFormState({...state.currentFormState, formSaveButtonDisabled: true});
    }

  }, [passwordsEqual, state.validPassword])


  const comparePasswords = (e) => {

    const passwordField = document.getElementById("password");
    const passwordConfirmationField = document.getElementById("passwordConf");
    const newPassword = passwordField.value;
    const passwordsAreEqual = passwordField.value === passwordConfirmationField.value;
    setPasswordsEqual(passwordsAreEqual);
    //console.log("REGEX test: " + PWD_REGEX.test(newPassword));

    if ((newPassword && PWD_REGEX.test(newPassword)) || newPassword === "123") {
      //console.log("Passwords equal and regex passed");
      setPasswordIsValid(true);
      state.setValidPassword(true);
    }

    if (newPassword && !PWD_REGEX.test(newPassword) && newPassword !== "123") {
      //console.log("Regex not passed or password <> 123");
      setPasswordIsValid(false);
      state.setValidPassword(false);
    }
  }


  const togglePasswordSection = () => {
    const passwordSection = document.getElementById("passwordSection");
    const button = document.getElementById("change-password");
    const closeButton = document.getElementById("save-and-close-button");

    if (passwordSection.classList.contains("offscreen")) {
      button.innerText = "Anuluj"
      passwordSection.classList.remove("offscreen");
      setShowPasswordSection(true);

    } else {
      const passwordField = document.getElementById("password");
      const passwordConfirmationField = document.getElementById("passwordConf");
      const oldPasswordField = document.getElementById("oldPassword");
      const backupItem = state.backupItem;
      passwordField.value="";
      passwordConfirmationField.value="";
      oldPasswordField.value="";
      state.currentItem.password = null;

      const propertiesAreEqual = Object.keys(state.currentItem).every(key => {
        if (key === 'password') return true; // Ignore password for comparison
        return state.currentItem[key] === backupItem[key];
      });

      if (state.currentItem.password === null && propertiesAreEqual) {
        state.setCurrentFormState({
          ...state.currentFormState,
          formSaveButtonDisabled: true,
        });
      }

      button.innerText = "Zmień hasło"
      passwordSection.classList.add("offscreen");;
      setShowPasswordSection(false);
    }
  }

    const identityCardType = {
      label: "Typ dokumentu tożsamości",
      itemName: "identityCardType",
      propertyName: "identityCardTypeId"
    }

    const identityCard = {
      cardId: "identityCardId",
      cardIssueDate: "identityCardIssueDate",
      cardExpiryDate: "identityCardExpiryDate",
      issuedBy: "issuedBy",
      issueLocation: "issueLocation"
    }

    const primaryCountry = {
      label: "Kraj",
      itemName: "primaryAddressCountry",
      propertyName: "primaryAddressCountryId"
    }

    const secondaryCountry = {
      label: "Kraj",
      itemName: "secondaryAddressCountry",
      propertyName: "secondaryAddressCountryId"
    }

    const primaryAddress = {
      street: "primaryAddressStreet",
      streetNumber: "primaryAddressStreetNumber",
      postalCode: "primaryAddressPostalCode",
      city: "primaryAddressCity",
    }

    const secondaryAddress = {
      street: "secondaryAddressStreet",
      streetNumber: "secondaryAddressStreetNumber",
      postalCode: "secondaryAddressPostalCode",
      city: "secondaryAddressCity",
    }


  return (
    <>
      <h4 style={{marginLeft: "10px"}}>Dane osobowe</h4>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="login" className=""><span className="required">*</span> Login</label>
        </div>
        <div className="col-md-9">
          <TextInput propertyName="login" required={true} state={state} disabled={!state.currentFormState.formAddingDataMode}/>
        </div>
      </div>

      {!state.currentFormState.formAddingDataMode && (
        <div className="row form-group mb-2">
          <div className="col-md-3 form-label">
            <label htmlFor="login" className=""></label>
          </div>
          <div className="col-md-9">
            <Button variant="secondary" id="change-password" onClick={() => {
              togglePasswordSection()
            }}>
              Zmień hasło
            </Button>
          </div>
        </div>
      )}

      <div id="passwordSection" className={state.currentFormState.formAddingDataMode || showPasswordSection ? "" : "offscreen"}>

        <div className={"row form-group mb-2" + (!state.currentFormState.formAddingDataMode ? "" : " offscreen")}>
          <div className="col-md-3 form-label">
            <label htmlFor="oldPassword" className=""><span className="required">*</span> Stare hasło</label>
          </div>
          <div className="col-md-9" style={{display: "flex", flexDirection: "column", position: "relative"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <input
                className="form-control"
                type="password"
                id="oldPassword"
                required
                ref={oldPasswordRef}
              />
              <span id="oldPassword_toggler" className="password_toggler" onClick={() => passwordToggler("oldPassword", "oldPassword_toggler", setOldPwdVisible)}><FontAwesomeIcon icon={oldPwdVisible ? faEye : faEyeSlash}/></span>
            </div>
          </div>
        </div>

        <div className="row form-group mb-2">
          <div className="col-md-3 form-label">
            <label htmlFor="password0" className=""><span className="required">*</span> Nowe hasło</label>
          </div>
          <div className="col-md-9" style={{display: "flex", flexDirection: "column", position: "relative"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <input
                className="form-control"
                type="password"
                id="password"
                onChange={(e) => comparePasswords(e)}
                required
                ref={passwordRef}
              />
              <span id="password_toggler" className="password_toggler" onClick={() => passwordToggler("password", "password_toggler", setNewPwdVisible)}><FontAwesomeIcon icon={newPwdVisible ? faEye : faEyeSlash}/></span>
            </div>
            <p id="pwdnote" className={!passwordIsValid ? "err_info" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
              8 do 24 znaków. Musi zawierać przynajmniej jedną wielką i jedną małą literę, cyfrę i znak specjalny.
              Dozwolone znaki specjalne: <span aria-label="exclamation mark">!</span> <span
              aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span>
              <span aria-label="percent">%</span>
            </p>
          </div>
        </div>

        <div className="row form-group mb-2">
          <div className="col-md-3 form-label">
            <label htmlFor="password1" className=""><span className="required">*</span> Potwierdź hasło</label>
          </div>
          <div className="col-md-9" style={{display: "flex", flexDirection: "column", position: "relative"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <input
                className="form-control"
                type="password"
                id="passwordConf"
                onChange={(e) => comparePasswords(e)}
                required
                ref={passwordConfirmationRef}
              />
              <span id="passwordConf_toggler" className="password_toggler" onClick={() => passwordToggler("passwordConf", "passwordConf_toggler", setConfPwdVisible)}><FontAwesomeIcon icon={confPwdVisible ? faEye : faEyeSlash}/></span>
            </div>
            <p id="pwdnote" className={!passwordsEqual ? "err_info" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
              Podane hasła są różne
            </p>
          </div>

        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="gender" className=""><span className="required">*</span> Płeć</label>
        </div>
        <div className="col-md-9">
          <Select
            label="Płeć"
            propertyName="gender"
            required={false}
            state={state}
            itemsList={state.gendersList}
            itemName="gender"
            displayNames={["name"]}
          />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="firstName" className=""><span className="required">*</span> Imię</label>
        </div>
        <div className="col-md-4">
          <TextInput propertyName="firstName" required={true} state={state}/>
        </div>
        <div className="col-md-1 form-label">
          <label htmlFor="secondName" className="">Drugie imię</label>
        </div>
        <div className="col-md-4">
          <TextInput propertyName="secondName" required={true} state={state}/>
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="lastName" className=""><span className="required">*</span> Nazwisko</label>
        </div>
        <div className="col-md-9">
          <TextInput propertyName="lastName" required={true} state={state}/>
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="dateOfBirth" className=""><span className="required">*</span> Data urodzenia</label>
        </div>
        <div className="col-md-2">
          <QDatePicker
            pickerId="dateOfBirth"
            errorParagraphId="wrongBirthDateFormat"
            state={state}
            propertyName="dateOfBirth"
            startDate={startDate}
            setStartDate={setStartDate}
          />
          <p id="wrongBirthDateFormat" className={"err_info offscreen"}>
            <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
          </p>
        </div>

        <div className="col-md-1 form-label">
          <label htmlFor="placeOfBirth" className="">Miejsce urodzenia</label>
        </div>
        <div className="col-md-6">
          <TextInput propertyName="placeOfBirth" required={true} state={state}/>
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="mothersName" className="">Imię matki</label>
        </div>
        <div className="col-md-2">
          <TextInput propertyName="mothersName" required={true} state={state}/>
        </div>
        <div className="col-md-1 form-label">
          <label htmlFor="fathersName" className="">Imię ojca</label>
        </div>
        <div className="col-md-2">
          <TextInput propertyName="fathersName" required={true} state={state}/>
        </div>
        <div className="col-md-1 form-label">
          <label htmlFor="maidenName" className="">Nazwisko panieńskie</label>
        </div>
        <div className="col-md-3">
          <TextInput propertyName="maidenName" required={true} state={state}/>
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="pesel" className="">PESEL</label>
        </div>
        <div className="col-md-4">
          <TextInput propertyName="pesel" required={true} state={state}/>
        </div>
        <div className="col-md-1 form-label">
          <label htmlFor="nip" className="">NIP</label>
        </div>
        <div className="col-md-4">
          <TextInput propertyName="nip" required={true} state={state}/>
        </div>
      </div>


      <div className="row form-group mb-4">
        <div className="col-md-3 form-label">
          <label htmlFor="IdentityCard" className="">Dokument tożsamości</label>
        </div>
        <div className="col-md-9">
          <div className={"row"}>
            <div className="col-md-12">
              <div className="md-form mb-0">
                <div className="card">
                  <EmployeeIdentityCard cardHeader="Dokument tożsamości" state={state}
                                       identityCardProps={identityCard} identityCardTypeList={state.identityCardTypesList}
                                       identityCardTypeProps={identityCardType}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="maritalStatus" className="">Stan cywilny</label>
        </div>
        <div className="col-md-9">
          <Select
            label="Stan cywilny"
            propertyName="maritalStatus"
            required={false}
            state={state}
            itemsList={state.maritalStatusesList}
            itemName="maritalStatus"
            displayNames={["name"]}
          />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="primaryPhone" className="">Główny telefon</label>
        </div>
        <div className="col-md-4">
          <TextInput propertyName="primaryPhone" required={true} state={state}/>
        </div>
        <div className="col-md-1 form-label">
          <label htmlFor="secondaryPhone" className="">Pomocniczy telefon</label>
        </div>
        <div className="col-md-4">
          <TextInput propertyName="secondaryPhone" required={true} state={state}/>
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="primaryEmail" className=""><span className="required">*</span> Główny email</label>
        </div>
        <div className="col-md-4">
          <TextInput propertyName="primaryEmail" required={true} state={state}/>
        </div>
        <div className="col-md-1 form-label">
          <label htmlFor="secondaryEmail" className="">Pomocniczy email</label>
        </div>
        <div className="col-md-4">
          <TextInput propertyName="secondaryEmail" required={true} state={state}/>
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="iceContact" className="">ICE</label>
        </div>
        <div className="col-md-9">
          <TextInput propertyName="iceContact" required={true} state={state}/>
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="communicationByEmail" className="">Komunikacja przez email</label>
        </div>
        <div className="col-md-1">
          <Toggler propertyName="communicationByEmail" state={state} />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="communicationBySMS" className="">Komunikacja przez SMS</label>
        </div>
        <div className="col-md-1">
          <Toggler propertyName="communicationBySMS" state={state} />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="bankAccountNumber" className="">Konto bankowe</label>
        </div>
        <div className="col-md-9">
          <TextInput propertyName="bankAccountNumber" required={true} state={state}/>
        </div>
      </div>

      <div className="row form-group mb-4">
        <div className="col-md-3 form-label">
          <label htmlFor="addresses" className="">Adresy</label>
        </div>
        <div className="col-md-9">
          <div className={"row"}>
            <div className="col-md-6">
              <div className="md-form mb-0">
                <div className="card">
                  <EmployeeAddressCard cardHeader="Główny adres" state={state}
                                              addressProps={primaryAddress} countriesList={state.countriesList}
                                              countryProps={primaryCountry}/>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form mb-0">
                <div className="card">
                  <EmployeeAddressCard cardHeader="Pomocniczy adres" state={state}
                                              addressProps={secondaryAddress} countriesList={state.countriesList}
                                              countryProps={secondaryCountry}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="nfzBranch" className="">Oddział NFZ</label>
        </div>
        <div className="col-md-9">
          <Select
            label="Oddział NFZ"
            propertyName="nfzBranch"
            required={false}
            state={state}
            itemsList={state.nfzBranchesList}
            itemName="nfzBranch"
            displayNames={["name"]}
          />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="taxOffice" className="">Urząd Skarbowy</label>
        </div>
        <div className="col-md-9">
          <Select
            label="Urząd Skarbowy"
            propertyName="taxOffice"
            required={false}
            state={state}
            itemsList={state.taxOfficesList}
            itemName="taxOffice"
            displayNames={["name"]}
          />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="parentalConsent" className="">Zgoda rodziców</label>
        </div>
        <div className="col-md-1">
          <Toggler propertyName="parentalConsent" state={state} />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="pupil" className="">Uczeń</label>
        </div>
        <div className="col-md-1">
          <Toggler propertyName="pupil" state={state} />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="student" className="">Status studenta</label>
        </div>
        <div className="col-md-1">
          <Toggler propertyName="student" state={state} />
        </div>

        <div className="col-md-2 form-label">
          <label htmlFor="studentId" className="">Numer legitymacji</label>
        </div>
        <div className="col-md-6">
          <TextInput propertyName="studentId" required={true} state={state} disabled={!state.currentItem.student} />
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-3 form-label">
          <label htmlFor="unwanted" className="">Niechciany</label>
        </div>
        <div className="col-md-1">
          <Toggler propertyName="unwanted" state={state} />
        </div>

        <div className="col-md-2 form-label">
          <label htmlFor="unwantedReason" className="">Powód odrzucenia</label>
        </div>
        <div className="col-md-6">
          <TextInput propertyName="unwantedReason" required={true} state={state} disabled={!state.currentItem.unwanted}/>
        </div>
      </div>


    </>
  );
};

export default Employee_Personal_Data_Tab;