import React from 'react';
import Toggler from "../../../elements/Toggler";
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import QNestedDatePicker from "../../../elements/QNestedDatePicker";

const EmployeeMedExam = ({ medExam, index, state, onInputChange, medExamTypesList, onRemove }) => {
  return (
    <div className="row">

      <div className="col-md-2">
        <SelectNested
          propertyName={`employeeMedExams[${index}].medicalExaminationType`}
          state={state}
          itemsList={medExamTypesList}
          displayNames={["name"]}
          showInput={false}
          showDelete={false}
          useId={false}
        />
      </div>

      <div className="col-md-1">
        <QNestedDatePicker
          pickerId={`medExamExaminationDateError-${medExam.id}`}
          errorParagraphId={`medExamExaminationDateError-${medExam.id}`}
          state={state}
          property="employeeMedExams"
          propertyPath={`employeeMedExams[${index}].examinationDate`}
          startDate={medExam.examinationDate ? new Date(medExam.examinationDate) : null}
          isDisabled={false}
        />
        <p id={`medExamExaminationDateError-${medExam.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>

      <div className="col-md-2">
        <input
          type="text"
          id={`comments-${medExam.id}`}
          value={medExam.comments || ""}
          onChange={(e) => onInputChange(e, medExam, "comments")}
          placeholder="Opis"
          className="form-control"
        />
      </div>

      <div className="col-md-1">
        <Toggler propertyName={`employeeMedExams[${index}].positive`} state={state} />
      </div>

      <div className="col-md-1">
        <QNestedDatePicker
          pickerId={`medExamExpirationDateError-${medExam.id}`}
          errorParagraphId={`medExamExpirationDateError-${medExam.id}`}
          state={state}
          property="employeeMedExams"
          propertyPath={`employeeMedExams[${index}].expirationDate`}
          startDate={medExam.expirationDate ? new Date(medExam.expirationDate) : null}
          isDisabled={false}
        />
        <p id={`medExamExpirationDateError-${medExam.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>


      <div className="col-md-1">
        <button className="btn btn-outline-danger" onClick={onRemove}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    </div>
  );
};

export default EmployeeMedExam;
