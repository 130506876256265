import React, {useEffect, useState, useCallback} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faChalkboardUser,
} from "@fortawesome/free-solid-svg-icons";
import PositionTraining from "./PositionTraining";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {trainingDefault} from "../../../../defaults/Items";


const PositionTrainingsCard = ({ state, trainingsList }) => {
  const currentItem = state.currentItem;
  const requiredTrainings = currentItem?.requiredTrainings || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const filteredTrainingsList = trainingsList.filter(
    (training) => !requiredTrainings.some((reqTrn) => reqTrn.id === training.id)
  );

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };




  const addTraining = () => {
    const existingIds = requiredTrainings.map((training) => training.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }
    const newTraining = { ...trainingDefault, id: newId, name: "---Wybierz---" };
    const updatedTrainings = [...requiredTrainings, newTraining];
    setCurrentItem({ ...currentItem, requiredTrainings: updatedTrainings });
    setItemChanged(!itemChanged);
  };


  const removeTraining = useCallback(
    (trainingId) => {
      const updatedTrainings = requiredTrainings.filter(
        (item) => item.id !== trainingId
      );
      setCurrentItem({ ...currentItem, requiredTrainings: updatedTrainings });
      setItemChanged(!itemChanged);
    },
    [requiredTrainings, currentItem, setCurrentItem, setItemChanged, itemChanged]
  );


  return (
    <>
      <div className="card">
        <div
          className="card-header d-flex align-items-center justify-content-between"
          onClick={toggleAccordion}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faChalkboardUser}
              className="me-2 header-icon"
            />
            <span>
              Wymagane szkolenia {!isAccordionOpen ? `(${requiredTrainings.length})` : ""}
            </span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div
          className={`card-body ${isAccordionOpen ? "show" : "offscreen"}`}
          id="requiredTrainings"
        >
          <button
            type="button"
            className="mb-2 btn-auxiliary btn-primary"
            onClick={addTraining}
          >
            <FontAwesomeIcon icon={faCirclePlus} /> Dodaj
          </button>
          {requiredTrainings.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-2 header-label">Typ szkolenia</div>
            </div>
          )}
          {requiredTrainings.length > 0 ? (
            requiredTrainings.map((training, index) => (
              <PositionTraining
                key={index}
                training={training}
                index={index}
                state={state}
                trainingsList={filteredTrainingsList}
                onRemove={() => removeTraining(training.id)}
              />
            ))
          ) : (
            <p>Brak wymaganych szkoleń</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PositionTrainingsCard;








// const PositionTrainingsCard = ({ state, trainingsList}) => {
//
//   const currentItem = state.currentItem;
//   let requiredTrainings = currentItem?.requiredTrainings?.slice() || [];
//   const setCurrentItem = state.setCurrentItem;
//   const setItemChanged = state.setItemChanged;
//   const itemChanged = state.itemChanged;
//   const [isAccordionOpen, setIsAccordionOpen] = useState(true);
//
//
//   useEffect(()=> {
//     //console.log(trainingsList);
//   }, [currentItem])
//
//   const toggleAccordion = () => {
//     setIsAccordionOpen(!isAccordionOpen);
//   };
//
//
//   const addTraining = () => {
//     const existingIds = currentItem.requiredTrainings.map(cert => cert.id);
//     let newId = -1;
//     while (existingIds.includes(newId)) {
//       newId--;
//     }
//     const newTraining = { ...trainingDefault, id: newId, name: '---Wybierz---'};
//     const updatedTrainings = [...requiredTrainings, newTraining];
//     setCurrentItem({ ...currentItem, requiredTrainings: updatedTrainings });
//     setItemChanged(!itemChanged);
//   };
//
//
//
//   const removeTraining = (trainingId) => {
//     const updatedTrainings = requiredTrainings.filter(item => item.id !== trainingId);
//     setCurrentItem({ ...currentItem, requiredTrainings: updatedTrainings });
//     setItemChanged(!itemChanged);
//   };
//
//
//   return (
//     <>
//       <div className="card">
//         <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
//           <div className="d-flex align-items-center">
//             <FontAwesomeIcon icon={faChalkboardUser} className="me-2 header-icon"/>
//             <span>Wymagane szkolenia {!isAccordionOpen ? "(" + currentItem.requiredTrainings.length + ")" : ""}</span>
//           </div>
//           <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
//         </div>
//         <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="requiredTrainings">
//           <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addTraining}><FontAwesomeIcon
//             icon={ faCirclePlus }/> Dodaj</button>
//           {requiredTrainings.length > 0 && (
//             <div className="row font-weight-bold">
//               <div className="col-md-2 header-label">Typ szkolenia</div>
//             </div>
//           )}
//           {requiredTrainings.length > 0 ? (
//             requiredTrainings.map((training, index) => (
//               <PositionTraining
//                 key={index}
//                 training={training}
//                 index={index}
//                 state={state}
//                 trainingsList={trainingsList}
//                 onRemove={() => removeTraining(training.id)}
//               />
//             ))
//           ) : (
//             <p>Brak wymaganych szkoleń</p>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };
//
// export default PositionTrainingsCard;
//
//
//
