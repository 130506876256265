export let contractDefault = {
  id: "",
  docType: "",
  identifier: "",
  dateFrom: "",
  dateTo: "",
  contractType: {id: 0},
  businessPartner: {id: 0},
  employee: {id: 0},
  notes: "",
  legalPerson: {id: 0},
  status: "",
  number: 0,
  year: 0,
  month: 0,
  symbol: ""
}


export let employeePositionDefault = {
  id: "",
  position: {id: 0},
  comments: "",
  evaluation: 0,
  evaluationDate: "",
  active: true,
  EID: 0
}


export let employeeCertificationDefault = {
  id: "",
  EID: 0,
  name: "",
  validFrom: "",
  validTo: "",
  isValid: null,
  noExpirationDate: false,
  evaluationDate: "",
  certificationType: {id: 0},
  comments: "",
  issuedBy: 0,
  issueDate: ""
}


export let employeeMedExamDefault = {
  id: "",
  EID: 0,
  medicalExaminationType: {id: 0},
  examinationDate: "",
  comments: "",
  positive: false,
  expirationDate: ""
}


export let employeeAbsenceDefault = {
  id: "",
  EID: 0,
  absenceType: {id: 0},
  dateFrom: "",
  dateTo: "",
  comments: "",
  positive: false,
  supervisorID: ""
}

export let employeeTrainingDefault = {
  id: "",
  EID: 0,
  dateFrom: "",
  dateTo: "",
  evaluation: 0,
  evaluationDate: "",
  completed: false,
  training: {id: 0},
  trainerID: 0,
  comments: ""
}

export let employeeCommendationDefault = {
  id: "",
  EID: 0,
  createdBy: 0,
  creationDate: "",
  updatedBy: 0,
  lastUpdateDate: "",
  commendation: {id: 0},
  incidentDate: "",
  supervisorID: 0,
  comments: ""
}

export let employeeInfractionDefault = {
  id: "",
  EID: 0,
  createdBy: 0,
  creationDate: "",
  updatedBy: 0,
  lastUpdateDate: "",
  infraction: {id: 0},
  incidentDate: "",
  supervisorID: 0,
  comments: ""
}


export let hiringStageDefault = {
  id: "",
  name: "",
  shortName: "",
  description: "",
  color: ""
};

export let skillDefault = {
  id: "",
  name: "",
  shortName: "",
  description: "",
  color: "",
  weight: 0
};



export let documentNumberingPatternDefault = {
  id: "",
  year: 0,
  symbol: "",
  yearFormat: "rrrr",
  monthFormat: "mm",
  pattern: "",
  contractType: {id: 0},
  locked: false
};


export let employeeCompactedDefault = {
  bankAccountNumber: "",
  dateOfBirth: "",
  evaluation: "",
  fathersName: "",
  firstName: "",
  gender: {id:0},
  iceContact: "",
  id: "",
  lastName: "",
  login: "",
  maidenName: "",
  mothersName: "",
  maritalStatus: {id:0},
  nip: "",
  oldPassword: "",
  password: "",
  pesel: "",
  placeOfBirth: "",
  primaryEmail: "",
  primaryPhone: "",
  secondName: "",
  secondaryEmail: "",
  secondaryPhone: "",
  type: "",
  userRoles: [],
  photos: "",
  createDate:"",
  lastUpdateDate:"",
  createdBy:"",
  updatedBy:"",
  nfzBranch: {id:0},
  taxOffice: {id:0},
  student: false,
  pupil: false,
  studentId: "",
  unwanted: false,
  unwantedReason:"",
  parentalConsent: false,
  enabled: true,
  hiringStage: {id: 0},
  primaryAddressStreet: "",
  primaryAddressStreetNumber: "",
  primaryAddressPostalCode: "",
  primaryAddressCity: "",
  primaryAddressCountry: {id: 0},
  secondaryAddressStreet: "",
  secondaryAddressStreetNumber: "",
  secondaryAddressPostalCode: "",
  secondaryAddressCity: "",
  secondaryAddressCountry: {id: 0},
  identityCardType: {id: 0},
  identityCardId: "",
  identityCardIssueDate: "",
  identityCardExpiryDate: "",
  issuedBy: "",
  issueLocation: "",
  employeePositions: [],
  employeeSkills: [],
  employeeTrainings: [],
  employeeCommendations: [],
  employeeInfractions: [],
  employeeCertifications: [],
  employeeMedExams: [],
  employeeAbsences: [],
  missingTrainings: [],
  department: {id: 0},
  position: {id: 0},
  communicationByEmail: true,
  communicationBySMS: false,
  hiringCompany: {id: 0}
}

export let employeeDefault = {
  bankAccountNumber: "",
  dateOfBirth: "",
  evaluation: "",
  fathersName: "",
  firstName: "",
  gender: {id:0},
  iceContact: "",
  id: "",
  lastName: "",
  login: "",
  maidenName: "",
  mothersName: "",
  maritalStatus: {id:0},
  nip: "",
  oldPassword: "",
  password: "",
  pesel: "",
  placeOfBirth: "",
  primaryEmail: "",
  primaryPhone: "",
  secondName: "",
  secondaryEmail: "",
  secondaryPhone: "",
  type: "",
  userRoles: [],
  photos: "",
  createDate:"",
  lastUpdateDate:"",
  createdBy:"",
  updatedBy:"",
  nfzBranch: {id:0},
  taxOffice: {id:0},
  student: false,
  pupil: false,
  studentId: "",
  unwanted: false,
  unwantedReason:"",
  parentalConsent: false,
  enabled: true,
  hiringStage: {id: 0},
  primaryAddressStreet: "",
  primaryAddressStreetNumber: "",
  primaryAddressPostalCode: "",
  primaryAddressCity: "",
  primaryAddressCountry: {id: 0},
  secondaryAddressStreet: "",
  secondaryAddressStreetNumber: "",
  secondaryAddressPostalCode: "",
  secondaryAddressCity: "",
  secondaryAddressCountry: {id: 0},
  identityCardType: {id: 0},
  identityCardId: "",
  identityCardIssueDate: "",
  identityCardExpiryDate: "",
  issuedBy: "",
  issueLocation: "",
  employeePositions: [],
  employeeSkills: [],
  employeeTrainings: [],
  employeeCommendations: [],
  employeeInfractions: [],
  employeeCertifications: [],
  employeeMedExams: [],
  employeeAbsences: [],
  missingTrainings: [],
  department: {id: 0},
  position: {id: 0},
  communicationByEmail: true,
  communicationBySMS: false,
  hiringCompany: {id: 0}
}

export let commendationDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  weight: "",
  description: "",
  companySectionTypeList: []
}

export let infractionDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  weight: "",
  description: "",
  companySectionTypeList: []
};

export let workingTimeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  workingHours: "",
  description: ""
};

export let attractionDefault = {
  id: "",
  name: "",
  shortName: "",
  description: ""
};


export let zoneDefault = {
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let nonWorkingDaysDefault = {
  selected: false,
  id: "",
  name: "",
  date: "",
  shortName: "",
  description: ""
};


export let businessPartnerDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  legalEntity:
    {id: 0},
  notes: "",
  nip: "",
  primaryPhone: "",
  primaryEmail: "",
  billingAddressStreet: "",
  billingAddressStreetNumber: "",
  billingAddressPostalCode: "",
  billingAddressCity: "",
  billingAddressCountry:
    {id: 0},
  secondaryAddressStreet: "",
  secondaryAddressStreetNumber: "",
  secondaryAddressPostalCode: "",
  secondaryAddressCity: "",
  secondaryAddressCountry:
    {id: 0},
  contactsIds : [],
  hiringCompany: false,
  active: true

};

export let countryDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: ""
};


export let voivodeshipDefault = {
  selected: false,
  id: "",
  name: ""
};



export let nfzBranchDefault = {
  selected: false,
  id: "",
  name: "",
  identifier: "",
  description: ""
};

export let legalEntityTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let contractTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: "",
  color: "",
  legalPersonJuridical: false,
  legalPersonNatural: false,
};


export let departmentDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: "",
  manager: {id: 0},
  deputyManager: {id: 0},
  positions: [],
  email: "",
  phoneNumber: "",
  color: ""
};


export let medicalExaminationTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let genderDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let trainingDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: "",
  required: false
  // positionTraining: false,
  // position: {id: 0}
};


export let documentTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let identityCardTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let certificationTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let positionDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  basicWage: 0.0,
  description: "",
  department: {id: 0},
  active: true,
  requiredTrainings: [],
  requiredCertifications: []
};

export let positionTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: "",
};


export const equipmentOwnershipTypeDefault = {
  id: "",
  name: "",
  description: ""
}

export const equipmentStatusDefault = {
  id: "",
  name: ""
}

export const absenceTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
}

export const businessTripTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
}

export const equipmentBookingStatusDefault = {
  id: "",
  name: "",
  description: "",
  color: ""
}

export const equipmentCategoryDefault = {
  id: "",
  name: "",
  description: ""
}

export let taxOfficeDefault = {
  selected: false,
  id: "",
  name: "",
  address: "",
  postalCode: "",
  city: "",
  code: "",
  voivodeship: {
    id: 0
  }
};

export const equipmentDefault = {
  id: 0,
  sortingId: 0,
  name: "",
  notes: "",
  width: 0,
  length: 0,
  height: 0,
  weight: 0,
  powerRequired: 0,
  staffNeeded: 0,
  minimumAge: 0,
  maxParticipants: 0,
  equipmentCategory: {
    id: 0
  },
  inUse: false,
  photos:[],
  equipmentStatus: {
    id: 0
  },
  bookingStatus: {
    id: 0
  },
  equipmentOwnership: {
    id: 0
  }
}

export const userCompactedDefault = {
  id: 0,
  login: "",
  password: "",
  firstName: "",
  lastName: "",
  userRoles: [{}]
}

export const userDefault = {
  id: 0,
  login: "",
  password: "",
  firstName: "",
  lastName: "",
  userRoles: [{}]
}

export const userRoleDefault = {
  id: "",
  name: "",
  description: ""
}


export const settingsDefault = {
  id: "",
  infractionWeightMinimumValue: 0,
  infractionWeightMaximumValue: 5,
  commendationWeightMinimumValue: 0,
  commendationWeightMaximumValue: 5,
  skillWeightMinimumValue: 0,
  skillWeightMaximumValue: 5,
  skillEvaluationMinimumValue: 0,
  skillEvaluationMaximumValue: 5,
  trainingEvaluationMinimumValue: 0,
  trainingEvaluationMaximumValue: 5,
  positionEvaluationDefaultValue: 3,
  positionEvaluationMinimumValue: 0,
  positionEvaluationMaximumValue: 5,
  sameManagerInManyDepartments: false
}

export const companyDefault = {
  id: 0,
  fullName: "",
  shortName: "",
  regon: "",
  nip: "",
  krs: "",
  insuranceNumber: "",
  legalEntity: {id: 0},
  taxOffice: {id: 0},
  notes: "",
  representative: {id: 0},
  primaryStreet: "",
  primaryPostalCode: "",
  primaryCity: "",
  primaryCountry: {id: 0},
  secondaryStreet: "",
  secondaryPostalCode: "",
  secondaryCity: "",
  secondaryCountry: {id: 0},
  email: "",
  website: "",
  bankAccount: ""
}

export const resourcePlanItemDefault = {
  id: 0,
  position: {id: 0},
  requiredPeopleCount: 0,
  priority: 0,
  workStart: "",
  workEnd: "",
  active: "",
  createdAt: "",
  createdBy: "",
  updatedAt: "",
  updatedBy: ""

}

export const resourcePlanDefault = {
  id: 0,
  name: "",
  shortName: "",
  description: "",
  workStart: "",
  workEnd: "",
  resourcePlanItems: [],
  createdAt: "",
  createdBy: "",
  updatedAt: "",
  updatedBy: "",
  active: true,
  totalStaff: 0,
  totalHours: 0
}