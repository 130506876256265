import DatePicker from "react-datepicker";
import { format } from "date-fns";

const QNestedDatePicker = ({ pickerId, errorParagraphId, state, propertyPath, startDate, property, isDisabled, includeTime }) => {
  const handleDateChange = (date) => {
    if (!date) return;

    const formattedDate = includeTime
      ? format(date, "yyyy-MM-dd HH:mm:ss")
      : format(date, "yyyy-MM-dd");

    const updatedItem = JSON.parse(JSON.stringify(state.currentItem));
    const keys = propertyPath.split(".");
    const arrayIndexMatch = keys[0].match(new RegExp(`^${property}\\[(\\d+)\\]$`));

    if (arrayIndexMatch) {
      const arrayIndex = parseInt(arrayIndexMatch[1], 10);
      const fieldName = keys[1];
      updatedItem[property][arrayIndex][fieldName] = formattedDate;
    }

    state.setCurrentItem(updatedItem);
    state.setItemChanged(!state.itemChanged);
    state.setCurrentFormState({ ...state.currentFormState, formSaveButtonDisabled: false });

    const errorElement = document.getElementById(errorParagraphId);
    if (errorElement) {
      errorElement.classList.add("offscreen");
    }
  };

  const handleRawChange = (e) => {
    const value = e.target.value;
    const dateRegex = includeTime
      ? /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/
      : /^\d{4}-\d{2}-\d{2}$/;

    const errorElement = document.getElementById(errorParagraphId);
    if (!errorElement) return;

    if (!dateRegex.test(value)) {
      errorElement.classList.remove("offscreen");
    } else {
      errorElement.classList.add("offscreen");
    }
  };

  return (
    <DatePicker
      id={pickerId}
      selected={startDate}
      onChange={handleDateChange}
      onChangeRaw={handleRawChange}
      dateFormat={includeTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
      showTimeSelect={includeTime}
      timeFormat="HH:mm"
      timeIntervals={10}
      className={isDisabled ? "datepicker-disabled" : ""}
      disabled={isDisabled}
    />
  );
};

export default QNestedDatePicker;
