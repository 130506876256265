import React from 'react';
import Toggler from "../../../elements/Toggler";
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import QNestedDatePicker from "../../../elements/QNestedDatePicker";

const EmployeeAbsence = ({ absence, index, state, onInputChange, absenceTypesList, supervisorsList, onRemove }) => {
  return (
    <div className="row">
      <div className="col-md-2">
        <QNestedDatePicker
          pickerId={`absenceDateFromError-${absence.id}`}
          errorParagraphId={`absenceDateFromError-${absence.id}`}
          state={state}
          property="employeeAbsences"
          propertyPath={`employeeAbsences[${index}].dateFrom`}
          startDate={absence.dateFrom ? new Date(absence.dateFrom) : null}
          isDisabled={false}
          includeTime={true}
        />
        <p id={`absenceDateFromError-${absence.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>

      <div className="col-md-2">
        <QNestedDatePicker
          pickerId={`absenceDateToError-${absence.id}`}
          errorParagraphId={`absenceDateToError-${absence.id}`}
          state={state}
          property="employeeAbsences"
          propertyPath={`employeeAbsences[${index}].dateTo`}
          startDate={absence.dateTo ? new Date(absence.dateTo) : null}
          isDisabled={false}
          includeTime={true}
        />
        <p id={`absenceDateToError-${absence.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>

      <div className="col-md-2">
        <SelectNested
          propertyName={`employeeAbsences[${index}].absenceType`}
          state={state}
          itemsList={absenceTypesList}
          displayNames={["name"]}
          showInput={false}
          showDelete={false}
          useId={false}
        />
      </div>

      <div className="col-md-2">
        <SelectNested
          propertyName={`employeeAbsences[${index}].supervisorID`}
          state={state}
          itemsList={supervisorsList}
          displayNames={["firstName", "lastName"]}
          showInput={false}
          showDelete={false}
          useId={true}
        />
      </div>

      <div className="col-md-2">
        <input
          type="text"
          id={`comments-${absence.id}`}
          value={absence.comments || ""}
          onChange={(e) => onInputChange(e, absence, "comments")}
          placeholder="Opis"
          className="form-control"
        />
      </div>
      <div className="col-md-1">
        <button className="btn btn-outline-danger" onClick={onRemove}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    </div>
  );
};

export default EmployeeAbsence;
