import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import { Button } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import ModalControls from "../common/ModalControls";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultFormState } from "../../../defaults/Forms";
import { contractDefault } from "../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails, setForegroundColor, validateNumberField, validateTextField, validateSelectField
} from "../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../common/AppComponentCardHeader";
import LoadingDataDiv from "../common/LoadingDataDiv";
import DeleteWarningModal from "../common/DeleteWarningModal";
import TextInput from "../../elements/TextInput";
import TextArea from "../../elements/TextArea";
import useCrud from "../../../hooks/useCrud";
import { Table } from "../../table/Table";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "../../elements/Select";
import '../../../css/react-datepicker.css';

const Contract = () => {
  const isDateValid = (dateString) => !isNaN(Date.parse(dateString));

  const dataUrl = "/contracts";
  const employeesUrl = "/employees";
  const businessPartnersUrl = "/business-partners";
  const contractTypesUrl = "/contract-types";
  const legalPersonsUrl = "/legal-persons";
  const contractNumberingPatternUrl = "/document-numbering-patterns";
  const latestContractNumber = "/contracts/get-last-contract-number";
  const relatedItemsUrl = null;
  const defaultItem = contractDefault;
  const itemName = "umowa";
  const itemNames = "umowy";
  const navigate = useNavigate();
  const location = useLocation();
  const { createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId } = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [updateConflict, setUpdateConflict] = useState(false);
  const [conflictingRoles, setConflictingRoles] = useState(false);
  const [contractTypesList, setContractTypesList] = useState([]);
  const [filteredContractTypesList, setFilteredContractTypesList] = useState([]);
  const [dateFrom, setDateFrom] = useState(currentItem.dateFrom ? currentItem.dateFrom : format(new Date(), 'yyyy-MM-dd'));
  const [dateTo, setDateTo] = useState(isDateValid(currentItem.dateTo) ? currentItem.dateTo : null);
  const [numberingPatternAvailable, setNumberingPatternAvailable] = useState(true);

  const [employeesList, setEmployeesList] = useState([]);
  const [businessPartnersList, setBusinessPartnersList] = useState([]);
  const [legalPersonsList, setLegalPersonsList] = useState([]);
  const [itemLegalPerson, setItemLegalPerson] = useState(null);

  const LEGAL_PERSON_JURIDICAL = 1;
  const LEGAL_PERSON_NATURAL = 2;

  const columns = [
    { label: "Id", accessor: "id", sortable: true, searchable: false },
    { label: "Numer umowy", accessor: "identifier", sortable: true, searchable: false },
    { label: "Opis", accessor: "notes", sortable: true, searchable: true },
    { label: "Data zawarcia", accessor: "dateFrom", sortable: true, searchable: true },
    { label: "Data zakończenia", accessor: "dateTo", sortable: true, searchable: true },
    {
      label: "Typ umowy",
      accessor: "contractType",
      sortable: true, searchable: true,
      backgroundColor: "contractType.color",
      format: (contractType) => contractType ? `${contractType.shortName}` : "",
      fieldsToInclude: ['shortName']
    },
    {
      label: "Partner",
      accessor: "businessPartner",
      sortable: true, searchable: true,
      format: (businessPartner) => businessPartner ? `${businessPartner.shortName}` : "",
      fieldsToInclude: ['shortName']
    },
    { label: "Edycja", accessor: "editBtn", sortable: false, searchable: false },
    { label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false },
  ];

  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }

  const onDelete = async () => {
    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);

    if (response === 401 || response === 403) {
      navigate('/login', { state: { from: location }, replace: true });
    }
  }

  const onSaveItemClick = async (e) => {
    e.preventDefault();

    const datesAreValid = (currentItem.dateFrom && dateRegex.test(currentItem.dateFrom)) &&
      (currentItem.dateTo && dateRegex.test(currentItem.dateTo)) &&
      (currentItem.dateFrom <= currentItem.dateTo);

    const legalPersonIsValid = validateSelectField(currentItem.legalPerson, "legalPerson");
    const contractTypeIsValid = validateSelectField(currentItem.contractType, "contractType");
    const businessPartnerIsValid = validateSelectField(currentItem.businessPartner, "businessPartner");
    const employeeIsValid = validateSelectField(currentItem.employee, "employee");
    const contractorIsValid = (businessPartnerIsValid || employeeIsValid);
    const formIsValid = datesAreValid && legalPersonIsValid && contractTypeIsValid && contractorIsValid;

    //console.log(currentItem);

    if (!formIsValid) {
      return;
    }



    let response;
    const item = {
      id: currentItem.id,
      docType: currentItem.docType,
      identifier: currentItem.identifier,
      dateFrom: currentItem.dateFrom,
      dateTo: currentItem.dateTo,
      notes: currentItem.notes,
      contractType: currentItem.contractType && currentItem.contractType.id > 0 ? { id: currentItem.contractType.id } : null,
      businessPartner: currentItem.businessPartner && currentItem.businessPartner.id > 0 ? { id: currentItem.businessPartner.id } : null,
      legalPerson: currentItem.legalPerson && currentItem.legalPerson.id > 0 ? { id: currentItem.legalPerson.id } : null,
      status: currentItem.status,
      number: currentItem.number,
      year: yearFromDate(currentItem.dateFrom),
      month: monthFromDate(currentItem.dateFrom),
      symbol: currentItem.symbol
    };


    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataUrl, item, state);
    } else {
      response = await updateItem(`${dataUrl}/${item.id}`, item, state);
    }

    if (response.status === 401 || response.status === 403) {
      navigate('/login', { state: { from: location }, replace: true });
    } else if (response.status === 409) {
      setUpdateConflict(true);
    } else {
      response && onSaveAndClose({ state });
    }
  }

  const dateRegex = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

  const checkRelatedItems = async (id) => {
    setAllowDelete(true);
  }

  const onClose = () => {
    setUpdateConflict(false);
    onCloseDetails({ state });
  };

  useEffect(() => {
    if (updateConflict) setUpdateConflict(false);
    if (conflictingRoles) setConflictingRoles(false);
    if (!currentItem.legalPerson || currentItem.legalPerson.id === 0) {
      setCurrentItem(prev => ({ ...prev, contractType: null, businessPartner: null, employee: null }));
      setNumberingPatternAvailable(true);
      setCurrentFormState(prev => ({ ...prev, formSaveButtonDisabled: false }));
    }
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
  }, [itemChanged]);

  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.identifier);
    }
  }, [allowDelete]);

  useEffect(() => {
    const getData = async () => {
      const [itemsData, businessPartnersData, employeesData, contractTypesData, legalPersonsData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(businessPartnersUrl),
          getItems(employeesUrl),
          getItems(contractTypesUrl),
          getItems(legalPersonsUrl)
        ]);

      const success =
        itemsData.status === 200 &&
        businessPartnersData.status === 200 &&
        employeesData.status === 200 &&
        contractTypesData.status === 200 &&
        legalPersonsData.status === 200

      if (success) {
        setLoading(false);
        setItems(itemsData.data);
        setBusinessPartnersList(businessPartnersData.data);
        setEmployeesList(employeesData.data);
        setContractTypesList(contractTypesData.data);
        setLegalPersonsList(legalPersonsData.data);
      } else {
        alert("Nie udało się pobrać danych");
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const year = yearFromDate(dateFrom);

    const fetchContractNumberingPattern = async () => {
      if (currentItem.contractType && currentItem.contractType.id && year !== null) {
        await getContractNumberingPattern(currentItem.contractType.id, year);
      }
    };

    if (currentFormState.showForm) {
      fetchContractNumberingPattern();
    }
  }, [currentItem.contractType, dateFrom, currentFormState.showForm]);

  useEffect(() => {
    if (currentItem.identifier.includes("#")) {
      setCurrentItem(prev => ({ ...prev, contractType: null, businessPartner: null, employee: null }));
    }
  }, [currentItem.legalPerson]);

  useEffect(() => {
    const year = yearFromDate(dateFrom);

    const fetchContractNumberingPattern = async () => {
      if (currentItem.contractType && currentItem.contractType.id && year !== null) {
        await getContractNumberingPattern(currentItem.contractType.id, year);
      }
    };

    fetchContractNumberingPattern();
  }, [dateFrom, currentFormState.showForm]);

  useEffect(() => {
    if (currentItem.legalPerson && currentItem.legalPerson.id) {
      const filteredList = contractTypesList.filter(ct => {
        if (currentItem.legalPerson.id === LEGAL_PERSON_JURIDICAL) {
          return ct.legalPersonJuridical;
        } else if (currentItem.legalPerson.id === LEGAL_PERSON_NATURAL) {
          return ct.legalPersonNatural;
        }
        return false;
      });
      setFilteredContractTypesList(filteredList);
    } else {
      setFilteredContractTypesList(contractTypesList);
    }
  }, [currentItem.legalPerson, contractTypesList]);

  const getContractNumberingPattern = async (contractTypeId, year) => {
    if (year === null) return;

    const response = await getItems(`${contractNumberingPatternUrl}/${contractTypeId}/${year}`);
    if (response.status === 200) {
      const pattern = response.data;
      const patternId = pattern.id;
      const symbol = pattern.symbol;
      pattern.locked = true;

      setNumberingPatternAvailable(true);
      setCurrentFormState(prev => ({ ...prev, formSaveButtonDisabled: false }));

      // Only generate identifier if there is no existing identifier or in addingDataMode
      if (!currentItem.identifier || currentFormState.formAddingDataMode) {
        const identifier = generateIdentifier(dateFrom, response);

        let updatePatternResponse = await updateItem(`${contractNumberingPatternUrl}/${patternId}`, pattern, state);

        setCurrentItem(prev => ({
          ...prev,
          identifier: identifier,
          symbol: symbol
        }));
      }

    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', { state: { from: location }, replace: true })
    } else if (response.includes("404")) {
      setNumberingPatternAvailable(false);
      setCurrentItem(prev => ({
        ...prev,
        identifier: ""
      }));

      setCurrentFormState(prev => ({ ...prev, formSaveButtonDisabled: true }));
    } else {
      alert("Nie udało się pobrać danych wzorca numeracji");
    }
  }

  const getCompleteItem = async (id) => {
    const response = await getItems(`${dataUrl}/${id}`);
    if (response.status === 200) {
      const item = response.data;
      setCurrentItem(item);
      setDateFrom(item.dateFrom ? item.dateFrom : format(new Date(), 'yyyy-MM-dd'));
      setDateTo(isDateValid(item.dateTo) ? item.dateTo : null);
      setCurrentFormState(prev => ({ ...prev, showForm: true, formAddingDataMode: false }));
      return item;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', { state: { from: location }, replace: true })
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }

  const resetForm = () => {
    setCurrentItem(defaultItem);
    setDateFrom(format(new Date(), 'yyyy-MM-dd'));
    setDateTo(null);
    setCurrentFormState(prev => ({ ...prev, formAddingDataMode: true, showForm: true }));
  }

  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem: {
      ...defaultItem,
      dateFrom: format(new Date(), 'yyyy-MM-dd'),
      dateTo: null
    },
    currentFormState,
    setCurrentFormState,
    formDescription: null,
    formHeader: `Nowy element`,
    buttonTitle: `Dodaj nową umowę`
  }

  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv />
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows={itemsList}
        columns={columns}
        state={state}
        checkRelatedItems={checkRelatedItems}
        formHeader={`Edycja ${itemName}`}
        relatedItemsUrl={relatedItemsUrl}
        getCompleteItem={getCompleteItem}
      />
  } else {
    dataSectionContent =
      <div className={"warn_info"}><FontAwesomeIcon
        className={"warning-icon div-visible"} icon={faExclamationCircle} />&nbsp;&nbsp;&nbsp;NIE ZNALEZIONO ŻADNYCH
        DANYCH
      </div>
  }

  const yearFromDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return date.getFullYear();
    }
    return null;
  };

  const monthFromDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return date.getMonth();
    }
    return null;
  };

  const formatYear = (date, yearFormat) => {
    const year = date.getFullYear();
    if (yearFormat === 'rrrr') return year.toString();
    if (yearFormat === 'rr') return year.toString().slice(-2);
    return year.toString();
  }

  const formatMonth = (date, monthFormat) => {
    const month = date.getMonth() + 1;
    const monthNames = ["STY", "LUT", "MAR", "KWI", "MAJ", "CZE", "LIP", "SIE", "WRZ", "PAŹ", "LIS", "GRU"];
    if (monthFormat === 'mm') return month.toString().padStart(2, '0');
    if (monthFormat === 'm') return month.toString();
    if (monthFormat === 'mmm') return monthNames[month - 1];
    return month.toString();
  }

  const generateIdentifier = (dateFrom, response) => {
    const yearFormat = response.data.yearFormat;
    const monthFormat = response.data.monthFormat;
    const symbol = response.data.symbol;
    const year = formatYear(new Date(dateFrom), yearFormat);
    const month = formatMonth(new Date(dateFrom), monthFormat);
    const pattern = response.data.pattern;

    let identifier = pattern
      .replace(/R/g, year)
      .replace(/M/g, month)
      .replace(/S/g, symbol)
      .replace(/N/g, '#');

    return identifier;
  }

  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader
          title={`Edycja ${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()} -> ${currentItem.identifier}`}/>
      ) : (
        <AppComponentCardHeader
          title={`Zawarte umowy`}
          buttonProps={addDataButtonProps}
          onAddButtonClick={resetForm}
        />
      )}

      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{currentFormState.formDescription}</p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={() => {
                    setUpdateConflict(false)
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>
                <div className="col-md-12 mb-md-0 mb-5">
                  <form id="add-contract-form" name="add-contract-form">

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="legalPerson" className="">Forma prawna *</label>
                      </div>
                      <div className="col-md-9">
                        <Select
                          label="legalPerson"
                          propertyName="legalPersonId"
                          required={false}
                          state={state}
                          itemsList={legalPersonsList}
                          itemName="legalPerson"
                          displayNames={["name"]}
                        />
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="contractType" className="">Typ umowy *</label>
                      </div>
                      <div className="col-md-9">
                        {(!currentItem.legalPerson || currentItem.legalPerson.id === 0) ? (
                          <span className={"err_info"}>Najpierw wybierz formę prawną</span>
                        ) : (
                          <Select
                            label="contractType"
                            propertyName="contractTypeId"
                            required={true}
                            state={state}
                            itemsList={filteredContractTypesList}
                            itemName="contractType"
                            displayNames={["name"]}
                          />
                        )}
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="businessPartner" className="">Wykonawca *</label>
                      </div>
                      <div className="col-md-9 align-items-center">
                        {(!currentItem.legalPerson || currentItem.legalPerson.id === 0) ? (
                          <span className={"err_info"}>Najpierw wybierz formę prawną</span>
                        ) : (
                          currentItem.legalPerson.id === LEGAL_PERSON_JURIDICAL ? (
                            <Select
                              label="businessPartner"
                              propertyName="businessPartnerId"
                              required={true}
                              state={state}
                              itemsList={businessPartnersList}
                              itemName="businessPartner"
                              displayNames={["shortName"]}
                              disabled={!numberingPatternAvailable}
                            />) : (
                            <Select
                              label="employee"
                              propertyName="employeeId"
                              required={false}
                              state={state}
                              itemsList={employeesList}
                              itemName="employee"
                              displayNames={["firstName", "lastName"]}
                              disabled={!numberingPatternAvailable}
                            />
                          )
                        )}
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="identifier" className="">Identyfikator</label>
                      </div>
                      <div className="col-md-9">
                        {(!currentFormState.formAddingDataMode && currentItem.identifier && currentItem.identifier !== "unidentified" && currentItem.identifier.trim() !== "") ? (
                          <TextInput propertyName="identifier" value={currentItem.identifier} required={true} state={state} disabled={true} />
                        ) : (
                          <TextInput propertyName="identifier" required={true} state={state} disabled={true}/>
                        )}
                        <p id="conflict_notification"
                           className={!numberingPatternAvailable ? "err_info mt-1" : "offscreen"}>
                          <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                          Nie istnieje wzorzec numeracji dokumentów dla wybranego typu umowy i roku. <a
                          className={"app-link"} href="#" onClick={() => navigate('/app/document-numbering-pattern')}>Dodaj
                          wzorzec</a>
                        </p>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="dateFrom" className="">Data zawarcia *</label>
                      </div>
                      <div className="col-md-3">
                        <DatePicker
                          selected={currentItem.dateFrom ? new Date(currentItem.dateFrom) : new Date(dateFrom)}
                          onChange={(date) => {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setDateFrom(formattedDate);
                            setItemChanged(!itemChanged);
                            setCurrentItem({
                              ...currentItem,
                              dateFrom: formattedDate
                            });
                            setCurrentFormState(prev => ({...prev, formSaveButtonDisabled: false}));
                          }}
                          dateFormat='yyyy-MM-dd'
                        />
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="dateTo" className="">Data zakończenia *</label>
                      </div>
                      <div className="col-md-3">
                        <DatePicker
                          selected={currentItem.dateTo ? new Date(currentItem.dateTo) : null}
                          onChange={(date) => {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setDateTo(formattedDate);
                            setItemChanged(!itemChanged);
                            setCurrentItem({
                              ...currentItem,
                              dateTo: formattedDate
                            });
                            setCurrentFormState(prev => ({...prev, formSaveButtonDisabled: false}));
                          }}
                          dateFormat='yyyy-MM-dd'
                        />
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="notes" className="">Uwagi</label>
                      </div>
                      <div className="col-md-9">
                        <TextArea propertyName="notes" required={false} rows="2" state={state}/>
                      </div>
                    </div>
                    <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                      Nie można dodać ani zmienić tego elementu, ponieważ istnieje już umowa o tym identyfikatorze
                    </p>
                  </form>
                </div>

              </section>
            </div>
            <ModalControls
              onDelete={onDelete}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div
            className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{dataSectionContent}</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );
}

export default Contract;
