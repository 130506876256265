import React, {useEffect, useState} from 'react';
import Toggler from "../../../elements/Toggler";
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import QDatePicker from "../../../elements/QDatePicker";
import QNestedDatePicker from "../../../elements/QNestedDatePicker";

const EmployeeCertification = ({ certification, index, state, onInputChange, certificationTypesList, onRemove }) => {
  const today = new Date();


  useEffect(() => {
    const updatedCertifications = [...state.currentItem.employeeCertifications];
    const currentCertification = updatedCertifications[index];
    const validToDate = currentCertification.validTo ? new Date(currentCertification.validTo).setHours(0, 0, 0, 0) : null;

    if (currentCertification.noExpirationDate) {
      currentCertification.validFrom = null;
      currentCertification.validTo = null;
      currentCertification.isValid = 'Y';
    } else {
      if (validToDate) {
        const validToDateObj = new Date(validToDate);
        const todayDateObj = new Date();
        validToDateObj.setHours(0, 0, 0, 0);
        todayDateObj.setHours(0, 0, 0, 0);

        if (validToDateObj.getTime() < todayDateObj.getTime()) {
          currentCertification.isValid = 'N';
        } else if (validToDateObj.getTime() > todayDateObj.getTime()) {
          currentCertification.isValid = 'Y';
        } else {
          currentCertification.isValid = 'E';
        }
      } else {
        currentCertification.isValid = null;
      }
    }


    state.setCurrentItem({
      ...state.currentItem,
      employeeCertifications: updatedCertifications,
    });
  }, [state.currentItem.employeeCertifications[index].validTo, state.currentItem.employeeCertifications[index].noExpirationDate]);



  return (
    <div className="row">
      <div className="col-md-1">

        <QNestedDatePicker
          pickerId={`certificationIssueDate-${certification.id}`}
          errorParagraphId={`certificationIssueDateError-${certification.id}`}
          state={state}
          property="employeeCertifications"
          propertyPath={`employeeCertifications[${index}].issueDate`}
          startDate={certification.issueDate ? new Date(certification.issueDate) : null}
        />
        <p id={`certificationIssueDateError-${certification.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>

      </div>

      <div className="col-md-2">
        <input
          type="text"
          id={`issuedBy-${certification.id}`}
          value={certification.issuedBy || ""}
          onChange={(e) => onInputChange(e, certification, "issuedBy")}
          placeholder="Wydany przez"
          className="form-control"
        />
      </div>

      <div className="col-md-1">
        <QNestedDatePicker
          pickerId={`certificationValidFrom-${certification.id}`}
          errorParagraphId={`certificationValidFromError-${certification.id}`}
          state={state}
          property="employeeCertifications"
          propertyPath={`employeeCertifications[${index}].validFrom`}
          startDate={certification.validFrom ? new Date(certification.validFrom) : null}
          isDisabled={certification.noExpirationDate}
        />
        <p id={`certificationValidFromError-${certification.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>

      <div className="col-md-1">
        <QNestedDatePicker
          pickerId={`certificationValidTo-${certification.id}`}
          errorParagraphId={`certificationValidToError-${certification.id}`}
          state={state}
          property="employeeCertifications"
          propertyPath={`employeeCertifications[${index}].validTo`}
          startDate={certification.validTo ? new Date(certification.validTo) : null}
          isDisabled={certification.noExpirationDate}
        />
        <p id={`certificationValidToError-${certification.id}`} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
        </p>
      </div>

      <div className="col-md-2">
        <SelectNested
          propertyName={`employeeCertifications[${index}].certificationType`}
          state={state}
          itemsList={certificationTypesList}
          displayNames={["name"]}
          showInput={false}
          showDelete={false}
          useId={false}
        />
      </div>

      <div className="col-md-1">
        <Toggler propertyName={`employeeCertifications[${index}].noExpirationDate`} state={state} />
      </div>

      <div className="col-md-1">
        <div className="col-md-1">
          <input
            type="text"
            id={`isValid-${certification.id}`}
            value={certification.isValid || ""}
            className={`notification ${
              certification.isValid === 'Y'
                ? "notification-valid"
                : certification.isValid === 'N'
                  ? "notification-invalid"
                  : certification.isValid === 'E'
                    ? "notification-warning"
                    : "notification-empty"
            } non-clickable`}
            readOnly
            tabIndex="-1"
          />
        </div>
      </div>

      <div className="col-md-2">
        <input
          type="text"
          id={`comments-${certification.id}`}
          value={certification.comments || ""}
          onChange={(e) => onInputChange(e, certification, "comments")}
          placeholder="Opis"
          className="form-control"
        />
      </div>

      <div className="col-md-1">
        <button className="btn btn-outline-danger" onClick={onRemove}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>

    </div>
  );
};

export default EmployeeCertification;
