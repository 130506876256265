import React, {useState} from 'react';
import Select from "../../../elements/Select";
import TextInput from "../../../elements/TextInput";
import DatePicker from "react-datepicker";
import QDatePicker from "../../../elements/QDatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

const EmployeeIdentityCard = ({ state, identityCardProps, identityCardTypeList, identityCardTypeProps, cardHeader }) => {

  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const setCurrentFormState = state.setCurrentFormState;
  const currentFormState = state.currentFormState;
  const [issueDate, setIssueDate] = useState(state.currentItem.identityCardIssueDate);
  const [expiryDate, setExpiryDate] = useState(state.currentItem.identityCardExpiryDate);

  return (
    <>
      <div className="card-header">
        { cardHeader }
      </div>
      <div className="card-body">
        <label htmlFor={ identityCardTypeProps.itemName } className="">{ identityCardTypeProps.label }</label>
        <Select
          label = "Dokument tożsamości" // aria-label
          propertyName = { identityCardTypeProps.propertyName }
          required = { false }
          state = { state }
          itemsList = { identityCardTypeList }
          itemName = { identityCardTypeProps.itemName } // DOM element id
          displayNames = {["name"]}
        />
        <div className="row">
        <div className="col-md-4">
          <div className="md-form mb-0">
            <label htmlFor = { identityCardProps.cardId } className="">Seria i numer</label>
            <TextInput propertyName = { identityCardProps.cardId } state={ state }/>
          </div>
        </div>
        <div className="col-md-4">
          <div className="md-form mb-0">
            <label htmlFor={ identityCardProps.issuedBy } className="">Organ wydający</label>
            <TextInput propertyName={ identityCardProps.issuedBy } state={ state }/>
          </div>
        </div>
        <div className="col-md-4">
          <div className="md-form mb-0">
            <label htmlFor={ identityCardProps.issueLocation } className="">Miejsce wydania</label>
            <TextInput propertyName={ identityCardProps.issueLocation } state={ state }/>
          </div>
        </div>
        <div className="col-md-4">
          <div className="md-form mb-0">
            <label htmlFor={ identityCardProps.cardIssueDate } className="">Data wydania</label>
            <div className="col">
              <QDatePicker
                pickerId="identityCardIssueDate"
                errorParagraphId="wrongCardIssueDateFormat"
                state={state}
                propertyName="identityCardIssueDate"
                startDate={issueDate}
                setStartDate={setIssueDate}
              />


              {/*<DatePicker id="identityCardIssueDate"*/}
              {/*            selected={issueDate}*/}
              {/*            onChange={(date) => {*/}
              {/*              if (date === null) {*/}
              {/*                setIssueDate(null);*/}
              {/*                state.setCurrentItem({...state.currentItem, identityCardIssueDate: null});*/}
              {/*              } else {*/}
              {/*                const adjustedDate = new Date(date);*/}
              {/*                adjustedDate.setHours(12, 0, 0, 0);*/}
              {/*                setIssueDate(adjustedDate);*/}
              {/*                state.setCurrentItem({...state.currentItem, identityCardIssueDate: adjustedDate});*/}
              {/*              }*/}

              {/*              state.setItemChanged(!state.itemChanged);*/}
              {/*              state.setCurrentFormState({...state.currentFormState, formSaveButtonDisabled: false});*/}
              {/*            }}*/}
              {/*/>*/}
              <p id="wrongCardIssueDateFormat" className={"err_info offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
              </p>
            </div>

          </div>
        </div>
        <div className="col-md-4">
          <div className="md-form mb-0">
            <label htmlFor={ identityCardProps.cardExpiryDate } className="">Data ważności</label>
            <div className="col">
              <QDatePicker
                pickerId="identityCardExpiryDate"
                errorParagraphId="wrongIdentityCardExpiryDateFormat"
                state={state}
                propertyName="identityCardExpiryDate"
                startDate={expiryDate}
                setStartDate={setExpiryDate}
              />


              {/*<DatePicker id="identityCardExpiryDate"*/}
              {/*            selected={expiryDate}*/}
              {/*            onChange={(date) => {*/}
              {/*              if (date === null) {*/}
              {/*                setExpiryDate(null);*/}
              {/*                state.setCurrentItem({...state.currentItem, identityCardExpiryDate: null});*/}
              {/*              } else {*/}
              {/*                const adjustedDate = new Date(date);*/}
              {/*                adjustedDate.setHours(12, 0, 0, 0);*/}
              {/*                setExpiryDate(adjustedDate);*/}
              {/*                state.setCurrentItem({...state.currentItem, identityCardExpiryDate: adjustedDate});*/}
              {/*              }*/}

              {/*              state.setItemChanged(!state.itemChanged);*/}
              {/*              state.setCurrentFormState({...state.currentFormState, formSaveButtonDisabled: false});*/}
              {/*            }}*/}
              {/*/>*/}
              <p id="wrongIdentityCardExpiryDateFormat" className={"err_info offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format daty
              </p>
            </div>
          </div>
        </div>


        </div>
      </div>
    </>
  );
};

export default EmployeeIdentityCard;
