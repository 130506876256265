import React from 'react';
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";


const PositionCertification = ({ index, state, certificationsList, onRemove }) => {
  const propertyName = `requiredCertifications[${index}]`;

  return (
    <div className="row">
      <div className="col-md-2">
        <SelectNested
          propertyName={propertyName}
          state={state}
          itemsList={certificationsList}
          displayNames={["name"]}
          showInput={false}
          showDelete={false}
          useId={false}
        />
      </div>

      <div className="col-md-1">
        <button
          className="btn btn-outline-danger"
          onClick={(e) => {
            e.preventDefault();
            onRemove();
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    </div>
  );
};


export default PositionCertification;
