import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ElementRemovalWarningModal = ({ onRemove, onCancel, warningText }) => {
  const [show, setShow] = useState(true);

  const handleConfirm = () => {
    setShow(false);
    if (onRemove) onRemove();
  };

  const handleCancel = () => {
    setShow(false);
    if (onCancel) onCancel();
  };

  return (
    <Modal show={show} onHide={handleCancel} backdrop="static" keyboard={false} className = "mt-5">
      <Modal.Header className="form-header-warning" closeButton closeVariant="white">
        <Modal.Title>Uwaga</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {warningText}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Anuluj
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Usuń
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ElementRemovalWarningModal;
