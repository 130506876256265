import React, {useEffect, useState} from 'react';
import Toggler from "../../../elements/Toggler";
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import ElementRemovalWarningModal from "../../common/ElementRemovalWarningModal";

const EmployeePosition = ({ employeePosition, index, state, onInputChange, positionsList, onRemove, settings }) => {

  const employee = state.currentItem;

  const [showModal, setShowModal] = useState(false);
  const [missingTrainingsNote, setMissingTrainingsNote] = useState('');
  const [missingCertificationsNote, setMissingCertificationsNote] = useState('');

  employeePosition.evaluationDate = employeePosition.evaluationDate || new Date().toISOString().split("T")[0];
  employeePosition.evaluation = employeePosition.evaluation || settings.positionEvaluationDefaultValue;

  function updateEvaluation() {
    employeePosition.evaluationDate = new Date().toISOString().split("T")[0];
    onInputChange({ target: { value: employeePosition.evaluationDate } }, employeePosition, "evaluationDate");
  }


  const handleRemoveClick = () => {
    if (employeePosition.position.name) {
    setShowModal(true);
    } else {
      onRemove();
    }

  };


  const handleModalConfirm = () => {
    setShowModal(false);
    onRemove();
  };


  const handleModalCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const findMissingItems = (requiredItems, userItems, getId, getName) => {
      const userItemIds = new Set(userItems.map(getId));
      return requiredItems
        .filter(item => !userItemIds.has(item.id))
        .map(getName)
        .join(', ');
    };

    if (employeePosition?.position) {
      setMissingTrainingsNote(
        findMissingItems(
          employeePosition.position.requiredTrainings || [],
          employee.employeeTrainings || [],
          et => et.training.id,
          mt => mt.name
        )
      );

      setMissingCertificationsNote(
        findMissingItems(
          employeePosition.position.requiredCertifications || [],
          employee.employeeCertifications || [],
          ec => ec.certificationType.id,
          mc => mc.name
        )
      );
    } else {
      setMissingTrainingsNote(null);
      setMissingCertificationsNote(null);
    }
  }, [employeePosition?.position, employee.employeeTrainings, employee.employeeCertifications]);



  // useEffect(() => {
  //   if (!employeePosition.position || !employeePosition.position.requiredTrainings) {
  //     setMissingTrainingsNote(null);
  //   } else {
  //     const employeeTrainingIds = employee.employeeTrainings.map(et => et.training.id);
  //
  //     const missingTrainings = employeePosition.position.requiredTrainings.filter(rt =>
  //       !employeeTrainingIds.includes(rt.id)
  //     );
  //
  //     setMissingTrainingsNote(
  //       missingTrainings.length > 0 ? missingTrainings.map(mt => mt.name).join(', ') : ''
  //     );
  //   }
  //
  //   if (!employeePosition.position || !employeePosition.position.requiredCertifications) {
  //     setMissingCertificationsNote(null);
  //   } else {
  //     const employeeCertificationIds = employee.employeeCertifications.map(ec => ec.certificationType.id);
  //
  //     const missingCertifications = employeePosition.position.requiredCertifications.filter(rc =>
  //       !employeeCertificationIds.includes(rc.id)
  //     );
  //
  //     setMissingCertificationsNote(
  //       missingCertifications.length > 0 ? missingCertifications.map(mc => mc.name).join(', ') : ''
  //     );
  //   }
  //
  //
  // }, [employeePosition.position.id, employee.employeeTrainings]);


  return (
    <div className="row">

      <div className="col-md-8">
        <div className="row">
          <div className="col-md-2">
            <SelectNested
              propertyName={`employeePositions[${index}].position`}
              state={state}
              itemsList={positionsList}
              displayNames={["name"]}
              showInput={false}
              showDelete={false}
              useId={false}
              disabled={employeePosition.id > 0}
            />
          </div>
          <div className="col-md-1">
            <input
              type="number"
              id={`position_evaluation-${employeePosition.id}`}
              value={employeePosition.evaluation || settings.positionEvaluationDefaultValue}
              onChange={(e) => onInputChange(e, employeePosition, "evaluation")}
              min={settings.positionEvaluationMinimumValue}
              max={settings.positionEvaluationMaximumValue}
              placeholder="Ocena"
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <input
              type="text"
              id={`comments-${employeePosition.id}`}
              value={employeePosition.comments || ""}
              onChange={(e) => onInputChange(e, employeePosition, "comments")}
              placeholder="Uwagi"
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <input
              type="text"
              id={`evaluationDate-${employeePosition.id}`}
              value={employeePosition.evaluationDate || new Date().toISOString().split("T")[0]}
              onChange={(e) => onInputChange(e, employeePosition, "evaluationDate")}
              placeholder="Data oceny"
              className="form-control"
              disabled={true}
            />
          </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-auxiliary btn-primary"
              onClick={updateEvaluation}
              disabled={employeePosition.id < 0}
            >
              <FontAwesomeIcon icon={faArrowsRotate} /> Aktualizuj
            </button>
          </div>
          <div className="col-md-1">
            <Toggler propertyName={`employeePositions[${index}].active`} state={state} />
          </div>
          <div className="col-md-1">
            <button className="btn btn-outline-danger" onClick={handleRemoveClick}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </div>
      </div>


      <div className="col-md-4">
        <div className="row">
          <div className="col-md-6">
            {missingTrainingsNote ? (
            <p id="missingTrainingsNote" className="err_info">
              {missingTrainingsNote}</p>
            ) : (<p id="missingTrainingsNote" className="ok_info">Brak</p>)}

          </div>
          <div className="col-md-6">
            {missingCertificationsNote ? (
              <p id="missingCertificationsNote" className="err_info">
                {missingCertificationsNote}</p>
            ) : (<p id="missingCertificationsNote" className="ok_info">Brak</p>)}

          </div>
        </div>
      </div>


      {showModal && (
        <ElementRemovalWarningModal
          onRemove={handleModalConfirm}
          onCancel={handleModalCancel}
          warningText={
            <>
              Czy na pewno usunąć stanowisko <strong>{employeePosition.position?.name}</strong>?
            </>
          }
        />
      )}
    </div>


  );
};

export default EmployeePosition;
