import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {useNavigate, useLocation} from "react-router-dom";
import {defaultFormState} from "../../../../defaults/Forms";
import {settingsDefault} from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onCloseDetails, onItemsListDeleteButtonClick, deleteItem, validateNumberField
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import useCrud from "../../../../hooks/useCrud";

import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ModalControls from "../../common/ModalControls";
import NumberInput from "../../../elements/NumberInput";
import Select from "../../../elements/Select";
import Toggler from "../../../elements/Toggler";
import {Table} from "../../../table/Table";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import SimpleDataDisplay from "../../common/SimpleDataDisplay";

const Settings = () => {

  const dataUrl = "/settings";
  const defaultItem = settingsDefault;
  const itemName = "Ustawienia";
  const navigate = useNavigate();
  const location = useLocation();
  const {updateItem, getItems} = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);


  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }


  const itemDictionary = {
    infractionWeightMinimumValue: "Minimalna waga przewinień pracowników",
    infractionWeightMaximumValue: "Maksymalna waga przewinień pracowników",
    commendationWeightMinimumValue: "Minimalna waga pochwał pracowników",
    commendationWeightMaximumValue: "Maksymalna waga pochwał pracowników",
    skillWeightMinimumValue: "Minimalna waga umiejętności pracowników",
    skillWeightMaximumValue: "Maksymalna waga umiejętności pracowników",
    skillEvaluationMinimumValue: "Minimalna ocena umiejętności pracowników",
    skillEvaluationMaximumValue: "Maksymalna ocena umiejętności pracowników",
    trainingEvaluationMinimumValue: "Minimalna ocena ze szkolenia pracowników",
    trainingEvaluationMaximumValue: "Maksymalna ocena ze szkolenia pracowników",
    sameManagerInManyDepartments: "Ta sama osoba jako manager w wielu departamentach",
    positionEvaluationDefaultValue: "Domyślna ocena umiejętności na stanowisku",
    positionEvaluationMinimumValue: "Minimalna ocena umiejętności na stanowisku",
    positionEvaluationMaximumValue: "Maksymalna ocena umiejętności na stanowisku",
  }


  const onDelete = async () => {
    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  }


  const onSaveItemClick = async (e) => {
    e.preventDefault();
    const formIsValid = fieldsValidation.every(isValid => isValid);

    if (!formIsValid) {
      return;
    }

    let response;
    const item = {
      id: currentItem.id,
      infractionWeightMinimumValue: currentItem.infractionWeightMinimumValue,
      infractionWeightMaximumValue: currentItem.infractionWeightMaximumValue,
      commendationWeightMinimumValue: currentItem.commendationWeightMinimumValue,
      commendationWeightMaximumValue: currentItem.commendationWeightMaximumValue,
      skillWeightMinimumValue: currentItem.skillWeightMinimumValue,
      skillWeightMaximumValue: currentItem.skillWeightMaximumValue,
      skillEvaluationMinimumValue: currentItem.skillEvaluationMinimumValue,
      skillEvaluationMaximumValue: currentItem.skillEvaluationMaximumValue,
      trainingEvaluationMinimumValue: currentItem.trainingEvaluationMinimumValue,
      trainingEvaluationMaximumValue: currentItem.trainingEvaluationMaximumValue,
      positionEvaluationDefaultValue: currentItem.positionEvaluationDefaultValue,
      positionEvaluationMinimumValue: currentItem.positionEvaluationMinimumValue,
      positionEvaluationMaximumValue: currentItem.positionEvaluationMaximumValue,
      sameManagerInManyDepartments: currentItem.sameManagerInManyDepartments
    };

    item.id = 1;

    response = await updateItem(`${dataUrl}/${item.id}`, item, state);

    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
    } else {
      response && onSaveAndClose({state});
    }
  }


  const fieldsValidation = [
    validateNumberField(currentItem.infractionWeightMinimumValue, "infractionWeightMinimumValue"),
    validateNumberField(currentItem.infractionWeightMaximumValue, "infractionWeightMaximumValue"),
    validateNumberField(currentItem.commendationWeightMinimumValue, "commendationWeightMinimumValue"),
    validateNumberField(currentItem.commendationWeightMaximumValue, "commendationWeightMaximumValue"),
    validateNumberField(currentItem.skillWeightMinimumValue, "skillWeightMinimumValue"),
    validateNumberField(currentItem.skillWeightMaximumValue, "skillWeightMaximumValue"),
    validateNumberField(currentItem.skillEvaluationMinimumValue, "skillEvaluationMinimumValue"),
    validateNumberField(currentItem.skillEvaluationMaximumValue, "skillEvaluationMaximumValue"),
    validateNumberField(currentItem.trainingEvaluationMinimumValue, "trainingEvaluationMinimumValue"),
    validateNumberField(currentItem.trainingEvaluationMaximumValue, "trainingEvaluationMaximumValue"),
    validateNumberField(currentItem.positionEvaluationDefaultValue, "positionEvaluationDefaultValue"),
    validateNumberField(currentItem.positionEvaluationMinimumValue, "positionEvaluationMinimumValue"),
    validateNumberField(currentItem.positionEvaluationMaximumValue, "positionEvaluationMaximumValue")


  ];


  const onClose = () => {
    onCloseDetails({state});
  };


  const getCompleteItem = async () => {
    const response = await getItems(`${dataUrl}`);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }


  useEffect(() => {
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
  }, [itemChanged])


  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])


  useEffect(() => {
    const getData = async () => {
      const response = await getItems(dataUrl);
      if (response.status === 200) {
        setLoading(false);
        setItems([response.data]);
      } else if (response.status === 401 || response.status === 403) {
        navigate('/login', {state: {from: location}, replace: true})
      } else {
        alert("Nie udało się pobrać danych.");
      }
    };
    getData();
  }, [])


  const settingsConfig = {
    infractionSettings: {
      title: "Ustawienia dla nagan",
      fields: ['infractionWeightMinimumValue', 'infractionWeightMaximumValue']
    },
    commendationSettings: {
      title: "Ustawienia dla pochwał",
      fields: ['commendationWeightMinimumValue', 'commendationWeightMaximumValue']
    },
    skillSettings: {
      title: "Ustawienia dla umiejętności",
      fields: ['skillWeightMinimumValue', 'skillWeightMaximumValue','skillEvaluationMinimumValue', 'skillEvaluationMaximumValue']
    },
    trainingsSettings: {
      title: "Ustawienia dla szkoleń",
      fields: ['trainingEvaluationMinimumValue', 'trainingEvaluationMaximumValue']
    },
    departmentsSettings: {
      title: "Ustawienia dla departamentów",
      fields: ['sameManagerInManyDepartments']
    },
    positionsSettings: {
      title: "Ustawienia dla stanowisk",
      fields: ['positionEvaluationDefaultValue', 'positionEvaluationMinimumValue', 'positionEvaluationMaximumValue']
    }
  };


  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv />;
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <SimpleDataDisplay
        state={{
          itemsList,
          setCurrentItem,
          setBackupItem,
          setCurrentFormState,
          currentFormState
        }}
        formHeader={`Edycja ${itemName}`}
        getCompleteItem={getCompleteItem}
        itemDictionary={itemDictionary}
        itemConfig={settingsConfig}
      />;
  } else {
    dataSectionContent = <div>Nie znaleziono danych, dodaj nowy element.</div>;
  }


  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader title={"Edycja ustawień globalnych aplikacji"}/>
      ) : (
        <AppComponentCardHeader title={"Ustawienia globalne aplikacji"}/>
      )}
      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1"></p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore mb-3" onClick={() => {
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>

                <div className="row">
                  <div className="col-md-12 mb-md-0 mb-5">
                    <form id="settings-form" name="settings-form">
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="infractionWeightMinimumValue" className="">Minimalna waga przewinień pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="infractionWeightMinimumValue" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="infractionWeightMaximumValue" className="">Maksymalna waga przewinień pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="infractionWeightMaximumValue" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="commendationWeightMinimumValue" className="">Minimalna waga pochwał pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="commendationWeightMinimumValue" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="commendationWeightMaximumValue" className="">Maksymalna waga pochwał pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="commendationWeightMaximumValue" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="skillWeightMinimumValue" className="">Minimalna waga umiejętności pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="skillWeightMinimumValue" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="skillWeightMaximumValue" className="">Maksymalna waga umiejętności pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="skillWeightMaximumValue" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="skillEvaluationMinimumValue" className="">Minimalna ocena umiejętności pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="skillEvaluationMinimumValue" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="skillEvaluationMaximumValue" className="">Maksymalna ocena umiejętności pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="skillEvaluationMaximumValue" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="trainingEvaluationMinimumValue" className="">Minimalna ocena ze szkolenia pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="trainingEvaluationMinimumValue" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="trainingEvaluationMaximumValue" className="">Maksymalna ocena ze szkolenia pracowników</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="trainingEvaluationMaximumValue" required={true} state={state}/>
                        </div>
                      </div>

                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="positionEvaluationDefaultValue" className="">Domyślna ocena umiejętności stanowiskowej</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="positionEvaluationDefaultValue" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="positionEvaluationMinimumValue" className="">Minimalna ocena umiejętności stanowiskowej</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="positionEvaluationMinimumValue" required={true} state={state}/>
                        </div>
                      </div>
                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="positionEvaluationMaximumValue" className="">Maksymalna ocena umiejętności stanowiskowej</label>
                        </div>
                        <div className="col-md-9">
                          <NumberInput propertyName="positionEvaluationMaximumValue" required={true} state={state}/>
                        </div>
                      </div>


                      <div className="row form-group mb-4">
                        <div className="col-md-3 form-label">
                          <label htmlFor="sameManagerInManyDepartments" className="">Ta sama osoba jako manager w wielu departamentach</label>
                        </div>
                        <div className="col-md-9">
                          <Toggler propertyName="sameManagerInManyDepartments" state={state} />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </section>
            </div>
            <ModalControls
              onDelete={null}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{ dataSectionContent }</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );


}

export default Settings;