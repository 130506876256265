import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChalkboardUser, faFrownOpen, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import {employeeInfractionDefault} from "../../../../defaults/Items";
import EmployeeInfraction from "./EmployeeInfraction";

const EmployeeInfractionsCard = ({ state, infractionsList, supervisorsList, settings }) => {

  const currentItem = state.currentItem;
  let employeeInfractions = currentItem?.employeeInfractions?.slice() || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [infractionsNote, setInfractionsNote] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  useEffect(()=> {
    //console.log(infractionsList);
  }, [currentItem])

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const onInputChange = (e, infraction, field) => {
    const updatedInfractions = employeeInfractions.map((item) =>
      item.id === infraction.id ? { ...item, [field]: e.target.value } : item
    );
    setCurrentItem({ ...currentItem, employeeInfractions: updatedInfractions });
    setItemChanged(!itemChanged);
  };


  const addInfraction = () => {
    const existingIds = currentItem.employeeInfractions.map(cert => cert.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }
    const newInfraction = { ...employeeInfractionDefault, EID: currentItem.id, id: newId};
    const updatedInfractions = [...employeeInfractions, newInfraction];
    setCurrentItem({ ...currentItem, employeeInfractions: updatedInfractions });
    setItemChanged(!itemChanged);
  };

  const removeInfraction = (id) => {
    const updatedInfractions = employeeInfractions.filter(item => item.id !== id);
    setCurrentItem({ ...currentItem, employeeInfractions: updatedInfractions });
    setItemChanged(!itemChanged);
  };


  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faFrownOpen} className="me-2 header-icon"/>
            <span>Przewinienia pracownika {!isAccordionOpen ? "(" + currentItem.employeeInfractions.length + ")" : ""}</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="employeeInfractions">
          <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addInfraction}><FontAwesomeIcon
            icon={ faCirclePlus }/> Dodaj</button>
          {employeeInfractions.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-1 header-label">Data zdarzenia</div>
              <div className="col-md-2 header-label">Typ przewinienia</div>
              <div className="col-md-2 header-label">Supervisor</div>
              <div className="col-md-7 header-label">Komentarz</div>
            </div>
          )}
          {employeeInfractions.length > 0 ? (
            employeeInfractions.map((infraction, index) => (
              <EmployeeInfraction
                key={index}
                infraction={infraction}
                index={index}
                state={state}
                onInputChange={onInputChange}
                infractionsList={infractionsList}
                supervisorsList={supervisorsList}
                onRemove={() => removeInfraction(infraction.id)}
              />
            ))
          ) : (
            <p>Brak przewinień</p>
          )}
        </div>
      </div>
      <p id="infractionsNote" className={infractionsNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
        Uzupełnij dane pochwały
      </p>
    </>
  );
};

export default EmployeeInfractionsCard;



