import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboardUser,
  faChevronDown,
  faChevronUp,
  faFaceGrin,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {employeeCommendationDefault} from "../../../../defaults/Items";
import EmployeeCommendation from "./EmployeeCommendation";

const EmployeeCommendationsCard = ({ state, commendationsList, supervisorsList, settings }) => {

  const currentItem = state.currentItem;
  let employeeCommendations = currentItem?.employeeCommendations?.slice() || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [commendationsNote, setCommendationsNote] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  useEffect(()=> {
    //console.log(commendationsList);
  }, [currentItem])

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const onInputChange = (e, commendation, field) => {
    const updatedCommendations = employeeCommendations.map((item) =>
      item.id === commendation.id ? { ...item, [field]: e.target.value } : item
    );
    setCurrentItem({ ...currentItem, employeeCommendations: updatedCommendations });
    setItemChanged(!itemChanged);
  };


  const addCommendation = () => {
    const existingIds = currentItem.employeeCommendations.map(cert => cert.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }

    const newCommendation = { ...employeeCommendationDefault, EID: currentItem.id, id: newId};
    const updatedCommendations = [...employeeCommendations, newCommendation];
    setCurrentItem({ ...currentItem, employeeCommendations: updatedCommendations });
    setItemChanged(!itemChanged);
  };

  const removeCommendation = (id) => {
    const updatedCommendations = employeeCommendations.filter(item => item.id !== id);
    setCurrentItem({ ...currentItem, employeeCommendations: updatedCommendations });
    setItemChanged(!itemChanged);
  };


  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faFaceGrin} className="me-2 header-icon"/>
            <span>Pochwały pracownika {!isAccordionOpen ? "(" + currentItem.employeeCommendations.length + ")" : ""}</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="employeeCommendations">
          <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addCommendation}><FontAwesomeIcon
            icon={ faCirclePlus }/> Dodaj</button>
          {employeeCommendations.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-1 header-label">Data zdarzenia</div>
              <div className="col-md-2 header-label">Typ pochwały</div>
              <div className="col-md-2 header-label">Supervisor</div>
              <div className="col-md-7 header-label">Komentarz</div>
            </div>
          )}
          {employeeCommendations.length > 0 ? (
            employeeCommendations.map((commendation, index) => (
              <EmployeeCommendation
                key={index}
                commendation={commendation}
                index={index}
                state={state}
                onInputChange={onInputChange}
                commendationsList={commendationsList}
                supervisorsList={supervisorsList}
                onRemove={() => removeCommendation(commendation.id)}
              />
            ))
          ) : (
            <p>Brak udzielonych pochwał</p>
          )}
        </div>
      </div>
      <p id="commendationsNote" className={commendationsNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
        Uzupełnij dane pochwały
      </p>
    </>
  );
};

export default EmployeeCommendationsCard;



