import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";


const ContractTemplate = () => {

  let dataSectionContent =
    <div className={"warn_info"}><FontAwesomeIcon
      className={"warning-icon div-visible"} icon={faExclamationCircle}/>&nbsp;&nbsp;&nbsp;JESZCZE NIE ZAIMPLEMENTOWANO</div>

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid px-4 mt-3">
          <div className={"card shadow mb-5 bg-transparent rounded-2"}>{ dataSectionContent }</div>
      </div>
    </div>
  );
}

export default ContractTemplate;

