import React, { useState, useEffect, useRef } from 'react';
import { resetInvalidInputField } from "../../js/CommonHelper";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectNested = ({ label, propertyName, required, disabled, state, itemsList, itemName, displayNames, showInput, showDelete, useId }) => {
  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const itemChanged = state.itemChanged;
  const setItemChanged = state.setItemChanged;
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const isNested = propertyName.includes('.') || propertyName.includes('[');

  const getNestedProperty = (baseObject, path) => {
    return path.split('.').reduce((acc, part) => {
      const match = part.match(/(\w+)\[(\d+)\]/);
      if (match) {
        const arrayName = match[1];
        const index = parseInt(match[2], 10);
        return acc && acc[arrayName] ? acc[arrayName][index] : undefined;
      }
      return acc ? acc[part] : undefined;
    }, baseObject);
  };


  const setNestedProperty = (obj, path, value) => {
    const parts = path.split('.');
    const lastPart = parts.pop();
    const target = parts.reduce((acc, part) => {
      const match = part.match(/(\w+)\[(\d+)\]/);
      if (match) return acc[match[1]][parseInt(match[2], 10)];
      return acc[part];
    }, obj);
    const match = lastPart.match(/(\w+)\[(\d+)\]/);
    if (match) target[match[1]][parseInt(match[2], 10)] = value;
    else target[lastPart] = value;
  };


  const handleReset = () => {

    const updatedItem = { ...currentItem };
    if (isNested) {
      setNestedProperty(updatedItem, propertyName, useId ? 0 : undefined);
    } else {
      updatedItem[propertyName] = useId ? 0 : undefined;
    }
    setCurrentItem(updatedItem);
    setCurrentFormState({ ...currentFormState, formSaveButtonDisabled: false });
    setItemChanged(!itemChanged);
  };

  const handleSelectChange = (item) => {
    const valueToSet = useId ? item.id : item;
    const updatedItem = { ...currentItem };
    if (isNested) {
      setNestedProperty(updatedItem, propertyName, valueToSet);
    } else {
      updatedItem[propertyName] = valueToSet;
    }
    setCurrentItem(updatedItem);
    setCurrentFormState({ ...currentFormState, formSaveButtonDisabled: false });
    setItemChanged(!itemChanged);
    setIsOpen(false);
    setSearchTerm("");
    resetInvalidInputField(propertyName);
  };


  const currentValue = isNested
    ? getNestedProperty(currentItem, propertyName)
    : currentItem[propertyName];

  const displayValue = useId && currentValue
    ? itemsList.find(item => item.id === currentValue) || {}
    : currentValue;

  const filteredItems = itemsList.filter(item =>
      item && displayNames.some(displayName =>
        item[displayName] && item[displayName].toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', position: 'relative'}} ref={selectRef}>
      <div style={{ flex: 1 }}>
        <div className="form-select"
             onClick={() => setIsOpen(!isOpen)}
             style={{ cursor: 'pointer', backgroundColor: disabled? '#E9ECEF' : 'white' }}
             id={propertyName}
        >
          {displayValue && displayValue.id !== 0
            ? displayNames.map(displayName => displayValue[displayName]).join(' ')
            : '-- Wybierz --'}
        </div>
        {isOpen && (
          <div style={{ position: 'absolute', zIndex: 1, background: 'white', border: '1px solid #ccc', width: '100%' }}>
            {showInput && (
              <input
                type="text"
                className="form-control"
                placeholder="Wyszukaj..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoFocus
                disabled={disabled}
              />
            )}

            <div className="dropdown-menu show">
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <button
                    key={item.id}
                    className="dropdown-item"
                    onClick={() => handleSelectChange(item)}
                    disabled={disabled}
                  >
                    {displayNames.map(displayName => item[displayName]).join(' ')}
                  </button>
                ))
              ) : (
                <div className="dropdown-item text-muted">
                  Nie znaleziono żadnych pozycji. Zaktualizuj słownik.
                </div>
              )}
            </div>

          </div>
        )}
      </div>
      {showDelete && (
        <button onClick={handleReset} disabled={!(currentValue && currentValue !== 0)} className='btn btn-outline-danger'>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      )}
    </div>
  );
};

export default SelectNested;

