import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Tab, Container } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faInfoCircle, faEye, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import ModalControls from "../../common/ModalControls";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultFormState } from "../../../../defaults/Forms";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import {employeeCompactedDefault, employeeDefault, settingsDefault} from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import AppAddDataButton from "../../common/AppAddDataButton";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import ItemDetailsModalHeader from "../../common/ItemDetailsModalHeader";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import RelatedItemsList from "../../common/RelatedItemsList";
import useCrud from "../../../../hooks/useCrud";
import { Table } from "../../../table/Table";
import UserRolesCard from "../users/UserRolesCard";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useAxiosPrivateFileUpload from "../../../../hooks/useAxiosPrivateFileUpload";
import NumberInput from "../../../elements/NumberInput";
import Select from "../../../elements/Select";
import Employee_Personal_Data_Tab from "./Employee_Personal_Data_Tab";
import Employee_Company_Data_Tab from "./Employee_Company_Data_Tab";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/; // requires 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special char and has to be 8-24 chars long

const Employee = () => {

  const dataUrl = "/employees";
  const maritalStatusesUrl="/marital-statuses";
  const gendersUrl = "/genders";
  const nfzBranchesUrl = "/nfz-branches";
  const taxOfficesUrl = "/tax-offices";
  const hiringStagesUrl = "/hiring-stages";
  const countriesUrl = "/countries";
  const identityCardTypesUrl = "/identity-card-types";
  const settingsUrl = "/settings";
  const dataFullUrl = "/employees";
  const rolesUrl = "/admin/role";
  const skillsUrl = "/skills";
  const trainingsUrl = "/trainings";
  const commendationsUrl = "/commendations";
  const infractionsUrl = "/infractions";
  const certificationTypesUrl = "/certification-types";
  const medexamTypesUrl = "/medical-examination-types";
  const absenceTypesUrl = "/absence-types";
  const departmentsUrl = "/departments";
  const positionsUrl = "/positions";
  const departmentPositionsUrl = "/departments/id/positions";
  const hiringCompaniesUrl = "/business-partners/hiring-companies"

  const imagesFolder = "../images/employees_photos/";
  const employeeImagePlaceholder = "../images/employees_photos/placeholder.jpg";
  const defaultItem = employeeCompactedDefault;
  const defaultFullItem = employeeDefault;
  const itemName = "pracownik";
  const itemNames = "pracownicy";
  const axiosPrivate = useAxiosPrivate();
  const axiosPrivateFileUpload = useAxiosPrivateFileUpload();

  const navigate = useNavigate();
  const location = useLocation();
  const { createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId } = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultFullItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [trainingsList, setTrainingsList] = useState([]);
  const [commendationsList, setCommendationsList] = useState([]);
  const [infractionsList, setInfractionsList] = useState([]);
  const [validPassword, setValidPassword] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");
  const [updateConflict, setUpdateConflict] = useState(false);
  const [oldPasswordValidationPassed, setOldPasswordValidationPassed] = useState(false);
  const [maritalStatusesList, setMaritalStatuses] = useState([]);
  const [gendersList, setGenders] = useState([]);
  const [nfzBranchesList, setNfzBranches] = useState([]);
  const [taxOfficesList, setTaxOffices] = useState([]);
  const [hiringStagesList, setHiringStages] = useState([]);
  const [countriesList, setCountries] = useState([]);
  const [identityCardTypesList, setIdentityCardTypes] = useState([]);
  const [certificationTypesList, setCertificationTypesList] = useState([]);
  const [medExamTypesList, setMedExamTypesList] = useState([]);
  const [absenceTypesList, setAbsenceTypesList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  const [hiringCompaniesList, setHiringCompaniesList] = useState([]);
  // const [displayedManager, setDisplayedManager] = useState([]);
  // const [displayedDeputyName, setDisplayedDeputyName] = useState([]);

  // elements related to the item

  const fileInput = useRef(null);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    //console.log(commendationsList);
  }, commendationsList)


  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false, visible: false},
    {label: "Login", accessor: "login", sortable: true, searchable: true},
    {label: "Imię", accessor: "firstName", sortable: true, searchable: true},
    {label: "Nazwisko", accessor: "lastName", sortable: true, searchable: true},
    {label: "Dział", accessor: "department", sortable: true, searchable: true, format: (department) => department ? `${department.name}` : "", fieldsToInclude: ['name']},
    // {label: "Stanowisko", accessor: "position", sortable: true, searchable: true, format: (position) => position ? `${position.name}` : "", fieldsToInclude: ['name']},
    {label: "Etap zatrudnienia", accessor: "hiringStage", sortable: true, searchable: true, format: (hiringStage) => hiringStage ? `${hiringStage.name}` : "", fieldsToInclude: ['name']},
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];


  const onDelete = async () => {
    const response = await deleteItem(`${ dataFullUrl }/${ currentItem.id }`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', { state: { from: location }, replace: true });
    }
  }

  const validatePassword = (newPassword) => {
    //console.log("VALIDATING");

    if (currentFormState.formAddingDataMode && newPassword === "") {
      setValidPassword(false);
      //console.log("Hasło nie może być puste")
      return;
    }

    // TODO Remove 123 and ABC from valid cases
    if ((newPassword && !PWD_REGEX.test(newPassword)) && newPassword !== "123" && newPassword !== "ABC") {
      setValidPassword(false);
      //console.log("Hasło nie spełnia warunków");
      return false;
    }
    return true;
  }


  const deleteImageLink = () => {
    setCurrentItem({...currentItem, photo: ""})
  }


  const onSaveItemClick = async (e) => {
    e.preventDefault();

    const oldPwdField = document.getElementById("oldPassword");
    const oldPwd = oldPwdField.value;

    if(currentItem.password && oldPwd === "" && !currentFormState.formAddingDataMode) {
      //console.log("błąd password oldPwd");

      let nameInput = document.getElementById("oldPassword");
      nameInput.classList.add("form-input-invalid");
      nameInput.placeholder = `ten element jest wymagany`;
      return;
    }

    if(!currentItem.login || currentItem.login.length < 4) {
      //console.log("błąd login < 4 or null");
      let nameInput = document.getElementById("login");
      nameInput.classList.add("form-input-invalid");
      nameInput.placeholder = `ten element jest wymagany`;
      return;
    }

    if(!currentItem.login || !currentItem.primaryEmail || !currentItem.firstName || !currentItem.lastName || !currentItem.dateOfBirth || !currentItem.gender || !currentItem.department) {
      if(!currentItem.login) {
        //console.log("błąd login null");
        let loginInput = document.getElementById("login");
        loginInput.classList.add("form-input-invalid");
        loginInput.placeholder = `ten element jest wymagany`;
      }
      if(!currentItem.primaryEmail) {
        //console.log("błąd email mull");
        let emailInput = document.getElementById("primaryEmail");
        emailInput.classList.add("form-input-invalid");
        emailInput.placeholder = `ten element jest wymagany`;
      }
      if(!currentItem.firstName) {
        //console.log("firstName jest null");
        let firstNameInput = document.getElementById("firstName");
        firstNameInput.classList.add("form-input-invalid");
        firstNameInput.placeholder = `ten element jest wymagany`;
      }
      if(!currentItem.lastName) {
        //console.log("błąd lastname mull");
        let lastNameInput = document.getElementById("lastName");
        lastNameInput.classList.add("form-input-invalid");
        lastNameInput.placeholder = `ten element jest wymagany`;
      }
      if(!currentItem.dateOfBirth) {
        //console.log("błąd DOB mull");
        let birthDateInput = document.getElementById("dateOfBirth");
        birthDateInput.classList.add("form-input-invalid");
        birthDateInput.placeholder = `ten element jest wymagany`;
      }
      if(!currentItem.gender) {
        //console.log("błąd gender mull");
        let genderInput = document.getElementById("gender");
        genderInput.classList.add("form-input-invalid");
        genderInput.placeholder = `ten element jest wymagany`;
      }

      if(!currentItem.department) {
        let departmentInput = document.getElementById("department");
        departmentInput.classList.add("form-input-invalid");
        departmentInput.placeholder = `ten element jest wymagany`;
      }
      return
    }




      // TODO!
    // if(currentItem.userRoles.length === 0) {
    //   let userRolesDiv = document.getElementById("userRoles");
    //   userRolesDiv.classList.add("form-input-invalid");
    //   return;
    // }

    let response;

    const item = {
      id: currentItem.id,
      login: currentItem.login,
      password: currentItem.password ? currentItem.password : null,
      gender: currentItem.gender ? {id: currentItem.gender.id} : null,
      firstName: currentItem.firstName,
      secondName: currentItem.secondName,
      lastName: currentItem.lastName,
      oldPassword: oldPwd,
      dateOfBirth: currentItem.dateOfBirth,
      placeOfBirth: currentItem.placeOfBirth,
      mothersName: currentItem.mothersName,
      fathersName: currentItem.fathersName,
      maidenName: currentItem.maidenName,
      maritalStatus: currentItem.maritalStatus ? { id: currentItem.maritalStatus.id } : null,
      pesel: currentItem.pesel,
      nip: currentItem.nip,
      primaryPhone: currentItem.primaryPhone,
      secondaryPhone: currentItem.secondaryPhone,
      primaryEmail: currentItem.primaryEmail,
      secondaryEmail: currentItem.secondaryEmail,
      userRoles: currentItem.userRoles,
      photos: currentItem.photos,
      createDate: currentItem.createDate,
      lastUpdateDate: currentItem.lastUpdateDate,
      createdBy: currentItem.createdBy,
      updatedBy: currentItem.updatedBy,
      iceContact: currentItem.iceContact,
      bankAccountNumber: currentItem.bankAccountNumber,
      nfzBranch: currentItem.nfzBranch ? { id: currentItem.nfzBranch.id } : null,
      taxOffice: currentItem.taxOffice ? { id: currentItem.taxOffice.id } : null,
      student: currentItem.student,
      pupil: currentItem.pupil,
      studentId: currentItem.studentId,
      unwanted: currentItem.unwanted,
      unwantedReason: currentItem.unwantedReason,
      parentalConsent: currentItem.parentalConsent,
      enabled: currentItem.enabled,
      hiringStage: currentItem.hiringStage ? {id: currentItem.hiringStage.id} : null,
      primaryAddressStreet: currentItem.primaryAddressStreet,
      primaryAddressStreetNumber: currentItem.primaryAddressStreetNumber,
      primaryAddressPostalCode: currentItem.primaryAddressPostalCode,
      primaryAddressCity: currentItem.primaryAddressCity,
      primaryAddressCountry: currentItem.primaryAddressCountry ? {id: currentItem.primaryAddressCountry.id} : null,
      secondaryAddressStreet: currentItem.secondaryAddressStreet,
      secondaryAddressStreetNumber: currentItem.secondaryAddressStreetNumber,
      secondaryAddressPostalCode: currentItem.secondaryAddressPostalCode,
      secondaryAddressCity: currentItem.secondaryAddressCity,
      secondaryAddressCountry: currentItem.secondaryAddressCountry ? {id: currentItem.secondaryAddressCountry.id} : null,
      identityCardType: currentItem.identityCardType ? {id: currentItem.identityCardType.id} : null,
      identityCardId: currentItem.identityCardId,
      identityCardIssueDate: currentItem.identityCardIssueDate,
      identityCardExpiryDate: currentItem.identityCardExpiryDate,
      issuedBy: currentItem.issuedBy,
      issueLocation: currentItem.issueLocation,
      employeePositions: currentItem.employeePositions,
      employeeSkills: currentItem.employeeSkills,
      employeeTrainings: currentItem.employeeTrainings,
      employeeCommendations: currentItem.employeeCommendations,
      employeeInfractions: currentItem.employeeInfractions,
      employeeCertifications: currentItem.employeeCertifications,
      employeeMedExams: currentItem.employeeMedExams,
      employeeAbsences: currentItem.employeeAbsences,
      missingTrainings: currentItem.missingTrainings,
      department: currentItem.department,
      position: currentItem.position,
      communicationByEmail: currentItem.communicationByEmail,
      communicationBySMS: currentItem.communicationBySMS,
      hiringCompany: currentItem.hiringCompany ? {id: currentItem.hiringCompany.id} : null,

    }

    //console.log(item);

    if (imageFile) uploadImage(imageFile);



    if (currentFormState.formAddingDataMode) {
      const itemToSave = {...item, id: 0}
      response = await createItem(dataFullUrl, itemToSave, state);
    } else {
      //console.log(item);
      response = await updateItem(`${ dataFullUrl }/${ item.id }`, item, state);
    }


    if (response.status === 422) {
      setOldPasswordValidationPassed(false);
      return;
    } else {
      setOldPasswordValidationPassed(true);
    }


    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
      setUpdateConflict(true);

    } else {
      response && onSaveAndClose({state});
    }

    if (imageFile) {
      // TODO
    }

  }

  // const checkRelatedItems = async (id) => {
  //   const data = await getRelatedChildrenByParentId(`${ userClientsUrl }/${ id }`, id, setUserClientsList);
  //   data.length === 0 ? setAllowDelete(true) : setAllowDelete(false);
  // }

  const onClose = () => {
    setUpdateConflict(false);
    setImageFile(null);
    onCloseDetails({ state })
  };


  useEffect(() => {
    if (updateConflict) setUpdateConflict(false);
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
//    console.log("CURRENT ITEM:" + currentItem.firstName + "/" + currentItem.photos);
  }, [itemChanged])


  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete);
    }
  }, [allowDelete])


  useEffect(() => {
    const getData = async () => {
      const departmentId = String(currentItem.department.id);
        const [itemsData] = await Promise.all([getItems(departmentPositionsUrl.replace("id", departmentId))]);
        const success = itemsData.status === 200;
        if (success) {
          setPositionsList(itemsData.data);
        } else {
          navigate('/login', {state: {from: location}, replace: true})
        }

    }

    getData();
  }, [currentItem.department])


  useEffect(() => {
    const getData = async () => {
      const [itemsData, rolesData, maritalStatusesData, gendersData, nfzBranchesData, taxOfficesData, hiringStagesData, countriesData, identityCardTypesData, settingsData,
        skillsData, trainingsData, commendationsData, infractionsData, certificationTypesData, medexamTypesData, absencesTypesData, departmentsData, positionsData, hiringCompaniesData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(rolesUrl),
          getItems(maritalStatusesUrl),
          getItems(gendersUrl),
          getItems(nfzBranchesUrl),
          getItems(taxOfficesUrl),
          getItems(hiringStagesUrl),
          getItems(countriesUrl),
          getItems(identityCardTypesUrl),
          getItems(settingsUrl),
          getItems(skillsUrl),
          getItems(trainingsUrl),
          getItems(commendationsUrl),
          getItems(infractionsUrl),
          getItems(certificationTypesUrl),
          getItems(medexamTypesUrl),
          getItems(absenceTypesUrl),
          getItems(departmentsUrl),
          getItems(positionsUrl),
          getItems(hiringCompaniesUrl)
        ]);

      const success =
        itemsData.status === 200 &&
        rolesData.status === 200 &&
        maritalStatusesData.status === 200 &&
        gendersData.status === 200 &&
        nfzBranchesData.status === 200 &&
        taxOfficesData.status === 200 &&
        hiringStagesData.status === 200 &&
        countriesData.status === 200 &&
        identityCardTypesData.status === 200 &&
        skillsData.status === 200 &&
        settingsData.status === 200 &&
        trainingsData.status === 200 &&
        commendationsData.status === 200 &&
        infractionsData.status === 200 &&
        certificationTypesData.status === 200 &&
        medexamTypesData.status === 200 &&
        absencesTypesData.status === 200 &&
        departmentsData.status === 200 &&
        positionsData.status === 200 &&
        hiringCompaniesData.status === 200
      ;

      if (success) {
        setLoading(false);
        setItems(itemsData.data);
        setRolesList(rolesData.data);
        setMaritalStatuses(maritalStatusesData.data);
        setGenders(gendersData.data);
        setNfzBranches(nfzBranchesData.data);
        setTaxOffices(taxOfficesData.data);
        setHiringStages(hiringStagesData.data);
        setCountries(countriesData.data);
        setIdentityCardTypes(identityCardTypesData.data);
        setSkillsList(skillsData.data);
        setSettingsList(settingsData.data);
        setTrainingsList(trainingsData.data);
        setCommendationsList(commendationsData.data);
        setInfractionsList(infractionsData.data);
        setCertificationTypesList(certificationTypesData.data);
        setMedExamTypesList(medexamTypesData.data);
        setAbsenceTypesList(absencesTypesData.data);
        setDepartmentsList(departmentsData.data);
        //setPositionsList(positionsData.data);
        setHiringCompaniesList(hiringCompaniesData.data);
      } else {
        navigate('/login', { state: { from: location }, replace: true})
      }
    };

    getData();
  }, [])


  const getCompleteItem = async (id) => {
    const response = await getItems(`${dataFullUrl}/${id}`);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', { state: { from: location }, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }


  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem: defaultFullItem,
    currentFormState,
    setCurrentFormState,
    formDescription: ``,
    formHeader: `Nowy ${ itemName }`,
    buttonTitle: `Dodaj`
  }

  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete, validPassword, setValidPassword,
    maritalStatusesList, setMaritalStatuses,
    gendersList, setGenders,
    nfzBranchesList, setNfzBranches,
    taxOfficesList, setTaxOffices,
    hiringStagesList, setHiringStages,
    countriesList, setCountries,
    identityCardTypesList, setIdentityCardTypes,
    hiringCompaniesList, setHiringCompaniesList
  }

  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv />
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows = { itemsList }
        columns = { columns }
        state = { state }
        // checkRelatedItems = { checkRelatedItems }
        formHeader = {`Edycja pracownika`}
        // relatedItemsUrl = { userClientsUrl }
        getCompleteItem = { getCompleteItem }
      />
    // console.log(itemsList);
  } else {
    dataSectionContent = <h6>NO DATA FOUND, PLEASE ADD A NEW `${itemName.toUpperCase()}`</h6>
  }


  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("imageFile", file);
    axiosPrivateFileUpload.post('http://localhost:8080/employee/upload-image', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(() => console.log("sent"))
      .catch(error => console.log("ERROR!:" + error))
  }


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imgName = event.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    //console.log("FILE");
    //console.log(file);

    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgName, {
              type: "image/png",
              lastModified: Date.now(),
            });
            setImageFile(file);
            setItemChanged(!itemChanged);
          },
          "image/jpeg",
          1
        );

        setCurrentItem({...currentItem, photos: event.target.files[0].name});
      };
    };
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };


  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader
          title={"Edycja pozycji słownika " + `${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}` + " -> " + currentItem.login}/>
      ) : (
        <AppComponentCardHeader
          title={"Pozycje słownika " + `${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}`}
          buttonProps={addDataButtonProps}/>
      )}

      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">

              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{currentFormState.formDescription}</p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={() => {
                    setUpdateConflict(false)
                    const pwdConfirmationField = document.getElementById("password1");
                    if (pwdConfirmationField) {
                      pwdConfirmationField.value = null;
                    }
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>

                <div className="row">
                  <div className="col-md-12 mb-md-0 mb-1" id="wrapper">

                    <form id="employee-form" name="employee-form">

                      <Tabs defaultActiveKey="employee-personal-data" id="employee" className="mb-3">
                        <Tab eventKey="employee-personal-data" title="Dane osobowe"><Employee_Personal_Data_Tab setValidPassword={setValidPassword} state={state}/></Tab>
                        {!currentFormState.formAddingDataMode && (
                        <Tab eventKey="employee-company-data" title="Dane firmowe"><Employee_Company_Data_Tab
                            state={state}
                            rolesList={rolesList}
                            skillsList={skillsList}
                            trainingsList={trainingsList}
                            commendationsList={commendationsList}
                            infractionsList={infractionsList}
                            certificationTypesList={certificationTypesList}
                            medExamTypesList={medExamTypesList}
                            absenceTypesList={absenceTypesList}
                            supervisorsList={itemsList.map(item => ({
                              id: item.id,
                              login: item.login,
                              firstName: item.firstName,
                              lastName: item.lastName,
                              permission: item.roles && item.roles.includes("modify")
                            }))}
                            departmentsList={departmentsList}
                            positionsList={positionsList}
                            settings={settingsList}/></Tab>
                        )}

                      </Tabs>

                    </form>


                    <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                      Nie można dodać ani zmienić tego elementu, ponieważ istnieje już wpis o tej nazwie i/lub skrócie
                    </p>

                  </div>

                </div>
              </section>

            </div>
            <ModalControls
              onDelete={onDelete}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div
            className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{dataSectionContent}</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );



}

export default Employee;