import React from 'react';
import { resetInvalidInputField } from "../../helpers/ComponentHelper";
import '../../css/Form.css'


// NOWA WERSJA DZIAŁAJĄCA NA ZAGNIEŻDŻONYCH OBIEKTACH
const Toggler = ({ propertyName, state }) => {
  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const itemChanged = state.itemChanged;
  const setItemChanged = state.setItemChanged;

  const getNestedProperty = (obj, path) => path.split('.').reduce((acc, part) => {
    const match = part.match(/(\w+)\[(\d+)\]/);
    if (match) return acc[match[1]][parseInt(match[2], 10)];
    return acc[part];
  }, obj);

  const setNestedProperty = (obj, path, value) => {
    const parts = path.split('.');
    const lastPart = parts.pop();
    const target = parts.reduce((acc, part) => {
      const match = part.match(/(\w+)\[(\d+)\]/);
      if (match) return acc[match[1]][parseInt(match[2], 10)];
      return acc[part];
    }, obj);
    const match = lastPart.match(/(\w+)\[(\d+)\]/);
    if (match) target[match[1]][parseInt(match[2], 10)] = value;
    else target[lastPart] = value;
  };

  const isNested = propertyName.includes('.') || propertyName.includes('[');

  const currentValue = isNested
    ? getNestedProperty(currentItem, propertyName)
    : currentItem[propertyName];

  return (
    <label className="toggler">
      <input
        type="checkbox"
        id={propertyName}
        name={propertyName}
        checked={currentValue}
        onChange={() => {
          setItemChanged(!itemChanged);
          const updatedItem = { ...currentItem };
          if (isNested) {
            setNestedProperty(updatedItem, propertyName, !currentValue);
          } else {
            updatedItem[propertyName] = !currentValue;
          }
          setCurrentItem(updatedItem);
          setCurrentFormState({ ...currentFormState, formSaveButtonDisabled: false });
          resetInvalidInputField(propertyName);
        }}
      />
      <span className="slider"></span>
    </label>
  );
};

export default Toggler;




/* STARA WERSJA DZIAŁAJĄCA NA PROSTYCH OBIEKTACH
const Toggler = ({ propertyName, state }) => {
  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const itemChanged = state.itemChanged;
  const setItemChanged = state.setItemChanged;

  return (
    <label className="toggler">
      <input
        type="checkbox"
        id = { propertyName }
        name = { propertyName }
        checked={currentItem[propertyName]}
        onChange={() => {
          setItemChanged(!itemChanged);
          setCurrentItem({
            ...currentItem,
            [propertyName]: !currentItem[propertyName]
          });
          setCurrentFormState({ ...currentFormState, formSaveButtonDisabled: false });
          resetInvalidInputField(propertyName);
        }}
      />
      <span className="slider"></span>
    </label>
  );
};

export default Toggler;
*/