import React from 'react';
import { resetInvalidInputField } from "../../helpers/ComponentHelper";

const TextInput = ({ propertyName, state, required, disabled, readonly, style, maxlen, draggable, type, identifier }) => {
  const { currentItem, setCurrentItem, currentFormState, setCurrentFormState, itemChanged, setItemChanged } = state;


  const handleDragStart = (e) => {
    const inputField = document.getElementById(propertyName);
    const inputValue = inputField.value;
    e.dataTransfer.setData("text/plain", inputValue);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedValue = e.dataTransfer.getData("text/plain");
    const currentValue = currentItem[propertyName] || "";

    setCurrentItem({
      ...currentItem,
      [propertyName]: currentValue + droppedValue
    });
    setItemChanged(true);
    setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
  };

  return (
    <>
      <input style={style}
             type={type ? type : "text"}
             id={identifier ? identifier : propertyName}
             name={propertyName}
             className="form-control"
             required={required}
             disabled={disabled}
             readOnly={readonly}
             value={currentItem[propertyName]}
             maxLength={maxlen}
             draggable={draggable}
             onDragStart={handleDragStart}
             onChange={(e) => {
               const value = e.target.value === "" ? null : e.target.value;

               setItemChanged(!itemChanged);
               setCurrentItem({
                 ...currentItem,
                 [propertyName]: value
               });
               setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
             }}
             onClick={() => resetInvalidInputField(propertyName)}
             onDragOver={handleDragOver}
             onDrop={handleDrop}
      ></input>
    </>
  );
};

export default TextInput;




// import React from 'react';
// import {resetInvalidInputField} from "../../helpers/ComponentHelper";
//
// const TextInput = ({propertyName, state, required, disabled, readonly, value, style, maxlen}) => {
//
//   const currentItem = state.currentItem;
//   const setCurrentItem = state.setCurrentItem;
//   const currentFormState = state.currentFormState;
//   const setCurrentFormState = state.setCurrentFormState;
//   const itemChanged = state.itemChanged;
//   const setItemChanged = state.setItemChanged;
//
//
//   return (
//     <>
//       <input style={ style }
//         type = "text"
//         id = { propertyName }
//         name = { propertyName }
//         defaultValue = { currentItem[propertyName] }
//         className = "form-control"
//         required = { required }
//         disabled={ disabled }
//         readOnly = { readonly }
//         value = { value }
//         maxLength = { maxlen }
//         onChange = { (item) => {
//           setItemChanged(!itemChanged);
//           setCurrentItem(currentItem => ({...currentItem,
//             [propertyName]: item.target.value}));
//           setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
//         }}
//         onClick = {() => {
//           resetInvalidInputField(propertyName);
//         }}
//       ></input>
//     </>
//   );
// };
//
// export default TextInput;
