import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import ModalControls from "../../common/ModalControls";
import {useNavigate, useLocation} from "react-router-dom";
import {defaultFormState} from "../../../../defaults/Forms";
import {skillDefault} from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails, setForegroundColor, validateNumberField
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import ItemDetailsModalHeader from "../../common/ItemDetailsModalHeader";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import useCrud from "../../../../hooks/useCrud";
import {Table} from "../../../table/Table";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Toggler from "../../../elements/Toggler";
import {HexColorPicker} from "react-colorful";
import NumberInput from "../../../elements/NumberInput";

const Skill = () => {

  const dataUrl = "/skills";
  const settingsUrl = "/settings";
  const relatedItemsUrl = null; // if no need to check it, initialize with null and remove RelatedItemsList from details modal
  const defaultItem = skillDefault;
  const itemName = "umiejętność";
  const itemNames = "umiejętności";
  const navigate = useNavigate();
  const location = useLocation();
  const {createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId} = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [updateConflict, setUpdateConflict] = useState(false);
  const [conflictingRoles, setConflictingRoles] = useState(false);
  const [itemColor, setItemColor] = useState("#ffffff")
  const [settingsList, setSettingsList] = useState([]);


  // elements related to current item
  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false},
    {label: "Nazwa", accessor: "name", sortable: true, searchable: true},
    {label: "Skrócona nazwa", accessor: "shortName", sortable: true, searchable: true},
    {label: "Opis", accessor: "description", sortable: true, searchable: true},
    {label: "Kolor etykiety", accessor: "color", sortable: false, searchable: false, type: "color"},
    {label: "Waga", accessor: "weight", sortable: true, searchable: true},
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];


  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }


  const onDelete = async () => {
    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  }


  const onSaveItemClick = async (e) => {
    e.preventDefault();
    const formIsValid = fieldsValidation.every(isValid => isValid);

    if (!formIsValid) {
      return;
    }

    if (!currentItem.name || !currentItem.shortName) {
      if (!currentItem.name) {
        let nameInput = document.getElementById("name");
        nameInput.classList.add("form-input-invalid");
        nameInput.placeholder = `ten element jest wymagany`;
      }

      if (!currentItem.shortName) {
        let shortNameInput = document.getElementById("shortName");
        shortNameInput.classList.add("form-input-invalid");
        shortNameInput.placeholder = `ten element jest wymagany`;
      }
      return;
    }

    let response;
    const item = {
      id: currentItem.id,
      name: currentItem.name,
      shortName: currentItem.shortName,
      description: currentItem.description,
      color: currentItem.color,
      weight: currentItem.weight
    };


    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataUrl, item, state);
    } else {
      response = await updateItem(`${dataUrl}/${item.id}`, item, state);
    }

    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
      setUpdateConflict(true);
    } else {
      response && onSaveAndClose({state});
    }
  }


  const changeColor = (itemColor) => {
    setItemChanged(!itemChanged);
    setItemColor(itemColor)
    setCurrentItem({...currentItem,
      color: itemColor});
    setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
  }

  const fieldsValidation = [
    validateNumberField(currentItem.weight, "weight", settingsList.infractionWeightMinimumValue, settingsList.infractionWeightMaximumValue)
  ];


  const checkRelatedItems = async (id) => {
    // const data = await getRelatedChildrenByParentId(`${ relatedItemsUrl }/${ id }`, id, setEventsList);
    // data.length === 0 ? setAllowDelete(true) : setAllowDelete(false);
    setAllowDelete(true);
  }


  const onClose = () => {
    setUpdateConflict(false);
    onCloseDetails({state});
  };


  useEffect(() => {
    if (updateConflict) setUpdateConflict(false);
    if (conflictingRoles) setConflictingRoles(false);
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
  }, [itemChanged])


  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])


  useEffect(() => {
    const getData = async () => {
      const [itemsData, settingsData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(settingsUrl)
        ]);
      const success =
        itemsData.status === 200 &&
        settingsData.status === 200
      if (success) {
        setLoading(false);
        setItems(itemsData.data);
        setSettingsList(settingsData.data);
      } else {
        alert("Nie udało się pobrać danych.");
      }
    };
    getData();
  }, [])


  const getCompleteItem = async (id) => {
    const response = await getItems(`${dataUrl}/${id}`);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }


  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem,
    currentFormState,
    setCurrentFormState,
    formDescription: `Tu możesz dodać nowy element.`,
    formHeader: `Nowy element`,
    buttonTitle: ` Dodaj nową umiejętność`
  }


  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv/>
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows={itemsList}
        columns={columns}
        state={state}
        checkRelatedItems={checkRelatedItems}
        formHeader={`Edycja ${itemName}`}
        relatedItemsUrl={relatedItemsUrl}
        getCompleteItem={getCompleteItem}
      />
  } else {
    dataSectionContent =
      <div className={"warn_info"}><FontAwesomeIcon
        className={"warning-icon div-visible"} icon={faExclamationCircle}/>&nbsp;&nbsp;&nbsp;NIE ZNALEZIONO ŻADNYCH DANYCH
      </div>
  }


  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader
          title={`Edycja pozycji słownika ${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()} -> ${currentItem.name}`}/>
      ) : (
        <AppComponentCardHeader
          title={`Pozycje słownika ${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}`}
          buttonProps={addDataButtonProps}/>
      )}
      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{currentFormState.formDescription}</p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={() => {
                    setUpdateConflict(false)
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>

                <div className="col-md-12 mb-md-0 mb-5">
                  <form id="add-skill-form" name="add-skill-form">
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="name" className=""><span className="required">*</span> Nazwa</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="name" required={true} state={ state } style = {{backgroundColor: `${currentItem.color}`, color: setForegroundColor(currentItem.color)}}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="shortName" className=""><span className="required">*</span> Skrót</label>
                      </div>
                      <div className="col-md-1">
                        <TextInput propertyName="shortName" maxlen="10" required={true} state={state}/>
                      </div>
                      <div className="col-md-1 form-label">
                        <label htmlFor="weight" className=""><span className="required">*</span> Waga ({settingsList.skillWeightMinimumValue}-{settingsList.skillWeightMaximumValue})</label>
                      </div>
                      <div className="col-md-1">
                        <NumberInput propertyName="weight" defaultValue="3" min={settingsList.skillWeightMinimumValue} max={settingsList.skillWeightMaximumValue} required="true"
                                     state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="description" className="">Opis</label>
                      </div>
                      <div className="col-md-9">
                        <TextArea propertyName="description" required={false} rows="2" state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="color" className="">Opis</label>
                      </div>
                      <div className="col-md-9">
                        <div>Kolor etykiety
                          <HexColorPicker
                            style={{width: "20%"}}
                            color={currentItem?.id > 0 ? currentItem.color : itemColor}
                            onChange={changeColor}/>
                        </div>
                      </div>
                    </div>

                    <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                      Nie można dodać ani zmienić tego elementu, ponieważ istnieje już wpis o tej nazwie i/lub skrócie
                    </p>

                  </form>
                </div>




                {/*<div className="col-md-12 mb-md-0 mb-5">*/}
                {/*  <form id="add-department-form" name="add-department-form">*/}
                {/*    <div className="row form-group mb-4">*/}
                {/*      <div className="col-md-3 form-label">*/}
                {/*        <label htmlFor="name" className="">Nazwa *</label>*/}
                {/*      </div>*/}
                {/*      <div className="col-md-9">*/}
                {/*        <TextInput propertyName="name" required={true} state={state}/>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div className="row form-group mb-4">*/}
                {/*      <div className="col-md-3 form-label">*/}
                {/*        <label htmlFor="shortName" className="">Skrót *</label>*/}
                {/*      </div>*/}
                {/*      <div className="col-md-9">*/}
                {/*        <TextInput propertyName="shortName" maxlen="10" required={true} state={state}/>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div className="row form-group mb-4">*/}
                {/*      <div className="col-md-3 form-label">*/}
                {/*        <label htmlFor="description" className="">Opis</label>*/}
                {/*      </div>*/}
                {/*      <div className="col-md-9">*/}
                {/*        <TextArea propertyName="description" required={false} rows="2" state={state}/>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    /!*<div className="row form-group mb-4">*!/*/}
                {/*    /!*  <div className="col-md-3 form-label">*!/*/}
                {/*    /!*    <label htmlFor="employees" className=""></label>*!/*/}
                {/*    /!*  </div>*!/*/}
                {/*    /!*  <div className="col-md-9">*!/*/}
                {/*    /!*    <RelatedItemsList*!/*/}
                {/*    /!*      label="Pracownicy w dziale"*!/*/}
                {/*    /!*      propertyName="employeeId"*!/*/}
                {/*    /!*      required={false}*!/*/}
                {/*    /!*      state={state}*!/*/}
                {/*    /!*      itemsList={employeesList}*!/*/}
                {/*    /!*      itemName="employees"*!/*/}
                {/*    /!*      displayNames={["firstName", "lastName"]}*!/*/}
                {/*    /!*    />*!/*/}
                {/*    /!*  </div>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*    <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>*/}
                {/*      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;*/}
                {/*      Nie można dodać ani zmienić tego elementu, ponieważ istnieje już wpis o tej nazwie i/lub skrócie*/}
                {/*    </p>*/}
                {/*  </form>*/}
                {/*</div>*/}

              </section>
            </div>
            <ModalControls
              onDelete={onDelete}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div
            className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{dataSectionContent}</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );
}

export default Skill;

