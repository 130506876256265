import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
registerLocale("pl", pl);

const QTimePicker = ({ pickerId, errorParagraphId, state, propertyName, startTime, setStartTime, caption }) => {


  const formatDateToTimeString = (date) => {
    if (!date) return null;
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const parseTimeToDate = (timeString) => {
    if (!timeString) return null;
    if (typeof timeString === 'string') {
      const [hours, minutes] = timeString.split(':').map(Number);
      return new Date(1970, 0, 1, hours || 0, minutes || 0, 0);
    }
    if (timeString instanceof Date) {
      return timeString;
    }
    throw new Error('Invalid time format');
  };


  const handleTimeChange = (time) => {
    if (!time) {
      setStartTime(null);
      state.setCurrentItem({ ...state.currentItem, [propertyName]: null });
      document.getElementById(errorParagraphId).classList.add('offscreen');
    } else {
      const timeString = formatDateToTimeString(time instanceof Date ? time : parseTimeToDate(time));
      setStartTime(parseTimeToDate(timeString));
      state.setCurrentItem({
        ...state.currentItem,
        [propertyName]: timeString,
      });
      state.setItemChanged(!state.itemChanged);
      state.setCurrentFormState({ ...state.currentFormState, formSaveButtonDisabled: false });
      document.getElementById(errorParagraphId).classList.add('offscreen');
    }
  };

  const handleRawChange = (e) => {
    const value = e.target.value;
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    if (!timeRegex.test(value)) {
      document.getElementById(errorParagraphId).classList.remove('offscreen');
    } else {
      document.getElementById(errorParagraphId).classList.add('offscreen');
      const parsedTime = parseTimeToDate(value);
      setStartTime(parsedTime);
      state.setCurrentItem({
        ...state.currentItem,
        [propertyName]: formatDateToTimeString(parsedTime),
      });
    }
  };

  return (
    <>
      <DatePicker
        locale="pl"
        id={pickerId}
        selected={startTime}
        onChange={handleTimeChange}
        onChangeRaw={handleRawChange}
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="HH:mm"
        timeCaption={caption}
        autoComplete="off"
      />
    </>
  );
};

export default QTimePicker;
