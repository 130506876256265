import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import ModalControls from "../../common/ModalControls";
import {useNavigate, useLocation} from "react-router-dom";
import {defaultFormState} from "../../../../defaults/Forms";
import {departmentDefault} from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails, setForegroundColor
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import ItemDetailsModalHeader from "../../common/ItemDetailsModalHeader";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import useCrud from "../../../../hooks/useCrud";
import {Table} from "../../../table/Table";
import Select from "../../../elements/Select";
import OptionsList from "../../../elements/OptionsList";

import {MDBDataTableV5} from "mdbreact";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import RelatedItemsList from "../../../elements/RelatedItemsList";
import {HexColorPicker} from "react-colorful";


const Department = () => {

  const dataUrl = "/departments";
  const employeesUrl = "/employees";
  const positionsUrl = "/positions";
  const relatedItemsUrl = null; // if no need to check it, initialize with null and remove RelatedItemsList from details modal
  const defaultItem = departmentDefault;
  const itemName = "dział firmy";
  const itemNames = "działy firmy";
  const navigate = useNavigate();
  const location = useLocation();
  const {createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId} = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [updateConflict, setUpdateConflict] = useState(false);
  const [conflictingRoles, setConflictingRoles] = useState(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [departmentEmployeesList, setDepartmentEmployeesList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [conflictingRolesMsg, setConflictingRolesMsg] = useState("");
  const [itemColor, setItemColor] = useState("#ffffff")



  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false},
    {label: "Nazwa", accessor: "name", sortable: true, searchable: true},
    {label: "Skrócona nazwa", accessor: "shortName", sortable: true, searchable: true},
    {label: "Email", accessor: "email", sortable: true, searchable: true},
    {label: "Telefon", accessor: "phoneNumber", sortable: true, searchable: true},
    {label: "Opis", accessor: "description", sortable: true, searchable: true},
    {label: "Kierownik", accessor: "manager", sortable: true, searchable: true, format: (manager) => manager ? `${manager.lastName} ${manager.firstName}` : "", fieldsToInclude: ['firstName', 'lastName']},
    {label: "Zastępca", accessor: "deputyManager", sortable: true, searchable: true, format: (deputyManager) => deputyManager ? `${deputyManager.lastName} ${deputyManager.firstName}` : "", fieldsToInclude: ['firstName', 'lastName']},
    {label: "Kolor etykiety", accessor: "color", sortable: false, searchable: false, type: "color"},
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];


  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }


  const onDelete = async () => {
    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  }


  const onSaveItemClick = async (e) => {
    e.preventDefault();
    if (!currentItem.name || !currentItem.shortName) {
      if (!currentItem.name) {
        let nameInput = document.getElementById("name");
        nameInput.classList.add("form-input-invalid");
        nameInput.placeholder = `ten element jest wymagany`;
      }

      if (!currentItem.shortName) {
        let shortNameInput = document.getElementById("shortName");
        shortNameInput.classList.add("form-input-invalid");
        shortNameInput.placeholder = `ten element jest wymagany`;
      }
      return;
    }

    let response;
    const item = {
      id: currentItem.id,
      name: currentItem.name,
      shortName: currentItem.shortName,
      description: currentItem.description,
      manager: currentItem.manager && currentItem.manager.id > 0 ? {id: currentItem.manager.id} : null,
      deputyManager: currentItem.deputyManager && currentItem.deputyManager.id > 0 ? {id: currentItem.deputyManager.id} : null,
      positions: currentItem.positions,
      email: currentItem.email,
      phoneNumber: currentItem.phoneNumber,
      color: currentItem.color
    };


    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataUrl, item, state);
    } else {
      response = await updateItem(`${dataUrl}/${item.id}`, item, state);
    }

    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
      setUpdateConflict(true);
    } else if (response.status === 422) {
      setConflictingRolesMsg(response.data);
      setConflictingRoles(true);
    } else {
      response && onSaveAndClose({state});
    }
  }

  const checkRelatedItems = async (id) => {
    // const data = await getRelatedChildrenByParentId(`${ relatedItemsUrl }/${ id }`, id, setEventsList);
    // data.length === 0 ? setAllowDelete(true) : setAllowDelete(false);
    setAllowDelete(true);
  }

  const changeColor = (itemColor) => {
    setItemChanged(!itemChanged);
    setItemColor(itemColor)
    setCurrentItem({...currentItem,
      color: itemColor});
    setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
  }

  const onClose = () => {
    setUpdateConflict(false);
    onCloseDetails({state});
  };

  useEffect(() => {
    if (updateConflict) setUpdateConflict(false);
    if (conflictingRoles) setConflictingRoles(false);
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
  }, [itemChanged])

  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])

  useEffect(() => {
    const getData = async () => {
      const [itemsData, employeesData, positionsData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(employeesUrl),
          getItems(positionsUrl)
        ]);
      const success =
        itemsData.status === 200 &&
        employeesData.status === 200 &&
        positionsData.status === 200
      if (success) {
        setLoading(false);
        setItems(itemsData.data);
        setEmployeesList(employeesData.data);
        setPositionsList(positionsData.data);
      } else {
        alert("Nie udało się pobrać danych.");
      }
    };
    getData();
  }, [])


  useEffect(() => {
    if (currentItem.manager && currentItem.deputyManager && currentItem.manager.id === currentItem.deputyManager.id) {
      setConflictingRoles(true);
      setConflictingRolesMsg("Ta sama osoba nie może być przypisana jednocześnie do dwóch stanowisk kierowniczych w tym samym dziale.");
    } else {
      setConflictingRoles(false);
      setConflictingRolesMsg("");
    }
  }, [currentItem.manager, currentItem.deputyManager]);


  useEffect(() => {
    const fetchPositions = async () => {
      if (currentItem.id) {
        const response = await getItems(`/departments/${currentItem.id}/positions`);
        if (response.status === 200) {
          setPositionsList(response.data);
        } else {
          console.error('Nie udało się pobrać danych o stanowiskach');
          setPositionsList([]);
        }
      }
    };

    fetchPositions();
  }, [currentItem.id]);


  useEffect(() => {
    const fetchEmployees = async () => {
      if (currentItem.id) {
        const response = await getItems(`/departments/${currentItem.id}/employees`);
        if (response.status === 200) {
          setDepartmentEmployeesList(response.data);
        } else {
          console.error('Nie udało się pobrać danych o pracownikach działu');
          setDepartmentEmployeesList([]);
        }
      }
    };

    fetchEmployees();
  }, [currentItem.id]);


  const getCompleteItem = async (id) => {
    const response = await getItems(`${dataUrl}/${id}`);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }


  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem,
    currentFormState,
    setCurrentFormState,
    formDescription: `Tu możesz dodać nowy element.`,
    formHeader: `Nowy element`,
    buttonTitle: ` Dodaj nowy Dział firmy`
  }


  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv/>
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows={itemsList}
        columns={columns}
        state={state}
        checkRelatedItems={checkRelatedItems}
        formHeader={`Edycja ${itemName}`}
        relatedItemsUrl={relatedItemsUrl}
        getCompleteItem={getCompleteItem}
      />
  } else {
    dataSectionContent =
      <div className={"warn_info"}><FontAwesomeIcon
        className={"warning-icon div-visible"} icon={faExclamationCircle}/>&nbsp;&nbsp;&nbsp;NIE ZNALEZIONO ŻADNYCH DANYCH
      </div>
  }


  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader
          title={`Edycja pozycji słownika ${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()} -> ${currentItem.name}`}/>
      ) : (
        <AppComponentCardHeader
          title={`Pozycje słownika ${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}`}
          buttonProps={addDataButtonProps}/>
      )}
      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{currentFormState.formDescription}</p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={() => {
                    setUpdateConflict(false)
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>
                <div className="col-md-12 mb-md-0 mb-5">
                  <form id="add-department-form" name="add-department-form">
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="name" className="">Nazwa *</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="name" required={true} state={ state } style = {{backgroundColor: `${currentItem.color}`, color: setForegroundColor(currentItem.color)}}/>
                        {/*<TextInput propertyName="name" required={true} state={state}/>*/}
                      </div>
                    </div>
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="shortName" className="">Skrót *</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="shortName" maxlen="10" required={true} state={state}/>
                      </div>
                    </div>
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="email" className="">Adres mailowy</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="email" required={false}  maxlen="10" state={state}/>
                      </div>
                    </div>
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="phoneNumber" className="">Numer telefonu</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="phoneNumber" required={false}  maxlen="10" state={state}/>
                      </div>
                    </div>
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="description" className="">Opis</label>
                      </div>
                      <div className="col-md-9">
                        <TextArea propertyName="description" required={false} rows="2" state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="color" className="">Kolor etykiety</label>
                      </div>
                      <div className="col-md-9">
                        <div>
                          <HexColorPicker
                            style={{width: "20%"}}
                            color={currentItem?.id > 0 ? currentItem.color : itemColor}
                            onChange={changeColor}/>
                        </div>
                      </div>
                    </div>


                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="manager" className="">Kierownik</label>
                      </div>
                      <div className="col-md-9">
                          <Select
                            label="manager"
                            propertyName="managerId"
                            required={false}
                            state={state}
                            itemsList={employeesList}
                            itemName="manager"
                            displayNames={["firstName", "lastName"]}
                          />
                      </div>
                    </div>
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="deputyManager" className="">Zastępca kierownika</label>
                      </div>
                      <div className="col-md-9">
                          <Select
                            label="Deputy Manager"
                            propertyName="deputyManagerId"
                            required={false}
                            state={state}
                            itemsList={employeesList}
                            itemName="deputyManager"
                            displayNames={["firstName", "lastName"]}
                          />
                      </div>
                    </div>
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="positions" className=""></label>
                      </div>
                      <div className="col-md-9">
                        <RelatedItemsList
                          label="Stanowiska w dziale"
                          propertyName="positionId"
                          required={false}
                          state={state}
                          itemsList={positionsList}
                          itemName="positions"
                          displayNames={["name"]}
                        />
                      </div>
                    </div>
                    {/*<div className="row form-group mb-4">*/}
                    {/*  <div className="col-md-3 form-label">*/}
                    {/*    <label htmlFor="employees" className=""></label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-md-9">*/}
                    {/*    <OptionsList*/}
                    {/*      label="Pracownicy w dziale"*/}
                    {/*      propertyName="employeeId"*/}
                    {/*      required={false}*/}
                    {/*      state={state}*/}
                    {/*      itemsList={employeesList}*/}
                    {/*      itemName="employees"*/}
                    {/*      displayNames={["firstName", "lastName"]}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="employees" className=""></label>
                      </div>
                      <div className="col-md-9">
                        <RelatedItemsList
                          label="Pracownicy w dziale"
                          propertyName="employeeId"
                          required={false}
                          state={state}
                          itemsList={departmentEmployeesList}
                          itemName="employees"
                          displayNames={["firstName", "lastName"]}
                        />
                      </div>
                    </div>
                    <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                      Nie można dodać ani zmienić tego elementu, ponieważ istnieje już wpis o tej nazwie i/lub skrócie
                    </p>
                    <p id="conflicting_roles_notification" className={conflictingRoles ? "err_info" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                      {conflictingRolesMsg}
                    </p>
                  </form>
                </div>

              </section>
            </div>
            <ModalControls
              onDelete={onDelete}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div
            className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{dataSectionContent}</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );
}

export default Department;

