import React, {useState} from 'react';
import Toggler from "../../../elements/Toggler";
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import QNestedDatePicker from "../../../elements/QNestedDatePicker";
import QTimePicker from "../../../elements/QTimePicker";
import {useEffect} from "react";

const ResourcePlanItem = ({ resourcePlanItem, index, state, onInputChange, positionsList, onRemove, settings }) => {

  const [workStart, setWorkStart] = useState(null);
  const [workEnd, setWorkEnd] = useState(null);

  const formatDateToTimeString = (date) => {
    if (!date) return null;
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const handleTimeChange = (propertyName, time) => {
    const updatedResourcePlanItems = [...state.currentItem.resourcePlanItems];
    updatedResourcePlanItems[index] = {
      ...updatedResourcePlanItems[index],
      [propertyName]: time ? formatDateToTimeString(time) : null,
    };

    state.setCurrentItem({
      ...state.currentItem,
      resourcePlanItems: updatedResourcePlanItems,
    });
  };

  const parseTimeToDate = (timeString) => {
    if (!timeString) return null;
    if (typeof timeString === 'string') {
      const [hours, minutes] = timeString.split(':').map(Number);
      return new Date(1970, 0, 1, hours || 0, minutes || 0, 0);
    }
    if (timeString instanceof Date) {
      return timeString;
    }
    throw new Error('Invalid time format');
  };


  const formatToLocalTime = (isoString) => {
    if (/^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/.test(isoString)) {
      return isoString.slice(0, 5);
    }
    const date = new Date(isoString);
    return date.toISOString().slice(11, 16);
  };


  useEffect(() => {
    console.log(resourcePlanItem);

    if (resourcePlanItem.workStart) {
      setWorkStart(parseTimeToDate(resourcePlanItem.workStart));
    }
    if (resourcePlanItem.workEnd) {
      setWorkEnd(parseTimeToDate(resourcePlanItem.workEnd));
    }
  }, [state.currentItem]);




  return (
    <div className="row">
      <div className="col-md-2">
        <SelectNested
          propertyName={`resourcePlanItems[${index}].position`}
          state={state}
          itemsList={positionsList}
          displayNames={["name"]}
          showInput={false}
          showDelete={false}
          useId={false}
        />
      </div>


      <div className="col-md-1">
        <input
          type="number"
          id={`resourcePlanItem_requiredStaff-${resourcePlanItem.id}`}
          value={resourcePlanItem.requiredPeopleCount || ""}
          onChange={(e) => onInputChange(e, resourcePlanItem, "requiredPeopleCount")}
          min={0}
          max={999}
          placeholder="Wymagana liczba osób"
          className="form-control"
        />
      </div>

      <div className="col-md-1">
        <input
          type="number"
          id={`resourcePlanItem_priority-${resourcePlanItem.id}`}
          value={resourcePlanItem.priority || ""}
          onChange={(e) => onInputChange(e, resourcePlanItem, "priority")}
          min={0}
          max={5}
          placeholder="Ważność"
          className="form-control"
        />
      </div>


      <div className="col-md-2">
        <QTimePicker
          pickerId="workStart"
          errorParagraphId={`wrongWorkStartTimeFormat-rpi` + resourcePlanItem.id}
          state={state}
          propertyName={`resourcePlanItems[${index}].workStart`}
          startTime={workStart}
          setStartTime={(time) => {
            setWorkStart(time);
            const updatedResourcePlanItems = [...state.currentItem.resourcePlanItems];
            updatedResourcePlanItems[index].workStart = time
              ? formatDateToTimeString(time)
              : null;

            state.setCurrentItem({
              ...state.currentItem,
              resourcePlanItems: updatedResourcePlanItems,
            });
          }}
          caption="Godzina rozpoczęcia pracy"
        />
        <p id={`wrongWorkStartTimeFormat-rpi` + resourcePlanItem.id} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format czasu
        </p>
      </div>

      <div className="col-md-2">
        <QTimePicker
          pickerId="workEnd"
          errorParagraphId={`wrongWorkEndTimeFormat-rpi` + resourcePlanItem.id}
          state={state}
          propertyName={`resourcePlanItems[${index}].workEnd`}
          startTime={workEnd}
          setStartTime={(time) => {
            setWorkEnd(time);
            const updatedResourcePlanItems = [...state.currentItem.resourcePlanItems];
            updatedResourcePlanItems[index].workEnd = time
              ? formatDateToTimeString(time)
              : null;

            state.setCurrentItem({
              ...state.currentItem,
              resourcePlanItems: updatedResourcePlanItems,
            });
          }}
          caption="Godzina zakończenia pracy"
        />
        <p id={`wrongWorkEndTimeFormat-rpi` + resourcePlanItem.id} className={"err_info offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp; Nieprawidłowy format czasu
        </p>
      </div>


      <div className="col-md-1">
        <button className="btn btn-outline-danger" onClick={onRemove}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>



    </div>
  );
};

export default ResourcePlanItem;
