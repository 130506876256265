import React from 'react';
import SelectNested from "../../../elements/SelectNested";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";



const PositionTraining = ({ index, state, trainingsList, onRemove }) => {
  const propertyName = `requiredTrainings[${index}]`;

  return (
    <div className="row">
      <div className="col-md-2">
        <SelectNested
          propertyName={propertyName}
          state={state}
          itemsList={trainingsList}
          displayNames={["name"]}
          showInput={false}
          showDelete={false}
          useId={false}
        />
      </div>

      <div className="col-md-1">
        <button
          className="btn btn-outline-danger"
          onClick={(e) => {
            e.preventDefault();
            onRemove();
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    </div>
  );
};

// const PositionTraining = ({ index, state, trainingsList, onRemove}) => {
//
//   return (
//     <div className="row">
//
//       <div className="col-md-2">
//         <SelectNested
//           propertyName={`requiredTrainings[${index}].training`}
//           state={state}
//           itemsList={trainingsList}
//           displayNames={["name"]}
//           showInput={false}
//           showDelete={false}
//           useId={true}
//         />
//       </div>
//
//       <div className="col-md-1">
//         <button className="btn btn-outline-danger"   onClick={(e) => {
//           e.preventDefault(); // Zatrzymuje odświeżenie
//           onRemove();
//         }}>
//           <FontAwesomeIcon icon={faTrashAlt} />
//         </button>
//       </div>
//     </div>
//   );
// };

export default PositionTraining;
