import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import {Modal} from "react-bootstrap";

import {
  onFormCancelCloseButtonClick,
  onFormCancelDeleteButtonClick,
  onFormConfirmDeleteButtonClick,
  onFormCloseWithoutSavingButtonClick,
} from "../../../helpers/ComponentHelper";

const ModalFooter = ({ onDelete, onCloseDetails, onSubmit, state }) => {

  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const setBackupItem = state.setBackupItem;
  const defaultItem = state.defaultItem;
  const closeButtonCaption = onDelete ? "Zapisz" : "Zapisz";

  return (
    <Modal.Footer>
      {onDelete && (
      <div className="form-confirm-delete div-hidden" id="confirm-delete">
        Na pewno usunąć? Tej operacji nie można cofnąć!
        <Button className="button-short" variant="danger" onClick={onDelete}>
          Usuń
        </Button>
        <Button className="button-short" variant="secondary" onClick={() => onFormCancelDeleteButtonClick()}>
          Nie
        </Button>
      </div>)}
      {onDelete && !currentFormState.formAddingDataMode &&
        <Button className="button-short"variant="danger" id="btn-delete" onClick={() => onFormConfirmDeleteButtonClick()}>
          Usuń
        </Button>
      }
      <div className="form-confirm-close" id="confirm-close">
        Zamknąć bez zapisywania?
        <Button variant="secondary" onClick={() => onFormCancelCloseButtonClick()}>
          Nie
        </Button>
        <Button variant="warning" onClick={() => onFormCloseWithoutSavingButtonClick(currentFormState, setCurrentFormState, setCurrentItem, setBackupItem, defaultItem)}>
          Zamknij
        </Button>
      </div>
      <Button variant="secondary" id="btn-close" onClick={onCloseDetails}>
        Zamknij
      </Button>
      <Button id="save-and-close-button" variant="primary" onClick={onSubmit} disabled={currentFormState.formSaveButtonDisabled}>
        {closeButtonCaption}
      </Button>
    </Modal.Footer>
  );
}

export default ModalFooter;