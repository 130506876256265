import React, { useEffect, useState, useRef } from "react";
import Select from "../../../elements/Select";
import UserRolesCard from "../users/UserRolesCard";
import EmployeeAddressCard from "./EmployeeAddressCard";
import EmployeeSkillsCard from "./EmployeeSkillsCard";
import EmployeeTrainingsCard from "./EmployeeTrainingsCard";
import EmployeeCommendationsCard from "./EmployeeCommendationsCard";
import EmployeeInfractionsCard from "./EmployeeInfractionsCard";
import EmployeeCertificationsCard from "./EmployeeCertificationsCard";
import EmployeeMedExamsCard from "./EmployeeMedExamsCard";
import EmployeeAbsencesCard from "./EmployeeAbsencesCard";
import EmployeePositionsCard from "./EmployeePositionsCard";

const Employee_Company_Data_Tab = ({state, rolesList, skillsList, trainingsList, commendationsList, infractionsList, certificationTypesList, medExamTypesList, supervisorsList, absenceTypesList, departmentsList, positionsList, settings}) => {


  return (
    <>
      <h4 style={{marginLeft: "10px"}}>Dane firmowe</h4>

      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="hiringCompany" className="">Firma zatrudniająca</label>
        </div>
        <div className="col-md-11">
          <Select
            label="Firma zatrudniająca"
            propertyName="hiringCompany"
            required={false}
            state={state}
            itemsList={state.hiringCompaniesList}
            itemName="hiringCompany"
            displayNames={["shortName"]}
          />
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="hiringStage" className="">Etap zatrudnienia</label>
        </div>
        <div className="col-md-11">
          <Select
            label="Etap zatrudnienia"
            propertyName="hiringStage"
            required={false}
            state={state}
            itemsList={state.hiringStagesList}
            itemName="hiringStage"
            displayNames={["name"]}
          />
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="department" className="">Dział</label>
        </div>
        <div className="col-md-11">
          <Select
            label="Dział"
            propertyName="department"
            required={true}
            state={state}
            itemsList={departmentsList}
            itemName="department"
            displayNames={["name"]}
          />
        </div>
      </div>

      {/*<div className="row form-group mb-2">*/}
      {/*  <div className="col-md-1 form-label">*/}
      {/*    <label htmlFor="position" className="">Stanowisko</label>*/}
      {/*  </div>*/}
      {/*  <div className="col-md-11">*/}
      {/*    <Select*/}
      {/*      label="Stanowisko"*/}
      {/*      propertyName="position"*/}
      {/*      required={true}*/}
      {/*      state={state}*/}
      {/*      itemsList={positionsList}*/}
      {/*      itemName="position"*/}
      {/*      displayNames={["name"]}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}


      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="employeePositions" className=""></label>
        </div>
        <div className="col-md-11">
          <EmployeePositionsCard state={state} positionsList={positionsList} settings={settings}/>
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="userRoles" className=""></label>
        </div>
        <div className="col-md-11">
          <UserRolesCard state={state} rolesList={rolesList}/>
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="employeeSkills" className=""></label>
        </div>
        <div className="col-md-11">
          <EmployeeSkillsCard state={state} skillsList={skillsList} settings={settings}/>
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="employeeTrainings" className=""></label>
        </div>
        <div className="col-md-11">
          <EmployeeTrainingsCard state={state} trainingsList={trainingsList} supervisorsList={supervisorsList} settings={settings}/>
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="employeeCertifications" className=""></label>
        </div>
        <div className="col-md-11">
          <EmployeeCertificationsCard state={state} certificationTypesList={certificationTypesList} settings={settings}/>
        </div>
      </div>


      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="employeeCommendations" className=""></label>
        </div>
        <div className="col-md-11">
          <EmployeeCommendationsCard state={state} commendationsList={commendationsList} supervisorsList={supervisorsList} settings={settings}/>
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="employeeInfractions" className=""></label>
        </div>
        <div className="col-md-11">
          <EmployeeInfractionsCard state={state} infractionsList={infractionsList} supervisorsList={supervisorsList} settings={settings}/>
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="employeeMedexams" className=""></label>
        </div>
        <div className="col-md-11">
          <EmployeeMedExamsCard state={state} medExamTypesList={medExamTypesList} settings={settings}/>
        </div>
      </div>

      <div className="row form-group mb-2">
        <div className="col-md-1 form-label">
          <label htmlFor="employeeAbsences" className=""></label>
        </div>
        <div className="col-md-11">
          <EmployeeAbsencesCard state={state} absenceTypesList={absenceTypesList} supervisorsList={supervisorsList} settings={settings}/>
        </div>
      </div>

    </>
  );
};

export default Employee_Company_Data_Tab;