import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import EmployeePosition from "./EmployeePosition";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {employeePositionDefault} from "../../../../defaults/Items";

const EmployeePositionsCard = ({state, positionsList, settings}) => {

  const currentItem = state.currentItem;
  const employeePositions = currentItem?.employeePositions || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [positionsNote, setPositionsNote] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [filteredPositionsList, setFilteredPositionsList] = useState([]);


  const onInputChange = (e, position, field) => {
    const updatedPositions = employeePositions.map((item) =>
      item.id === position.id ? {...item, [field]: e.target.value} : item
    );
    setCurrentItem({...currentItem, employeePositions: updatedPositions});
    setItemChanged(!itemChanged);
  };


  const addPosition = () => {
    const existingIds = employeePositions.map((position) => position.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }
    const newPosition = {...employeePositionDefault, EID: currentItem.id, id: newId};
    const updatedPositions = [...employeePositions, newPosition];
    setCurrentItem({...currentItem, employeePositions: updatedPositions});
    setItemChanged(!itemChanged);
  };


  const removePosition = useCallback(
    (positionId) => {
      const updatedPositions = employeePositions.filter(
        (item) => item.id !== positionId
      );
      setCurrentItem({...currentItem, employeePositions: updatedPositions});
      setItemChanged(!itemChanged);
    },
    [employeePositions, currentItem, setCurrentItem, setItemChanged, itemChanged]
  );


  useEffect(() => {
    const filtered = positionsList.filter(
      (position) => !employeePositions.some((empPos) => empPos.position.id === position.id)
    );
    setFilteredPositionsList(filtered);
  }, [positionsList, employeePositions, itemChanged]);


  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };


  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion}
             style={{cursor: "pointer"}}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faUserTie} className="me-2 header-icon"/>
            <span>Stanowiska pracownika {!isAccordionOpen ? "(" + currentItem.employeePositions.length + ")" : ""}</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown}/>
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="employeePositions">
          {currentItem.department ? (
            <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addPosition}>
              <FontAwesomeIcon icon={faCirclePlus}/> Dodaj
            </button>
          ) : (
            <p className="err_info">Brak przypisanego działu</p>
          )}
          {employeePositions.length > 0 && (
            <div className="row">
              <div className="col-md-8">
                <div className="row font-weight-bold">
                  <div className="col-md-2 header-label">Stanowisko</div>
                  <div className="col-md-1 header-label">Ocena</div>
                  <div className="col-md-3 header-label">Uwagi</div>
                  <div className="col-md-2 header-label">Data oceny</div>
                  <div className="col-md-2 header-label"></div>
                  <div className="col-md-1 header-label">Aktywne</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row font-weight-bold">
                  <div className="col-md-6 header-label">Wymagane szkolenia</div>
                  <div className="col-md-6 header-label">Wymagane certyfikaty</div>
                </div>
                </div>
            </div>
          )}
          {employeePositions.length > 0 ? (
            employeePositions.map((position, index) => (
              <EmployeePosition
                key={index}
                employeePosition={position}
                index={index}
                state={state}
                onInputChange={onInputChange}
                positionsList={filteredPositionsList}
                onRemove={() => removePosition(position.id)}
                settings={settings}
              />
            ))
          ) : (
            currentItem.department ? (<p>Brak przydzielonych stanowisk</p>) : ''
          )}
        </div>
      </div>
      <p id="positionsNote" className={positionsNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
        Uzupełnij dane stanowiska
      </p>
    </>
  );
};

export default EmployeePositionsCard;



