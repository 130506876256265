import React, {useEffect, useState, useCallback} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faChalkboardUser,
  faLightbulb, faCertificate
} from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {
  employeeCertificationDefault,
  employeePositionDefault,
  resourcePlanItemDefault
} from "../../../../defaults/Items";
import ResourcePlanItem from "./ResourcePlanItem";
import {faUserTie} from "@fortawesome/free-solid-svg-icons/faUserTie";

const ResourcePlanItemsCard = ({ state, positionsList, settings }) => {

  const currentItem = state.currentItem;
  const resourcePlanItems = currentItem?.resourcePlanItems || [];
  const setCurrentItem = state.setCurrentItem;
  const setItemChanged = state.setItemChanged;
  const itemChanged = state.itemChanged;
  const [resourcePlanItemNote, setResourcePlanItemNote] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [filteredPositionsList, setFilteredPositionsList] = useState([]);



  const onInputChange = (e, resourcePlanItem, field) => {
    const updatedPositions = resourcePlanItems.map((item) =>
      item.id === resourcePlanItem.id ? {...item, [field]: e.target.value} : item
    );
    setCurrentItem({...currentItem, resourcePlanItems: updatedPositions});
    setItemChanged(!itemChanged);
  };



  const addResourcePlanItem = () => {
    const existingIds = resourcePlanItems.map((position) => position.id);
    let newId = -1;
    while (existingIds.includes(newId)) {
      newId--;
    }
    const newPosition = {...resourcePlanItemDefault, id: newId};
    const updatedPositions = [...resourcePlanItems, newPosition];
    setCurrentItem({...currentItem, resourcePlanItems: updatedPositions});
    setItemChanged(!itemChanged);
  };


  const removeResourcePlanItem = useCallback(
    (positionId) => {
      const updatedPositions = resourcePlanItems.filter(
        (item) => item.id !== positionId
      );
      setCurrentItem({...currentItem, resourcePlanItems: updatedPositions});
      setItemChanged(!itemChanged);
    },
    [resourcePlanItems, currentItem, setCurrentItem, setItemChanged, itemChanged]
  );




  useEffect(() => {
    const filtered = positionsList.filter(
      (position) => !resourcePlanItems.some((rpi) => rpi.position.id === position.id)
    );
    setFilteredPositionsList(filtered);
  }, [positionsList, resourcePlanItems, itemChanged]);


  const toggleAccordion = () => {
    console.log(currentItem);
    setIsAccordionOpen(!isAccordionOpen);
  };


  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faUserTie} className="me-2 header-icon"/>
            <span>Stanowiska {!isAccordionOpen ? "(" + currentItem.resourcePlanItems.length + ")" : ""}</span>
          </div>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </div>
        <div className={`card-body ${isAccordionOpen ? 'show' : 'offscreen'}`} id="resourcePlanItems">
          <button type="button" className="mb-2 btn-auxiliary btn-primary" onClick={addResourcePlanItem}><FontAwesomeIcon
            icon={ faCirclePlus }/> Dodaj</button>
          {resourcePlanItems.length > 0 && (
            <div className="row font-weight-bold">
              <div className="col-md-2 header-label">Stanowisko</div>
              <div className="col-md-1 header-label">Liczba osób</div>
              <div className="col-md-1 header-label">Ważność</div>
              <div className="col-md-2 header-label">Początek pracy</div>
              <div className="col-md-2 header-label">Koniec pracy</div>
            </div>
          )}
          {resourcePlanItems.length > 0 ? (
            resourcePlanItems.map((resourcePlanItem, index) => (
              <ResourcePlanItem
                key={resourcePlanItem.id}
                resourcePlanItem={resourcePlanItem}
                index={index}
                state={state}
                onInputChange={onInputChange}
                positionsList={filteredPositionsList}
                onRemove={() => removeResourcePlanItem(resourcePlanItem.id)}
              />
            ))
          ) : (
            <p>Brak wybranych stanowisk</p>
          )}
        </div>
      </div>
      <p id="certificationNote" className={resourcePlanItemNote ? "err_info" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
        Uzupełnij dane stanowiska
      </p>
    </>
  );
};

export default ResourcePlanItemsCard;



